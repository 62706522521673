import { useCallback } from 'react';
import axios from 'axios';
import { useToken } from './useToken';
import { Address } from '../data-management/authSlice';
import { useSelectedOrganisationId } from './useSelectedOrganisationId';

type UseShippingAddressGetterHookType = () => (

) => Promise<Address[]>

export const useShippingAddressGetter: UseShippingAddressGetterHookType = () => {

  const token = useToken();
  const orgId = useSelectedOrganisationId();

  return useCallback(async () => (await axios.get<Address[]>(
    `${process.env.REACT_APP_SERVER_URL}/users/my-shipping-addresses/${orgId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )).data, [token, orgId])
}
