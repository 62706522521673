import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';


type UseAdminUserWelcomeHookType = () => (id: number) => Promise<void>

export const useAdminUserWelcome: UseAdminUserWelcomeHookType = () => {

  const token = useToken()

  return useCallback(async (id) => {
    const {data} = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/users/${id.toString()}/welcome`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}