import { SettingsType, SettingType } from '../hooks/useSettingsGet';
import React, { FC } from 'react';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import TextField from '@material-ui/core/TextField';

type IntegerSettingProps = {
  name: string
  setSetting: (inner: (settings: SettingsType) => SettingsType) => void
  setting: SettingType
}

export const IntegerSetting: FC<IntegerSettingProps> = (
  {
    name,
    setting,
    setSetting
  }
) => {

  const textFieldHandleChange: StandardInputProps['onChange'] = (e) => {
    setSetting(data => ({
      ...data,
      [name]: {
        ...setting,
        value: Number(e.target.value)
      },
    }));
  };

  return (
    <TextField
      name={setting.label}
      value={setting.value}
      type="number"
      fullWidth
      onChange={textFieldHandleChange}
    />
  )
}
