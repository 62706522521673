import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { productActions } from '../data-management/productsSlice';
import { useProducts } from './useProducts';
import { useCategoryId } from './useCategoryId';

type UseAddProductHookType = () => (id?: number) => void

export const useAddProduct: UseAddProductHookType = () => {

  const dispatch = useDispatch()
  const products = useProducts()
  const {fields} = useCategoryId()

  return useCallback((id) => {
    let lastProduct = undefined;
    let productIndex = products.findIndex(({key_id}) => id === key_id)

    if (productIndex !== -1 && products[productIndex])
      lastProduct = products[productIndex];

    if (!lastProduct && products?.length > 0) {
      lastProduct = products[products.length - 1];
      productIndex = products.length - 1;
    }

    if (lastProduct) {
      dispatch(productActions.addProductAt({
        index: productIndex !== -1 ? productIndex : 0,
        product: {
          active: lastProduct.active,
          product_id: lastProduct.product_id,
          quantity: undefined,
          features: lastProduct.features.filter(({ code: featureCode }) =>
            fields.find(({ code }) => featureCode === code)?.type === 'select',
          ),
          note: '',
        },
      }));
    } else {
      dispatch(productActions.addProduct({
        active: true,
        features: [],
        note: '',
      }));
    }
  }, [products])
}