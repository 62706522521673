import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    boxSizing: 'border-box',
    '@media print': {
      display: 'none',
    },
  },
}));

export const HideOnPrint = React.memo(
  <C extends React.ElementType>(
    {
      children,
      component,
      ...props
    }: {
      children: ReactNode,
      component: C,
      [name: string]: any
    },
  ) => {

    const classes = useStyles();

    return (
      <Box component={component} className={classes.box} {...props}>
        {children}
      </Box>
    );
  });
