import React, { FormEventHandler, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Paper, Typography, CircularProgress, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { flashActions } from '../data-management/flashSlice';
import { useToken } from '../hooks/useToken';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // ES6
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DataGrid, GridColumns, GridSortModel } from '@material-ui/data-grid';
import { format, parseISO } from 'date-fns';
import { Edit, Refresh, Visibility } from '@material-ui/icons';
const ReactQuill = require('react-quill');

const useStyles = makeStyles((theme) => ({
  root: {},
  gap: {
    marginTop: theme.spacing(2),
    textAlign: 'end',
  },
  paper: {
    padding: '40px 50px 52px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  filterItem: {
    flexGrow: 1,
    marginRight: theme.spacing(20),
  },
  itemFlexGrow: {
    flexGrow: 1,
    minHeight: 400,
    padding: '50px',
  },
  initial: {
    background: "#535353",
    width: "40px",
    height: "40px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    borderRadius: "4px",
    marginRight: "10px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  greyBox: {
    background: "#F6F7FA",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    borderRadius: "4px",
  },
  greyBoxTitle: {
    borderTop: "1px solid white",
    padding: "6px 12px",
    width: "100%",
    textAlign: "center",
  },
  greyBoxCount: {
    fontSize: "24px",
    padding: "15px",
  },
  datepickerInput: {
    margin: "0px 25px",
  },
  statusRow: {
    backgroundColor: '#DEFFBD',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  errorRow: {
    backgroundColor: '#ffbdbd',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
}));

export const StatisticPage = () => {

  const [data, setData] = useState<any>({});
  const [items, setItems] = useState<any>([]);
  const [savedOrderCount, setSavedOrderCount] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [loading, setLoading] = useState<boolean>(false);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'order_date',
      sort: 'desc',
    },
  ]);

  const classes = useStyles();

  const dispatch = useDispatch();
  const token = useToken();

  const onChange: (type: string) => (date: MaterialUiPickersDate) => void = (type) => (date) => {
    if(type == "start"){
      setStartDate(date)
    }else if(type == "end"){
      setEndDate(date)
    }
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/statistic/all`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((data) => {
      setData(data.data);
    })
    confirmCall();
  }, []);

  const confirmCall = function() {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/statistic/filtered`, {
      start_date: format(startDate, 'yyyy-MM-dd'), end_date: format(endDate, 'yyyy-MM-dd')
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((data) => {
      setItems(data.data);
      setLoading(false);
    })
    axios.post(`${process.env.REACT_APP_SERVER_URL}/statistic/saved-order-count`, {
      start_date: format(startDate, 'yyyy-MM-dd'), end_date: format(endDate, 'yyyy-MM-dd')
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((data) => {
      setSavedOrderCount(data.data.saved_order_count);
    })
  }

  const sendToSync = function(id: string) {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/statistic/send-to-sync/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((data) => {
      dispatch(flashActions.addFlashMessage("Beküldés sikeres!"))
    }).catch((err) => {
      dispatch(flashActions.addFlashMessage("Hiba a szinkronizációs próbálkozás során!"))
    });
  }

  const columns: GridColumns = [
    {
      field: 'ponumber',
      headerName: 'Azonosító',
      align: 'left',
      headerAlign: 'left',
      flex: 0.75,
    },
    {
      field: 'order_date',
      headerName: 'Rendelés dátuma',
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.getValue(params.id, 'finalized_at');
        return typeof value === 'string' ?
          parseISO(value) : new Date();
      },
      type: 'dateTime',
    },
    {
      field: 'organization_name',
      headerName: 'Cégnév',
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.getValue(params.id, 'organization');
        return (value && typeof value === 'object') ? (value as any).name : "";
      },
    },
    {
      field: 'user_name',
      headerName: 'Felhasználó',
      flex: 0.75,
    },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.value as { text?: string };
        return value?.text ?? '';
      },
      renderCell: (params) => (
        <Typography className={params.value == "Hiba a rögzítés során" ? classes.errorRow : classes.statusRow}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'totalArea',
      headerName: 'Méret',
      flex: 0.5,
      type: 'number',
      valueFormatter: (params) =>
        `${parseFloat(params.value as string) ? parseFloat(params.value as string).toFixed(4) : 0} m²`,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'totalNetPrice',
      headerName: 'Nettó ára',
      flex: 0.5,
      type: 'number',
      valueFormatter: (params) =>
        `${(params.value as number).toLocaleString?.()} Ft`,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'totalBrutPrice',
      headerName: 'Bruttó ára',
      flex: 0.5,
      type: 'number',
      valueFormatter: (params) =>
        `${(Math.round(params.value as number)).toLocaleString?.()} Ft`,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'show',
      headerName: '',
      sortable: false,
      resizable: false,
      editable: false,
      hideSortIcons: true,
      filterable: false,
      disableColumnMenu: true,
      align: 'left',
      renderHeader: () => (
        <div />
      ),
      renderCell: (params) => (
        <>
        <IconButton
          target='_blank'
          href={`/print/${params.row.secure_id}`}
        >
          <Visibility />
        </IconButton>
        { (params.getValue(params.id, 'status') === "Hiba a rögzítés során") && params.getValue(params.id, 'is_custom') != 1 ?
        <IconButton onClick={() => {
          sendToSync(params.row.id);
        }}>
          <Refresh />
        </IconButton> : <></> }
        </>
      ),
    },
  ];

  const rows = items ?? [];

  return (
    <Grid container direction={'column'} className={classes.root}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.filterItem} variant="h4">
              Rendelés történet
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item className={classes.itemFlexGrow}>
        <Paper className={classes.paper}>
          <Grid container direction={'row'}>

            <Grid item xs={8}>
              <Box className={classes.titleContainer}>
                <Box className={classes.initial}>F</Box>
                <Typography variant="h6">
                  Felhasználói statisztikák:
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={3} direction={'row'}>
                  <Grid item xs={2}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.all_user_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Összes felhasználó</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.active_user_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Aktív fiók</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.inactive_user_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Inaktív fiók</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.last_login_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Utolsó 7 napban belépett</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.all_organization_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Összes cég</Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box className={classes.titleContainer}>
                <Box className={classes.initial}>R</Box>
                <Typography variant="h6">
                  Rendelés statisztikák:
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={3} direction={'row'}>
                  <Grid item xs={4}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.all_order_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Összes rendelés</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.parking_order_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Aktuálisan parkol</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={classes.greyBox}>
                      <Box className={classes.greyBoxCount}>{ data.sync_error_count ?? 0}</Box>
                      <Box className={classes.greyBoxTitle}>Szinkron hiba</Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

          </Grid>

          


          
          <Box className={classes.titleContainer} style={{marginTop: "70px"}}>
            <Box className={classes.initial}>I</Box>
            <Typography variant="h6">
              Időszakos rendelési adatok:
            </Typography>
            <Box style={{display: "flex", alignItems: "center"}}>
            <KeyboardDatePicker
              clearable
              value={startDate}
              onChange={onChange("start")}
              fullWidth
              format="yyyy-MM-dd"
              className={classes.datepickerInput}
            />
            <KeyboardDatePicker
              clearable
              value={endDate}
              onChange={onChange("end")}
              fullWidth
              format="yyyy-MM-dd"
              className={classes.datepickerInput}
            />
            <Button type="submit" fullWidth variant="contained" color="primary" style={{marginBottom: 0}} onClick={confirmCall}>
              Szűrés
            </Button>
            </Box>
          </Box>
          <Box>
            <Grid container spacing={3} direction={'row'}>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <Box className={classes.greyBox}>
                  <Box className={classes.greyBoxCount}>{items.length ?? 0}</Box>
                  <Box className={classes.greyBoxTitle}>Leadott</Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.greyBox}>
                  <Box className={classes.greyBoxCount}>{items.length ? items.filter((item: any) => item.status?.text == "Számlázás/Szállítás").length : 0}</Box>
                  <Box className={classes.greyBoxTitle}>Lezárt</Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.greyBox}>
                  <Box className={classes.greyBoxCount}>{ items.length ? (Object.keys(items).reduce((sum,key)=>sum+parseFloat(items[key].totalArea||0),0)).toFixed(4) : 0 }</Box>
                  <Box className={classes.greyBoxTitle}>Eladott m²</Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.greyBox}>
                  <Box className={classes.greyBoxCount}>{savedOrderCount}</Box>
                  <Box className={classes.greyBoxTitle}>Létrejött ajánlatok</Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.greyBox}>
                  <Box className={classes.greyBoxCount}>{items.length ? items.filter((item: any) => item.status?.text == "Hiba a rögzítés során").length : 0}</Box>
                  <Box className={classes.greyBoxTitle}>Szinkron hiba</Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box style={{marginTop: "40px"}}>
            {loading ? <Grid container justify={'center'}><Box p={2}><CircularProgress/></Box></Grid> :
            <div style={{ minHeight: 300, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                autoHeight
                disableSelectionOnClick
                disableColumnSelector
                sortModel={sortModel}
                onSortModelChange={({ sortModel }) => setSortModel(sortModel)}
              />
            </div> }
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
