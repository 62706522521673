import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { DisplayRulesActions, DisplayRulesState } from '../../data-management/displayRulesSlice';
import { useProducts } from '../../hooks/useProducts';
import { SizeChart } from './SizeChart';
import { useToken } from '../../hooks/useToken';
import { RootState } from '../../data-management/store';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: '#535353',
    opacity: 0.5,
  },
  container: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden'
  }
}));

export const SizeTable: FC = () => {

  const [data, setData] = useState<any[]>([])

  const classes = useStyles();
  const token = useToken();
  const products = useProducts();
  const displayRulesCache = useSelector<RootState, DisplayRulesState>(state => state.displayRules)
  const dispatch = useDispatch()

  useEffect(() => {
    if (products.length !== 0 && products[0].product_id) {

      const cachedName = `${products[0].product_id}`;
      if (displayRulesCache[cachedName]) {
        setData(displayRulesCache[cachedName]);
        return
      }

      axios.get<[]>(`${process.env.REACT_APP_SERVER_URL}/displayrules/${products[0].product_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(({ data }) => {
        setData(data)
        dispatch(DisplayRulesActions.add({
          name: cachedName,
          rules: data
        }))
      })
    }
    else{
      setData([]);
    }
  }, [products])

  return data.length !== 0 ? (
    <Box my={2} className={classes.container}>
      <Grid container justify={'space-around'}>
        {
          data.map((props, index) => (
            <React.Fragment key={index}>
              <SizeChart {...props} />
              {
                index + 1 !== data.length ?
                  <Grid item>
                    <Divider
                      orientation={'vertical'}
                      className={classes.divider}
                    />
                  </Grid> : <></>
              }
            </React.Fragment>
          ))
        }
      </Grid>
    </Box>
  ) : <></>;
};
