import { useEffect, useState } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { useSelectedOrganisationId } from './useSelectedOrganisationId';

export type ShippingMode = {
  name: string
  code: string
  default: boolean
}

type ShippingModeResponse = ShippingMode[]

export type UseShippingModesHookType = () => ShippingMode[]

export const useShippingModes: UseShippingModesHookType = () => {

  const [shippingModes, setShippingModes] = useState<ShippingMode[]>([])
  const token = useToken()
  const orgId = useSelectedOrganisationId()

  useEffect(() => {
    if (token) {
      setShippingModes([])
      axios.post<ShippingModeResponse>(
        `${process.env.REACT_APP_SERVER_URL}/order/ship/modes`,
        {
          org_id: orgId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).then(({data}) => {
        setShippingModes(data)
      })
    }
  }, [orgId])

  return shippingModes
}
