import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UserImg from '../../images/user-img.png';
import { connect, useDispatch } from 'react-redux';
import { Button, Checkbox, FormControl, FormControlLabel, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@material-ui/core';
import axios from 'axios';
import MD5 from "crypto-js/md5";
import Alert from '@material-ui/lab/Alert';
import { PricingEdit } from './PricingEdit';
import Grid from '@material-ui/core/Grid';
import { useToken } from '../hooks/useToken';
import { useIsAdmin } from '../hooks/useIsAdmin';
import { useSettingsGet } from '../hooks/useSettingsGet';
import { flashActions } from '../data-management/flashSlice';
import { authActions } from '../data-management/authSlice';
import { useGetArkatOrganization } from '../hooks/useGetArkatOrganization';
import { useIsListTypeOrganization } from '../hooks/useIsListTypeOrganization';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { CloudDownload, Visibility } from '@material-ui/icons';
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} width={'100%'}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    width: '100%',
    minHeight: '100%',
    backgroundColor: '#f6f7fa',
    boxSizing: 'border-box',
  },
  tabs: {
    width: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
  },
  tab: {
    textTransform: 'unset',
    fontSize: '18px',
    fontWeight: 300,
    '& > span': {
      textAlign: 'left',
      display: 'block',
      paddingLeft: theme.spacing(3),
    },
  },
  userEdit: {
    backgroundColor: '#fff',
    padding: theme.spacing(5),
    boxSizing: 'border-box',
    width: '100%',
  },
  pwchange: {
    backgroundColor: '#fff',
    padding: theme.spacing(5),
    boxSizing: 'border-box',
    width: '100%',
  },
  notification: {
    backgroundColor: '#fff',
    padding: theme.spacing(5),
    boxSizing: 'border-box',
    width: '100%',
  },
  arlista: {
    backgroundColor: '#fff',
    padding: theme.spacing(5),
    boxSizing: 'border-box',
    width: '100%',
  },
  contact_person: {
    backgroundColor: '#fff',
    padding: '0px !important',
    textAlign: 'center',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  formItem: {
    marginBottom: '35px',
  },
  '@global': {
    '.MuiTab-root': {
      width: '100% !important',
      maxWidth: '100%',
    },
    '.Mui-selected': {
      backgroundColor: '#B2A77D',
      color: '#fff',
    },
    '.MuiBox-root': {
      paddingTop: 0,
    },
  },
}));

const ProfileEdit = ({ auth }) => {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState({
    password: '',
    new_password: '',
    new_password_confirm: '',
  });
  const token = useToken()
  const settingsGetApi = useSettingsGet();

  const [passwordResetSuccess, setPasswordResetSuccess] = useState(undefined);
  const [passwordResetMessage, setPasswordResetMessage] = useState('');

  const [orderProcessedNoti, setOrderProcessedNoti] = useState(auth?.user?.order_processed_noti ? 1 : 0);
  const [orderStatusChange, setOrderStatusChange] = useState(auth?.user?.order_status_change_noti ? 1 : 0);
  const [newsletterNoti, setNewsletterNoti] = useState(auth?.user?.newsletter_noti ? 1 : 0);
  const [orderConfirm, setOrderConfirm] = useState(auth?.user?.order_confirm ? 1 : 0);

  const isAdmin = useIsAdmin()
  const dispatch = useDispatch();
  const arkat = useGetArkatOrganization();
  const isListType = useIsListTypeOrganization();
  const orgId = useSelectedOrganisationId();

  const [profileData, setProfileData] = useState('');
  const [arlistaNames, setArlistaNames] = useState([]);
  const [arlistaRootFolder, setArlistaRootFolder] = useState("");
  const [contactInfos, setContactInfos] = useState([]);

  useEffect(() => {

    settingsGetApi().then((settings) => {
      setArlistaRootFolder(settings.pricelist_root_folder.value)
    });

    axios.get(`${process.env.REACT_APP_SERVER_URL}/users/${auth.user.id}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      setProfileData(data)
    })
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/get-contacts/${orgId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      setContactInfos(data)
    })
  }, [orgId]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/react-build?hash=${MD5('NF'+arkat).toString().substr(5,10)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      setArlistaNames(data)
    })
  }, [arkat]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePassword = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const notiSave = () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/notifications`,
    {
      noti_processed: orderProcessedNoti,
      noti_status_change: orderStatusChange,
      newsletter_change: newsletterNoti,
      noti_confirm: orderConfirm,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        accept: 'application/json',
      },
    }).then(() => {
      dispatch(flashActions.addFlashMessage('Értesítések mentése sikeres!'));
      dispatch(authActions.setNotification({order_processed_noti: !!orderProcessedNoti, order_status_change_noti: !!orderStatusChange, newsletter_noti: !!newsletterNoti, order_confirm: !!orderConfirm}));
    });
  }

  const onSubmitPasswordChange = (e) => {
    e.preventDefault();

    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/auth/changepw `,
        {
          password: data.password,
          new_password: data.new_password,
          new_password_confirm: data.new_password_confirm,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((res) => {
        setPasswordResetMessage('Sikeres jelszó változtatás');
        setPasswordResetSuccess(true);
      })
      .catch((err) => {
        if (typeof err?.response?.data === 'string')
          setPasswordResetMessage(err.response.data);
        else
          setPasswordResetMessage('Hiba történt a jelszó változtatás során');

        setPasswordResetSuccess(false);
      }).finally(() => {
      setData({
        password: '',
        new_password: '',
        new_password_confirm: '',
      });
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Tabs
            orientation="vertical"
            variant="standard"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab className={classes.tab} label="Általános információk" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Jelszó csere" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Értesítések" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Kapcsolat" {...a11yProps(3)} />
            <Tab className={classes.tab} label="Árlista" {...a11yProps(4)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={8}>
          <TabPanel style={{ width: '100%' }} value={value} index={0}>
            <div className={classes.userEdit}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
                <img src={UserImg} alt="user" style={{ marginRight: '20px' }} />
                <Typography variant="h4">{auth?.user?.name}</Typography>
              </div>
              <form>
                <FormControl className={classes.formItem} fullWidth>
                  <TextField name="name" fullWidth label="Név" value={auth?.user?.name ?? '-'} disabled />
                </FormControl>
                <FormControl className={classes.formItem} fullWidth>
                  <TextField name="company" fullWidth label="Cégnév" value={auth?.user?.organization_name ?? '-'} disabled />
                </FormControl>
                <FormControl className={classes.formItem} fullWidth>
                  <TextField name="contact" fullWidth label="E-Mail cím" value={auth?.user?.email ?? '-'} disabled />
                </FormControl>
                <FormControl className={classes.formItem} fullWidth>
                  <TextField name="phone" fullWidth label="Telefonszám" value={auth?.user?.phone ?? '-'} disabled />
                </FormControl>
                {
                  profileData.admin_text ? <Alert severity="warning" className={classes.formItem}>{profileData.admin_text}</Alert> : <></>
                }
                {profileData.shippingAddr?.map((item, index) => (
                  <FormControl className={classes.formItem} fullWidth>
                    <TextField name="phone" fullWidth label="Szállítási cím" value={item.city + ', ' + item.address} disabled />
                  </FormControl>
                ))}
                {profileData.billingAddr?.map((item, index) => (
                  <FormControl className={classes.formItem} fullWidth>
                    <TextField name="phone" fullWidth label="Számlázási cím" value={item.city + ', ' + item.address} disabled />
                  </FormControl>
                ))}
                <Typography>
                  Kedves Ügyfelünk, a megjelenített adatok tájékoztató jellegűek. Módosítási kérés, vagy helytelen
                  adatok
                  megjelenése esetén kérjük vegye fel a kapcsolatot az Ügyfélszolgálatunkkal!
                </Typography>
              </form>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={classes.pwchange}>
              <form onSubmit={onSubmitPasswordChange}>
                {
                  passwordResetSuccess !== undefined ? (
                    <Box mb={2}>
                      <Alert severity={passwordResetSuccess ? 'success' : 'warning'} variant={'outlined'}>
                        {passwordResetMessage}
                      </Alert>
                    </Box>
                  ) : <></>
                }
                <FormControl className={classes.formItem} fullWidth>
                  <TextField
                    type="password"
                    name="password"
                    onChange={handleChangePassword}
                    fullWidth
                    label="Régi jelszó"
                    value={data.password}
                  />
                </FormControl>
                <FormControl className={classes.formItem} fullWidth>
                  <TextField
                    type="password"
                    name="new_password"
                    onChange={handleChangePassword}
                    fullWidth
                    label="Új jelszó"
                    value={data.new_password}
                  />
                </FormControl>
                <FormControl className={classes.formItem} fullWidth>
                  <TextField
                    type="password"
                    name="new_password_confirm"
                    onChange={handleChangePassword}
                    fullWidth
                    label="Új jelszó újra"
                    value={data.new_password_confirm}
                  />
                </FormControl>
                <button type="submit" className="custom-button-brown" variant="contained">
                  Jelszó változtatása
                </button>
              </form>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={classes.notification}>
              <Typography variant={"h4"}>
                Értesítések
              </Typography>
              <Box mt={3}>
                <Typography>
                  Kérjük jelölje be milyen típusú értesítéseket szeretne kapni a rendszertől!
                </Typography>
                <Box mt={1}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={true}
                      color="primary"
                      name="order_created"
                      disabled={true}
                    />
                    }
                    label="Rendelés létrejött"
                  />
                </Box>
                <Box mt={1}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={orderProcessedNoti}
                      color="primary"
                      onChange={(e) => setOrderProcessedNoti(e.target.checked)}
                      name="order_processed"
                    />
                    }
                    label="Rendelés feldolgozása"
                  />
                </Box>
                <Box mt={1}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={orderStatusChange}
                      color="primary"
                      onChange={(e) => setOrderStatusChange(e.target.checked)}
                      name="order_status_change"
                    />
                    }
                    label="Rendelés státuszváltozások"
                  />
                </Box>
                <Box mt={1}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={newsletterNoti}
                      color="primary"
                      onChange={(e) => setNewsletterNoti(e.target.checked)}
                      name="newsletter_noti"
                      disabled={true}
                    />
                    }
                    label="Hírlevelek (hamarosan)"
                  />
                </Box>
              </Box>
              <Box mt={4} textAlign="right">
                <Button
                  variant={'contained'}
                  onClick={notiSave}
                  color={'secondary'}
                >Mentés</Button>
              </Box>
            </div>
          </TabPanel>
          {/* <TabPanel style={{ width: '100%' }} value={value} index={3}>
            <PricingEdit />
          </TabPanel> */}
          <TabPanel style={{ width: '100%' }} value={value} index={3}>
            <div>
              <Typography style={{paddingBottom: '16px'}} variant={'h4'}>
                Kapcsolat
              </Typography>
              <Grid container spacing={5} className={classes.contact_person_container}>
                {contactInfos.map((info, index) => (
                <Grid item xs={12} md={4}>
                  <Box className={classes.contact_person}>
                    <Typography style={{backgroundColor: "#B2A77D", color: "#fff", padding: '8px', fontWeight: 'bold'}}>{info.name}</Typography>
                    {info.email ?
                    <Box style={{display: "flex", padding: '8px', justifyContent: 'center'}}>
                      <MailIcon style={{ marginRight: "10px" }} />
                      <Typography>
                        {info.email ?? ""}
                      </Typography>
                    </Box>
                    : <></>}
                    {info.phone ?
                    <Box style={{display: "flex", padding: '8px', justifyContent: 'center'}}>
                      <PhoneIcon style={{ marginRight: "10px" }} />
                      <Typography>
                        {info.phone ?? ""}
                      </Typography>
                    </Box>
                    : <></>}
                  </Box>
                </Grid>
                ))}
              </Grid>
            </div>
          </TabPanel>
          <TabPanel style={{ width: '100%' }} value={value} index={4}>
            <div className={classes.arlista}>
              <Typography style={{paddingBottom: '16px'}} variant={'h4'}>
                Aktuális árlistáink:
              </Typography>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                      {Object.keys(arlistaNames).map((arlista_filename, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Box>{arlista_filename}</Box>
                            <Box>
                              <Button
                                  href={arlistaNames[arlista_filename]}
                                  target="_blank"
                                  style={{marginLeft: '10px', paddingRight: '5px'}}
                                  variant={'contained'}
                                  color={'secondary'}
                                  startIcon={<Visibility />}
                                >
                                </Button>
                              </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileEdit);
