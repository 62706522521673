import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, AuthState } from '../data-management/authSlice';
import { RootState } from '../data-management/store';
import { useToken } from './useToken';
import { useCallback } from 'react';

type UseTokenRefreshHookType = () => (onFail: () => void) => Promise<void>

export const useTokenRefresh: UseTokenRefreshHookType = () => {

  const user = useSelector<RootState, AuthState['user'] | undefined>(state => state.auth.user)
  const dispatch = useDispatch()
  const token = useToken()

  return useCallback((onFail) => token !== '' ? axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/auth/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      const accessToken = res.data.access_token;
      const expire = new Date().getTime() + parseInt(res.data.expires_in);

      dispatch(authActions.refreshToken({
        accessToken,
        expire: expire.toString()
      }))
    })
    .catch((err) => {
      dispatch(authActions.failedLogin(err.message ?? ''))
      onFail()
    }) : Promise.resolve(),
    [user, token])
}
