import React, { FormEventHandler, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { SettingsType, useSettingsGet } from '../hooks/useSettingsGet';
import { useSettingsSet } from '../hooks/useSettingsSet';
import { HolidayArrayPickerWrapper } from './HolidayArrayPickerWrapper';
import { useDispatch } from 'react-redux';
import { flashActions } from '../data-management/flashSlice';
import { StringSetting } from './StringSetting';
import { StringArraySetting } from './StringArraySetting';
import { IntegerSetting } from './IntegerSetting';
import { BooleanSetting } from './BooleanSetting';
import axios from 'axios';
import { Input } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useToken } from '../hooks/useToken';
import { CloudDownload } from '@material-ui/icons';
import fileDownload from 'js-file-download';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  newBtn: {
    float: 'right',
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'unset !important',
    color: '#fff',
  },
  gap: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    minWidth: 400
  },
  uploadButton: {
    marginLeft: '20px',
    borderRadius: '12px'
  }
}));

export const SettingListPage = () => {

  const [data, setData] = useState<SettingsType>({});
  const [name, setName] = useState("Fájl csatolása");
  const [selectedFile, setSelectedFile] = useState<any | null>(null);

  const fileSelectedHandler = (event: any) => {
    setSelectedFile(event.target.files[0])
    setName(event.target.files[0].name);
  }

  const fileUploadHandler = () => {
    const fd = new FormData();
    fd.set('file', selectedFile, name)
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/displayrules`,
      fd,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(res => {
        dispatch(flashActions.addFlashMessage('A feltöltés sikeres!'))
        console.log(res);
      }).catch(res => {
        console.log(res);
      });
  }

  const fileDownloadHandler = async () => {
    const response = await axios.get<ArrayBuffer>(
      `${process.env.REACT_APP_SERVER_URL}/displayrules/download`,
      {
        responseType: 'blob',
        //data: input.body,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      },
    );

    dispatch(flashActions.addFlashMessage('A sikeres letöltés!'))

    const { data, status } = response;

    fileDownload(
      data,
      "Nettfront_szabalyok.csv",
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
  }

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (null !== hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const classes = useStyles();

  const settingsGetApi = useSettingsGet();
  const settingsSetApi = useSettingsSet();
  const dispatch = useDispatch();
  const token = useToken();

  useEffect(() => {
    settingsGetApi().then((data) => {
      setData(data);
    });
  }, []);

  const handleSubmit: FormEventHandler<Element> = (e) => {
    e.preventDefault();
    settingsSetApi(data).then(() => {
      dispatch(flashActions.addFlashMessage('Beállítások mentése sikeres!'))
    }).catch(() => {
      dispatch(flashActions.addFlashMessage('Beállítások mentése sikertelen!!'))
    });
  };

  const keys = Object.keys(data);

  return (
    <div className={classes.root}>
      <Button color="primary" className={classes.newBtn} variant="contained">
        <Link to="/settings/create" className={classes.link}>
          Új beállítás létrehozása
        </Link>
      </Button>
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: 'bold' }}>
                  Kulcs
                </TableCell>
                <TableCell variant="head" style={{ fontWeight: 'bold' }}>
                  Érték
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys?.map((key, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {data[key].label ?? key}
                  </TableCell>
                  <TableCell>
                    {
                      data[key].type === 'string' ? (
                        <StringSetting
                          setSetting={setData}
                          setting={data[key]}
                          name={key}
                        />
                      ) : <></>
                    }
                    {
                      data[key].type === 'string_array' ? (
                        <StringArraySetting
                          setSetting={setData}
                          setting={data[key]}
                          name={key}
                        />
                      ) : <></>
                    }
                    {
                      data[key].type === 'object_array' ? (
                        <HolidayArrayPickerWrapper
                          name={key}
                          setting={data[key]}
                          setSetting={setData}
                        />
                      ) : <></>
                    }
                    {
                      data[key].type === 'integer' ? (
                        <IntegerSetting
                          name={key}
                          setting={data[key]}
                          setSetting={setData}
                        />
                      ) : <></>
                    }
                    {
                      data[key].type === 'boolean' ? (
                        <BooleanSetting
                          name={key}
                          setting={data[key]}
                          setSetting={setData}
                        />
                      ) : <></>
                    }
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell component="th" scope="row">
                  Feltöltés
                </TableCell>
                <TableCell>
                  <input
                    onChange={fileSelectedHandler}
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                  />
                  <Input
                    onClick={handleClick}
                    value={name}
                    className={classes.fileInput}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    onClick={fileUploadHandler}
                    disabled={selectedFile ? false : true}
                    className={classes.uploadButton}
                    startIcon={<CloudUploadIcon />}
                  >
                    Fájl feltöltése
                  </Button>
                  <Button
                    variant="contained"
                    component="label"
                    onClick={fileDownloadHandler}
                    className={classes.uploadButton}
                    startIcon={<CloudDownload />}
                  >
                    Jelenlegi letöltés
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item lg={12}>
          <Button className={classes.gap} type="submit" variant="contained" color="primary">
            Mentés
          </Button>
        </Grid>
      </form>
    </div>
  );
};
