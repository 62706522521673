import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';

type UseTokenHookType = () => string

export const useToken: UseTokenHookType = () => {

  const token = useSelector<RootState, string | undefined>(
    state => state.auth.user?.accessToken
  )

  return token ?? '';
}
