import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { HolidayType } from '../Settings/HolidayArrayPickerWrapper';

export type SettingType = {
  type: 'string' | 'boolean' | 'integer' | 'string_array' | 'object_array'
  label: string
  value: string | string[] | HolidayType[] | number
}

export type SettingsType = {
  [name: string]: SettingType
}

type UseSettingsGetHookType = () => () => Promise<SettingsType>

export const usePrivacyGet: UseSettingsGetHookType = () => {

  const token = useToken();

  return useCallback(async () => {
    const {data} = await axios
      .get<SettingsType>(`${process.env.REACT_APP_SERVER_URL}/privacy`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

    return data
  }, [token])
}
