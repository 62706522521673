import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type ProductItemRemoveModalProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ProductItemRemoveModal: FC<ProductItemRemoveModalProps> = (
  {
    open,
    onClose,
    onConfirm
  }
) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Biztosan törölni szeretné a terméket?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Törlés után a művelet nem visszavonható!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Mégse
        </Button>
        <Button onClick={onConfirm} color="secondary" autoFocus>
          Igen törlöm
        </Button>
      </DialogActions>
    </Dialog>
  );
};