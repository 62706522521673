import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { AuthState } from '../data-management/authSlice';
import { useMemo } from 'react';

type UseIsTermsAgreedHookType = () => boolean;

export const useIsTermsAgreed: UseIsTermsAgreedHookType = () => {

  const user = useSelector<RootState, AuthState['user'] | undefined>(state => state.auth.user)

  return useMemo<boolean>(() => {
    if (!user)
      return false;

    return user.terms_agreed;
    
  }, [user])
}