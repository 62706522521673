import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import { TypeSwitchItem } from './TypeSwitchItem';
import ArrowBack from '@material-ui/icons/ArrowBack';
import axios from 'axios';
import { ConfirmBackModal } from '../Order/ConfirmBackModal';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useCategoryId } from '../../hooks/useCategoryId';
import { useToken } from '../../hooks/useToken';
import { useSelectedOrganisationId } from '../../hooks/useSelectedOrganisationId';
import { FieldType } from '../../data-management/productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useIsTermsAgreed } from '../../hooks/useIsTermsAgreed';
import { SettingsType, useSettingsGet } from '../../hooks/useSettingsGet';
import { Alert } from '@material-ui/lab';
import { authActions } from '../../data-management/authSlice';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css'; // ES6
import { useOrderCartDeleter } from '../../hooks/useOrderCartDeleter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Link } from "react-router-dom";
import { RootState } from '../../data-management/store';
import { orderActions, OrderItem } from '../../data-management/orderSlice';
import { useProducts } from '../../hooks/useProducts';
import { useOrderLoader } from '../../hooks/useOrderLoader';
import ReportIcon from '@material-ui/icons/Report';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 300,
    paddingBottom: theme.spacing(5),
    paddingLeft: '24px',
  },
  pullRight: {
    textAlign: 'right',
    float: 'right',
  },
  warningArea: {
    display: "flex", 
    justifyContent: "space-between", 
    backgroundColor: "#fff3cd", 
    padding: "15px", 
    margin: "20px auto",
    width: "80%",
  },
}));

type Category = {
  id: string | number
  name: string
  fields: FieldType[]
}

export const TypeSwitch = () => {
  const classes = useStyles();
  const token = useToken();
  const settingsGetApi = useSettingsGet();

  const [oldCatId, setOldCatId] = useState(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [onlyTermsAgreed, setOnlyTermsAgreed] = useState(false);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState<any>({terms: "", privacy: ""});
  const [disabledCategories, setDisabledCategories] = useState<any[]>([]);
  const selectedOrganisationId = useSelectedOrganisationId();
  const isAdmin = useIsAdmin();
  const isTermsAgreed = useIsTermsAgreed();
  const { catId, setCatId } = useCategoryId();
  const deleteCart = useOrderCartDeleter(token);
  const dispatch = useDispatch()
  const loadOrders = useOrderLoader(token);

  const products = useProducts();
  const orderItems = useSelector<RootState, OrderItem[]>(
    (state) => state.order.orders,
  );
  const empty = !orderItems || orderItems.length === 0;  
  const emptyCart = !products || products.length === 0 || (products.length === 1 && !products[0].product_id);  

  const closeBackModal = () => {
    setOpenBackModal(false);
  };

  const confirmBackModal = () => {
    setCatId(undefined);
    deleteCart();
    setOpenBackModal(false);
  };

  const resetUnfinishedOrder = () => {
    setCatId(undefined);
    deleteCart();
  };

  const termsAgreedCall = () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/termsagree`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    dispatch(authActions.termsAgree(true));
  };

  useEffect(() => {
    settingsGetApi().then((settings : any) => {
      if(settings.can_edit_order_item){
        setTermsAndPrivacy({terms: settings.terms.value, privacy: settings.privacy.value});
      }
      const categorySettingKeys = Object.keys(settings).filter((setting_key) => setting_key.startsWith("categorydisable"));
      let disabledCategoriesArray : any[] = [];
      categorySettingKeys.forEach(function(value) {
        if(settings[value].value === "1" || settings[value].value === 1){
          disabledCategoriesArray.push(parseInt(value.split("_")[1]));
        }
      });
      setDisabledCategories(disabledCategoriesArray);
      setTermsAndPrivacy({terms: settings.terms.value, privacy: settings.privacy.value});
    });
    
    axios.get(`${process.env.REACT_APP_SERVER_URL}/order/category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({data}) => {
      setOldCatId(data.category_id)
      loadOrders();
    })
    .catch((err) => {});
  }, []);

  const handleClick: (
    id: number | string | undefined,
    fields: FieldType[]
  ) => void = (id, fields) => {
    if (catId === undefined) {
      setCatId(id, fields);
      dispatch(orderActions.resetOrderItems())
    }
  };

  useEffect(() => {
    axios
      .get<Category[]>(`${process.env.REACT_APP_SERVER_URL}/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <Typography className={classes.title} variant="h4">
        {isTermsAgreed ? "Termékek" : "Általános szerződési feltételeink és adatvédelmi nyilatkozatunk"}
          {
            catId !== undefined ? <div className={classes.pullRight}>
              <p>
                <a style={{
                  color: '#B2A77D',
                  textDecoration: 'underline',
                  marginRight: 20,
                  fontSize: 'large',
                  fontWeight: 'bolder',
                  display: 'inline-flex',
                  float: 'right',
                  alignItems: 'center',
                  cursor: 'pointer',
                }} onClick={() => {
                  setOpenBackModal(true);
                }}><ArrowBack />Vissza</a><br />
                <span style={{
                  display: 'inline-block',
                  marginRight: 20,
                }}> Visszalépéskor a kitöltött adatok elvesznek. </span>
              </p>
            </div> : <></>
          }
        </Typography>
        {
          (isTermsAgreed) ? (isAdmin && selectedOrganisationId === -1 ?
            <Typography variant={'h2'} align={'center'}>
              Adminként kötelező céget választani.
            </Typography>
            : <>
            {catId === undefined && oldCatId && (!empty || !emptyCart) ? 
                <Box className={classes.warningArea}>
                  <Box style={{display: "flex", alignItems: "center"}}>
                    <ReportIcon fontSize="large" color={'inherit'} /> 
                    <Typography variant={'h6'} align={'center'} style={{marginLeft: '5px'}}>Befejezetlen rendelése van!</Typography>
                  </Box>
                  <Box style={{display: "flex", justifyContent: "end"}}>
                    <a className="custom-button-brown" style={{marginRight: '15px', padding: '12px 40px'}} onClick={() => handleClick(oldCatId, categories.filter(({id}) => id == oldCatId)[0].fields)}>Megtekintés</a> 
                    <a className="custom-button-brown" style={{padding: '12px 40px'}} onClick={resetUnfinishedOrder}>Elvetés</a>
                  </Box>
              </Box> : <></>
            }
            <Grid container spacing={4} justify={'center'}>
              {categories.map((cat, index) => (
                 disabledCategories.includes(cat.id) ? <></> :
                <Grid item key={index}>
                  <TypeSwitchItem
                    id={cat.id}
                    name={cat.name == "RAKTARI" ? 'RAKTÁRI TERMÉKEK' : (cat.name + ' FRONT')}
                    activeType={catId}
                    onClick={() => handleClick(cat.id, cat.fields)}
                  />
                </Grid> 
              ))}
              <Grid item>
                <TypeSwitchItem
                  id="custom"
                  name="Egyedi tervrajz alapján"
                  activeType={catId}
                  onClick={() => handleClick('-1', [])}
                />
              </Grid>
            </Grid>
            {catId === undefined ? <Grid container spacing={4} style={{margin: '70px auto', maxWidth: '450px', background: '#eee'}} justify={'center'}>
              <Box style={{display: 'flex', flexDirection: 'row'}}>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                  <YouTubeIcon color="error" style={{ fontSize: 130 }}/>
                </Box>
                <Box borderLeft="1px solid red" style={{padding: '18px'}}>
                  <Box style={{marginBottom: '10px'}}><b>Kedves Ügyfelünk!</b></Box>
                  <Box style={{marginBottom: '20px'}}>A rendelő felület használatával kapcsolatos bemutatóvideónkat az alábbi gombra kattintva tekinthetik meg a Nettfront Youtube csatornáján!</Box>
                  <Box style={{textAlign: "right"}}>
                    <Button
                      target={'_blank'}
                      href={'https://www.youtube.com/channel/UCYJSUWAMRBfIJ0rz0p3DaBg'}
                      color={'primary'}
                      variant={'contained'}
                    >
                      Megnézem a videókat
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>: <></>}
            </>
          ) : (onlyTermsAgreed) ? <Box className={"ql-snow"}>
            <Box className={"ql-editor"}>{parse(termsAndPrivacy.privacy)}</Box>
              <Box style={{textAlign: "end"}}>
                <Button type="submit" variant="contained" color="primary" onClick={termsAgreedCall}>
                  Elfogadom
                </Button>
              </Box> 
            </Box> 
          : 
          <Box className={"ql-snow"}>
            <Box className={"ql-editor"}>{parse(termsAndPrivacy.terms)}</Box>
            <Box style={{textAlign: "end"}}>
              <Button type="submit" variant="contained" color="primary" onClick={() => setOnlyTermsAgreed(true)}>
                Elfogadom
              </Button>
            </Box>
          </Box>
        }
      </Container>
      <ConfirmBackModal
        open={openBackModal}
        onBack={closeBackModal}
        onConfirm={confirmBackModal}
      />
    </div>
  );
};

