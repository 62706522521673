import { Typography, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const Temp = () => {
	return (
		<div style={{ margin: "50px", textAlign: "center" }}>
			<Typography variant="h5" color="primary">
				Sajnáljuk, de ez az oldal még fejlesztés alatt van!
			</Typography>
			<Button variant="link" color="primary" style={{ marginTop: "20px" }}>
				<Link to="/order" style={{ textDecoration: "none" }}>
					Vissza a rendelésekhez
				</Link>
			</Button>
		</div>
	);
};

export default Temp;
