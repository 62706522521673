import axios from 'axios';
import { useDispatch } from 'react-redux';
import { orderActions } from '../data-management/orderSlice';
import { useCallback } from 'react';

type UseOrderCartDeleterHookType = (
  token: string,
) => () => Promise<void>

export const useOrderCartDeleter: UseOrderCartDeleterHookType = (
  token,
) => {

  const dispatch = useDispatch();

  return useCallback(() => {

    dispatch(orderActions.loadOrderItems())

    return axios.delete(`${process.env.REACT_APP_SERVER_URL}/order/cart`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({data}) => {
        dispatch(orderActions.resetOrderItems())
      })
      .catch((err) => {
        dispatch(orderActions.failedLoadOrder(err.message));
      });
  }, [token]);
};