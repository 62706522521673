import { FieldType, ProductWithKey } from './productsSlice';

export const requiredCheck: (product: ProductWithKey, fields: FieldType[]) => boolean =
  (product, fields) => {
    if (!product.product_id ||
      product.product_id === '' ||
      product.quantity === undefined
    )
      return false;

    const someMissing = fields.some(
      ({ code, type }) => {
        if (code === 'PROD' || code === 'QUANT')
          return false;

        const feature = product.features.find(({ code: featureCode }) => featureCode === code)

        if (feature) {
          if (type === 'number') {
            return feature.keyed_value === undefined || feature.keyed_value === ''
          } else {
            return false
          }
        }

        return true;
      },
    );

    return !someMissing
  };

