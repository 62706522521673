import React, { FormEventHandler } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { useState } from "react";
import { useContact } from '../hooks/useContact';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { AirlineSeatLegroomReducedTwoTone } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	shadow: {
		boxShadow: "0px 2px 4px 2px rgba(83, 83, 83, 0.16)",
	},
	title: {
		fontWeight: 300,
		color: "#535353",
		marginBottom: "30px",
	},
	sendBtn: {
		position: "relative",
		backgroundColor: "#b2a77d",
		color: "#fff",
		marginLeft: "auto",
		display: "block",
		padding: "12px 50px",
		fontWeight: 300,
		textAlign: "center",
		borderRadius: 0,
		textTransform: "unset",
		fontSize: "16px",
		border: 0,
		"&::after": {
			content: '""',
			display: "block",
			border: "1px solid #ccc",
			position: "absolute",
			top: "50%",
			left: "50%",
			width: "calc(100% - 15px)",
			height: "calc(100% - 15px)",
			transform: "translate(-50%, -50%)",
		},
		"&:hover": {
			backgroundColor: "#b2a77d",
			boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
			cursor: "pointer",
		},
	},
}));

export const ContactForm = () => {
	const classes = useStyles();
	const [data, setData] = useState({
		subject: "",
		message: "",
	});
	const [sent, setSent] = useState<boolean | undefined>(undefined)

	const handleChange: StandardInputProps['onChange'] = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const contactApi = useContact()

	const handleSubmit: FormEventHandler = (e) => {
		setSent(false)
		e.preventDefault();
		contactApi(data).then(() => {
			setData({
				subject: "",
				message: "",
			})
			setSent(true)
		}).catch(() => {
			setSent(undefined)
		});
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={3}>
					<Paper style={{ padding: "15px" }} className={classes.shadow}>
						<Typography variant="h4" className={classes.title}>
							Elérhetőségeink
						</Typography>
						<div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
							<MapIcon style={{ marginRight: "10px" }} />
							<Typography variant="body1" style={{ fontWeight: 300 }}>
								5650 Mezőberény, Gyomai út 26.
							</Typography>
						</div>
						<div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
							<PhoneIcon style={{ marginRight: "10px" }} />
							<Typography variant="body1" style={{ fontWeight: 300 }}>
								+36 66 424 102
							</Typography>
						</div>
						<div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
							<MailIcon style={{ marginRight: "10px" }} />
							<Typography variant="body1" style={{ fontWeight: 300 }}>
								rendeles@nettfront.hu
							</Typography>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={9}>
					{
						sent && (
							<>
							<Paper>
								<Alert variant="filled" severity="success">
									A kapcsolatfelvételi űrlap sikeresen elküldésre került!
								</Alert>
							</Paper>
								<Box mb={3} />
							</>
						)
					}
					<Paper style={{ padding: "40px 20px" }}>
						<Typography
							variant="body1"
							style={{ display: "flex", alignItems: "center", marginBottom: "25px" }}
						>
							<MailIcon style={{ marginRight: "10px", fontWeight: 300 }} />
							Bármilyen kérdése lenne, keressen minket bizalommal.
						</Typography>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={5}>
								<Grid item xs={12} lg={6}>
									<TextField
										fullWidth
										value={data.subject}
										label="Tárgy"
										type="text"
										name="subject"
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12} >
									<TextField
										fullWidth
										multiline
										label="Üzenet"
										value={data.message}
										rows={6}
										onChange={handleChange}
										name="message"
										required
										variant="filled"
									/>
								</Grid>
								<Grid item lg={12}>
									<Button
										type="submit"
										className={classes.sendBtn}
										disabled={sent === false}
									>
										Küldés
									</Button>
								</Grid>
							</Grid>
						</form>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContactForm;
