import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProductList } from '../Components/Product/ProductList';
import { TypeSwitch } from '../Components/Product/TypeSwitch';
import { batch, useDispatch } from 'react-redux';
import axios from 'axios';
import { OrderList } from '../Components/Order/OrderList';
import { FieldType, productActions } from '../data-management/productsSlice';
import { flashActions } from '../data-management/flashSlice';
import { orderActions } from '../data-management/orderSlice';
import { HideOnPrint } from '../Components/HideOnPrint';
import { useCategoryId } from '../hooks/useCategoryId';
import { useToken } from '../hooks/useToken';
import { SizeTable } from '../Components/Product/SizeTable';
import { UniqueProductWrapper } from '../Components/Product/UniqueProductWrapper';
import { useOrderItemAdder } from '../hooks/useOrderItemAdder';
import { useIsAdmin } from '../hooks/useIsAdmin';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { useProducts } from '../hooks/useProducts';
import { SavedOrder } from '../hooks/useSaveProducts';
import { useOrderLoader } from '../hooks/useOrderLoader';
import { productSizeRuleHelper } from '../hooks/useProductSizeRuleValid';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(0.25),
  },
}));

export const Order: FC = () => {

  const classes = useStyles();
  const [download, setDownload] = useState<string | null>(null);
  const {
    catId,
    fields,
    setCatId
  } = useCategoryId();
  const orderBtnRef = useRef(null);
  const token = useToken();
  const products = useProducts();
  const [disableButton, setDisableButton] = useState(false);
  
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const orgId = useSelectedOrganisationId();
  const shouldShow = (!isAdmin || orgId !== -1) && catId;
  const loadOrders = useOrderLoader(token);
  
  const { params: { id, existing } } = useRouteMatch<{
      id: string | undefined
      existing: string | undefined
    }>();
  const [loadId, setLoadId] = useState<undefined | string>(id);
  const [existingLoadId, setExistingLoadId] = useState<undefined | string>(existing);

  const onLoadItems = () => {
    if(!loadId) 
      return;

    dispatch(productActions.resetProducts());
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/order/saved/${loadId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((saved_order_result : any) => {
          dispatch(orderActions.resetOrderItems());
          setCatId(saved_order_result.data.category_id);
          axios.post(
            `${process.env.REACT_APP_SERVER_URL}/order/category`,
            {
              category_id: saved_order_result.data.category_id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          dispatch(productActions.loadProducts(saved_order_result.data.items));
          dispatch(orderActions.setOrderMeta({
            ship_id: saved_order_result.data.ship_id,
            ship_mode: saved_order_result.data.ship_mode,
            note: saved_order_result.data.note,
            purchase_number: saved_order_result.data.purchase_number,
            from_saved_order: true,
          }));
          dispatch(productActions.removeFirstEmpty());
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const onLoadExistingItems = async () => {
    if(!existingLoadId) 
      return;

    dispatch(productActions.resetProducts());
    const {data} = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/order/${existingLoadId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    setCatId(data.category_id);
    loadCategory(data.category_id);
  };

  const loadCategory = async (categoryId: string | number) => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/order/category`,
      {
        category_id: categoryId ? categoryId : null,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then(() => {
      loadOrders();
    });

    if (fields === undefined || fields.length === 0) {
      axios
        .get<{
          id: string | number
          fields: FieldType[]
        }[]>(`${process.env.REACT_APP_SERVER_URL}/categories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(productActions.setFields(
            res.data.find(({ id }) => id === catId)?.fields ?? [],
          ));
        });
    }
  };

  const orderItemAdder = useOrderItemAdder();

  const onCreateOrder = () => {

    setDisableButton(true);
    let activeProducts = products.filter(({ active }) => active);

    activeProducts = activeProducts.map((
      productCopy
    ) => {
      const product = {...productCopy}
      product.features = productCopy.features.map(feature => ({...feature}))
      const sizeRules = productSizeRuleHelper(
        product.validationState,
        product.sizeRules,
        product.sizeRulesFromValidation
      )

      const singleSize = sizeRules?.reduce<[number, number] | false | undefined>((
        single, { height, width },
      ) => {
        if (single === undefined)
          return single;

        const same = height.max === height.min && width.min === width.max;

        return same ? [height.max, width.max] : false;
      }, undefined);

      if (Array.isArray(singleSize)) {
        const heightFeature = product.features.find(({ code }) => code === 'MAG');
        const widthFeature = product.features.find(({ code }) => code === 'SZEL');

        if (heightFeature) {
          heightFeature.keyed_value = singleSize[0].toString()
        }

        if (widthFeature) {
          widthFeature.keyed_value = singleSize[1].toString()
        }
      }

      return product
    });

    const validAll = activeProducts.every(({ validationState }) => validationState === 'correct');

    if (validAll)
      orderItemAdder(activeProducts)
        .then(({ data: res }) => {
          dispatch(productActions.removeActiveProducts());
          dispatch(orderActions.setOrderItems(res));
          dispatch(flashActions.addFlashMessage('termék(ek) sikeresen a rendeléshez adva!'));
        })
        .catch((err) => {
          console.log(err);
          dispatch(flashActions.addFlashMessage(err.message));
        }).finally(() => {
        setDisableButton(false);
      });
    else {
      dispatch(flashActions.addFlashMessage('A kijelölt termékek közül nem mind helyes.'));
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (loadId) {
      //Load saved order items
      onLoadItems();
      setLoadId(undefined);
      //Bocs reactosok de ez tuti megy
      window.history.replaceState(null, "Nettfront Webrendelés", "/order")
      return;
    }
    else if (existingLoadId) {
      //Load sima order items
      onLoadExistingItems();
      setExistingLoadId(undefined);
      //Bocs reactosok de ez tuti megy
      window.history.replaceState(null, "Nettfront Webrendelés", "/order")
    }
    else {
      if (download) {
        window.location.href = download;
      }
      
      if (catId !== undefined) {
        loadCategory(catId);
      }
    }
    
  }, [catId, download]);

  const allActiveCorrect = products
    .filter(({ active }) => active)
    .every(({ validationState, sizeValidationState }) =>
      validationState === 'correct' && sizeValidationState === 'good',
    );

  return (
    <div className={classes.root}>
      <Box>
        <Grid container alignItems="stretch">
          <HideOnPrint item xs={12} component={Grid}>
            <TypeSwitch />
          </HideOnPrint>
          {
            shouldShow ?
              <>
                <SizeTable />
                {
                  catId === '-1' ?
                    <UniqueProductWrapper /> : <></>
                }
                {fields?.length > 0 && catId !== '-1' ?
                  (<Fragment>
                      <HideOnPrint component={Box}>
                        <Grid item xs={12}>
                          <ProductList />
                        </Grid>
                        <Box mt={4} width={'100%'} />
                        <Grid item xs={12}
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <div>
                            <button
                              className="custom-button-brown dark"
                              onClick={onCreateOrder}
                              ref={orderBtnRef}
                              disabled={!allActiveCorrect || disableButton}
                            >
                              Rendelési listához adom
                            </button>
                          </div>
                        </Grid>
                        <Box mt={4} width={'100%'} />
                      </HideOnPrint>
                      <Grid item xs={12}>
                        <OrderList />
                      </Grid>
                    </Fragment>
                  ) : <></>}
              </>
              : <></>
          }
        </Grid>
      </Box>
    </div>
  );
};

