import { useCallback } from 'react';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export type RegistrationFormType = {
  email: string
  emailConfirm: string
  contact: string
  companyPhone?: string

  name: string
  billingPostCode: string
  billingCity: string
  billingAddress: string
  taxNumber: string
  billingPhone: string

  shippingPostCode: string
  shippingCity: string
  shippingAddress: string
  shippingPhone: string
}

type UseRegistrationRequestHookType = () => (
  input: RegistrationFormType
) => Promise<void>

export const useRegistrationRequest: UseRegistrationRequestHookType = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return useCallback(async (input) => {
    if (!executeRecaptcha)
      return Promise.reject();

    const token = await executeRecaptcha();

    return axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/regrequest`,
      {
      ...input,
        "g-recaptcha-response": token
      })
  }, [executeRecaptcha])
}
