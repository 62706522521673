import React, { FormEventHandler, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Paper, Typography, TextField } from '@material-ui/core';
import MailIcon from "@material-ui/icons/Mail";
import { useDispatch } from 'react-redux';
import { flashActions } from '../data-management/flashSlice';
import { useToken } from '../hooks/useToken';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Alert } from '@material-ui/lab';
import { SendTestMailModal } from '../Components/Newsletter/SendTestMailModal';
const ReactQuill = require('react-quill');

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  gap: {
    marginTop: theme.spacing(2),
    textAlign: 'end',
  },
  iconRow: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export const EditNewsletterPatternPage = () => {

  const [content, setContent] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const [patternId, setPatternId] = useState<any>("");

  const [sendModal, setSendModal] = useState<any>("");

  const classes = useStyles();

  const dispatch = useDispatch();
  const token = useToken();

  const {
    params: { id },
  } = useRouteMatch<{
    id: string | undefined;
  }>();

  useEffect(() => {
    if(id){
      axios.get(
        `${process.env.REACT_APP_SERVER_URL}/newsletter/get-newsletter-pattern/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).then((res) => {
        setPatternId(res.data.id);
        setTitle(res.data.title);
        setContent(res.data.content);
      })
    }
  }, []);

  const handleSubmit: FormEventHandler<Element> = (e) => {
    e.preventDefault();
    const url = patternId ? `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-pattern/${patternId}` : `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-pattern`;
    axios.post(
      url, { title, content },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => {
      setPatternId(res.data);
      dispatch(flashActions.addFlashMessage('Mentés sikeres!'));
    })
    .catch((err) => {
      dispatch(flashActions.addFlashMessage('Mentés sikertelen!'));
    });
  };

  return (
    <div className={classes.root}>
      <Grid container direction={'row'} className={classes.root}>
        <Grid item xs={8}>
          <Box className={classes.iconRow}>
            <MailIcon style={{ marginRight: "10px" }} />
            Töltse ki az alábbi adatokat a sablon mentéséhez:
          </Box>
          <form onSubmit={handleSubmit}>
            <Box marginTop={6}>
              <Typography style={{marginBottom: "12px"}} variant="body1">
                Sablon neve
              </Typography>
              <TextField
                fullWidth
                name="note"
                value={title}
                variant="outlined"
                onChange={ (e) => setTitle(e.target.value) }
              />
            </Box>
            <Box marginTop={6}>
              <Typography style={{marginBottom: "12px"}} variant="body1">
                Sablon tartalma
              </Typography>
              <ReactQuill value={content || ''} onChange={(changed : any) => {setContent(changed)}} />
            </Box>
            <Box className={classes.gap}>
              <Alert color="info">A teszt küldéshez a sablon mentése szükséges.</Alert>
            </Box>
            <Box className={classes.gap}>
              <Button onClick={() => setSendModal(true)} variant="contained" color="primary" style={{marginRight: '20px'}}>
                Teszt küldés
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                Mentés
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={4}>
          <Paper style={{padding: "24px 16px", margin: "0px 24px"}}>
            <Typography variant="h4" color="textSecondary">
              Használható változók
            </Typography>
            <Box marginTop={4}>
              <Typography variant="body1">
                <b>{"{NAME}"}</b> {"=>"} Felhasználó neve
              </Typography>
              <Typography variant="body1">
                <b>{"{DATE}"}</b> {"=>"} Email küldéskori dátum
              </Typography>
              <Typography variant="body1">
                <b>{"{TIME}"}</b> {"=>"} Email küldéskori idő
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <SendTestMailModal
        open={!!sendModal}
        patternId={patternId}
        onClose={() => {
          setSendModal(false);
        }}
        onSuccess={() => {setSendModal(false)}}
      />
    </div>
  );
};
