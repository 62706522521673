import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

type UseAdminCatalogueSyncHookType = () => () => Promise<void>

export const useAdminCatalogueSync: UseAdminCatalogueSyncHookType = () => {

  const token = useToken()

  return useCallback(async () => {
    const {data} = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/sync/catalogue`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}
