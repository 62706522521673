import React, { FC, useEffect } from 'react';
import { useProductSizeRulesGetter } from '../../hooks/useProductSizeRulesGetter';
import { ProductWithKey } from '../../data-management/productsSlice';

type ProductSizeRuleManagerProps = {
  product: ProductWithKey
}

export const ProductSizeRuleManager: FC<ProductSizeRuleManagerProps> = React.memo((
  {
    product
  }
) => {

  const sizeRuleGetter = useProductSizeRulesGetter(product.key_id)

  useEffect(() => {
    sizeRuleGetter()
  }, [product])

  return (
    <>
  </>
  );
}, (
  {
    product: prev
  }, {
    product: next
  }
) => {

  if (prev.key_id !== next.key_id)
    return false;

  if (prev.product_id !== next.product_id)
    return false;

  if (prev.features.length !== next.features.length)
    return false;

  const containsMismatch = prev.features.some((
    {
      code,
      keyed_value,
      option_code,
      option_value
    }) => {

    const compare = next.features.find(({code: compareCode}) => code === compareCode)

    if (!compare)
      return true

    if (keyed_value !== compare.keyed_value ||
      option_code !== compare.option_code ||
      option_value !== compare.option_value
    )
      return true

    return false
  })

  return !containsMismatch
});