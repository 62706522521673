import { SettingsType, SettingType } from '../hooks/useSettingsGet';
import React, { FC } from 'react';
import { Switch } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

type BooleanSettingProps = {
  name: string
  setSetting: (inner: (settings: SettingsType) => SettingsType) => void
  setting: SettingType
}

export const BooleanSetting: FC<BooleanSettingProps> = (
  {
    name,
    setting,
    setSetting
  }
) => {

  const onChange: SwitchBaseProps['onChange'] = (e, checked) => {
    setSetting(data => ({
      ...data,
      [name]: {
        ...setting,
        value: checked ? 1 : 0
      },
    }));
  };

  return (
    <Switch
      checked={setting.value === 1 || setting.value === "1"}
      onChange={onChange}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  )
}
