import React, { FormEventHandler, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Paper, Typography, TextField, IconButton } from '@material-ui/core';
import GroupIcon from "@material-ui/icons/Group";
import { useDispatch } from 'react-redux';
import { flashActions } from '../data-management/flashSlice';
import { useToken } from '../hooks/useToken';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  gap: {
    marginTop: theme.spacing(2),
    textAlign: 'end',
  },
  iconRow: {
    display: 'flex',
    alignItems: 'center',
  },
  userRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));

export const EditNewsletterGroupPage = () => {

  const [allUsers, setAllUsers] = useState<any>([]);
  const [allPriceCats, setAllPriceCats] = useState<any>([]);
  const [orgs, setOrgs] = useState<any>([]);
  const [priceCats, setPriceCats] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [selectedPriceCat, setSelectedPriceCat] = useState<any>([]);
  const [description, setDescription] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const [groupId, setGroupId] = useState<any>("");

  const classes = useStyles();

  const dispatch = useDispatch();
  const token = useToken();

  const {
    params: { id },
  } = useRouteMatch<{
    id: string | undefined;
  }>();

  useEffect(() => {
    if(id){
      axios.get(
        `${process.env.REACT_APP_SERVER_URL}/newsletter/get-newsletter-group/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).then((res) => {
        setGroupId(res.data.id);
        setTitle(res.data.title);
        setDescription(res.data.description);
        setOrgs(res.data.org);
        setPriceCats(res.data.price_cats);
      })
    }

    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-group/organizations`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => {
      setAllUsers(res.data.orgs);
      setAllPriceCats(res.data.price_cats);
    })
  }, []);

  const addUser = () => {
    if( !orgs.length || (orgs.length && orgs.filter(function( user: any ) {
      return user.id === selectedUser.id;
    }).length === 0 )){
      setOrgs([...orgs, selectedUser]);
    }
  }

  const addPrice = () => {
    if( !priceCats.length || (priceCats.length && priceCats.filter(function( cat: any ) {
      return cat.id === selectedPriceCat.id;
    }).length === 0 )){
      setPriceCats([...priceCats, selectedPriceCat]);
    }
  }

  const removeUser = (id: number) => {
    setOrgs(orgs.filter(function( user: any ) {
      return user.id !== id;
    }))
  }

  const removePriceCat = (id: number) => {
    setPriceCats(priceCats.filter(function( cat: any ) {
      return cat.id !== id;
    }))
  }

  const handleDescriptionChange: StandardInputProps['onChange'] = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit: FormEventHandler<Element> = (e) => {
    e.preventDefault();
    const url = groupId ? `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-group/${groupId}` : `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-group`;
    const orgIds = orgs.map((org: any) => (org.id));
    const priceCatIds = priceCats.map((priceCat: any) => (priceCat.id));
    axios.post(
      url, { title, description, orgIds, priceCatIds },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => {
      setGroupId(res.data);
      dispatch(flashActions.addFlashMessage('Mentés sikeres!'));
    })
    .catch((err) => {
      dispatch(flashActions.addFlashMessage('Mentés sikertelen!'));
    });
  };

  return (
    <div className={classes.root}>
      <Grid container direction={'row'} className={classes.root}>
        <Grid item xs={8}>
          <Box className={classes.iconRow}>
            <GroupIcon style={{ marginRight: "10px" }} />
            Töltse ki az alábbi adatokat a csoport mentéséhez:
          </Box>
          <form onSubmit={handleSubmit}>
            <Box marginTop={6}>
              <Typography style={{marginBottom: "12px"}} variant="body1">
                Csoport neve
              </Typography>
              <TextField
                fullWidth
                name="note"
                value={title}
                onChange={ (e) => setTitle(e.target.value) }
              />
            </Box>
            <Box marginTop={6}>
              <Typography style={{marginBottom: "12px"}} variant="body1">
                Csoport leírása
              </Typography>
              <TextField
                  fullWidth
                  multiline
                  name="description"
                  value={description || ''}
                  rows="6"
                  variant="outlined"
                  onChange={handleDescriptionChange}
              />
            </Box>
            <Box style={{marginTop: "10px"}}>
              <Typography style={{marginBottom: "10px"}}>
                A legördülő listából adhat hozzá felhasználót a csoporthoz!
              </Typography>
              <Grid container direction={'row'}  alignItems={'center'}>
                <Grid item xs={10}>
                  <Autocomplete
                    freeSolo
                    getOptionLabel={(option: any) => option.label}
                    limitTags={3}
                    fullWidth
                    onChange={(e, value: any) => setSelectedUser(value)}
                    value={selectedUser}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="user_ids"
                        variant="standard"
                      />
                    )}
                    options={allUsers}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="secondary"
                  onClick={addUser}
                  style={{marginLeft: "10px"}}>
                    Hozzáadás
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box style={{marginTop: "10px"}}>
              <Typography style={{marginBottom: "10px"}}>
                A legördülő listából bizonyos árkategóriával rendelkező cégeket adhat a csoporthoz!
              </Typography>
              <Grid container direction={'row'}  alignItems={'center'}>
                <Grid item xs={10}>
                  <Autocomplete
                    freeSolo
                    getOptionLabel={(option: any) => option.label}
                    limitTags={3}
                    fullWidth
                    onChange={(e, value: any) => setSelectedPriceCat(value)}
                    value={selectedPriceCat}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="user_ids"
                        variant="standard"
                      />
                    )}
                    options={allPriceCats}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="secondary"
                  onClick={addPrice}
                  style={{marginLeft: "10px"}}>
                    Hozzáadás
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={6}>
              <Button type="submit" variant="contained" color="secondary">
                Mentés
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={4}>
          <Paper style={{padding: "24px 16px", margin: "0px 24px"}}>
          {priceCats.map((price_cat: any) => (
             <Box className={classes.userRow}>
               <Box>[ÁRKATEGÓRIA] {price_cat.label}</Box>
               <IconButton style={{ padding: "5px" }} onClick={() => removePriceCat(price_cat.id) }>
                  <DeleteIcon style={{ fill: '#B81E22' }}></DeleteIcon>
                </IconButton>
             </Box>
           ))}
           {priceCats.length && orgs.length ? <hr></hr> : <></>}
           {orgs.map((user: any) => (
             <Box className={classes.userRow}>
               <Box>{user.label}</Box>
               <IconButton style={{ padding: "5px" }} onClick={() => removeUser(user.id) }>
                  <DeleteIcon style={{ fill: '#B81E22' }}></DeleteIcon>
                </IconButton>
             </Box>
           ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
