import React, { FC, useEffect, useRef } from 'react';
import { productActions, ProductWithKey } from '../../data-management/productsSlice';
import axios, { CancelTokenSource } from 'axios';
import { useProductValidator } from '../../hooks/useProductValidator';
import { useDispatch } from 'react-redux';

type ProductValidationProps = {
  product: ProductWithKey
}

export const ProductValidation: FC<ProductValidationProps> = React.memo((
  {
    product
  }
  ) => {

    const state = product.validationState;
    const validateProduct = useProductValidator(product.key_id);
    const dispatch = useDispatch()

    const {
      current: holder,
    } = useRef<{
      requestCancelArray: CancelTokenSource[]
    }>({
      requestCancelArray: [],
    });

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (state !== 'incomplete') {
          dispatch(productActions.modifyProductValidationState({
            id: product.key_id,
            validationState: 'processing'
          }))

          timer = setTimeout(() => {

            holder.requestCancelArray.forEach((source) => {
              source.cancel();
            });
            holder.requestCancelArray = [];

            const sourceToken = axios.CancelToken.source();
            holder.requestCancelArray.push(sourceToken);

            validateProduct(
              sourceToken,
            ).finally(() => {
              holder.requestCancelArray.pop();
            });
          }, 500);
        }

        return () => {
          if (timer)
            clearTimeout(timer);
        };
      },
      [product]
    );

  return (<></>);
},
  ({
    product: prev
  }, {
    product: next
  }) => {

    if (prev.key_id !== next.key_id)
      return false;

    if (prev.product_id !== next.product_id)
      return false;

    if (next.quantity === undefined || next.quantity === '')
      return false;

    if (prev.quantity === undefined || prev.quantity === '')
      return false;

    if (prev.features.length !== next.features.length)
      return false;

    const containsMismatch = prev.features.some((
      {
        code,
        keyed_value,
        option_code,
        option_value
      }) => {

      const compare = next.features.find(({code: compareCode}) => code === compareCode)

      if (!compare)
        return true

      if (keyed_value !== compare.keyed_value ||
        option_code !== compare.option_code ||
        option_value !== compare.option_value
      )
        return true

      return false
    })

    return !containsMismatch
  }
);
