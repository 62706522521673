import storageSession from 'redux-persist/lib/storage/session'

const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

export const reduxStorage = typeof window !== 'undefined' ? storageSession  : createNoopStorage();
