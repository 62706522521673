import { ProductWithKey } from '../data-management/productsSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';

type UseProductHookType = (productId: number) => ProductWithKey

export const useProduct: UseProductHookType = (productId) =>
  useSelector<RootState, ProductWithKey | undefined>(
  state => state.products.products.find(({key_id: id}) => id === productId)
) ?? ({
  active: false,
  product_id: -1,
  quantity: 1,
  extra_count: 0,
  features: [],
  note: '',
  key_id: productId,
  validationState: 'incomplete',
  sizeValidationState: 'unknown'
})