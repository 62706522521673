import React, { FC, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, makeStyles, TextField, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Alert, Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { useToken } from '../../hooks/useToken';
import { flashActions } from '../../data-management/flashSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type SendTestMailModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  patternId: number
}

export const SendTestMailModal: FC<SendTestMailModalProps> = React.memo((
  {
    open,
    onClose,
    onSuccess,
    patternId,
  },
) => {

  const classes = useStyles()

  const [error, setError] = useState<string | undefined>(undefined)
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [users, setUsers] = useState<any>([])

  const token = useToken();
  const dispatch = useDispatch();

  const onModalClose = () => {
    onClose();
  }

  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-pattern/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => {
      setUsers(res.data);
    })
  }, []);

  const onConfirm = () => {
    if(selectedUsers.length == 0){
      setError("Válasszon címzettet!")
    }else{
      setError("");
    }

    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-pattern/send/${patternId}`, { user_ids: selectedUsers.map((user: any) => user.id) },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => {
      onSuccess();
      dispatch(flashActions.addFlashMessage('Küldés sikeres!'));
    })
    .catch((err) => {
      dispatch(flashActions.addFlashMessage('Küldés sikertelen!'));
    });
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Kérjük add meg az email címeket, ahova a tesztet küldjük
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          error ?
            <Alert severity={'error'}>
              {error}
            </Alert> : <></>
        }
        <Typography style={{marginTop: "10px"}}>
          Válassza ki a teszt üzenet címzettjeit!
        </Typography>
        <Autocomplete
          multiple
          freeSolo
          getOptionLabel={(option) => option.label}
          limitTags={3}
          fullWidth
          onChange={(e, value: any) => setSelectedUsers(value)}
          value={selectedUsers}
          renderInput={(params) => (
            <TextField
              {...params}
              name="user_ids"
              variant="standard"
            />
          )}
          options={users}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onConfirm}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Küldés
        </Button>
      </DialogActions>
    </Dialog>
  );
});
