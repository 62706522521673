import { useToken } from './useToken';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import axios from 'axios';
import { Address, authActions } from '../data-management/authSlice';

type UseOrganisationAddressLoaderHookType = () => (id: number) => Promise<void>

export const useOrganisationAddressLoader: UseOrganisationAddressLoaderHookType = () => {

  const token = useToken();
  const dispatch = useDispatch();

  return useCallback((id) => {

    return axios.get<Address[]>(
      `${process.env.REACT_APP_SERVER_URL}/order/ship/${id.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then(({data}) => {
      dispatch(authActions.loadOrganisationAddress({
        id,
        addresses: data
      }))
    });
  }, [token]);
};