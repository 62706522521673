import React, { FC, useState } from 'react';
import { Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { useSaveOrderDeleter } from '../../hooks/useSavedOrderDeleter';
import { useToken } from '../../hooks/useToken';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type DeleteModelProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteModal: FC<DeleteModelProps> = React.memo((
  {
    open,
    onClose,
    onConfirm,
  },
) => {

  const classes = useStyles()
  const token = useToken();

  const [error, setError] = useState<string | undefined>(undefined)

  const onModalClose = () => {
    onClose();
  }

  const onModalConfirm = () => {
    onConfirm();
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Törlés
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Biztosan törli az adott sort?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onModalConfirm}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Törlés
        </Button>
      </DialogActions>
    </Dialog>
  );
});
