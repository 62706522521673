import { Address } from '../data-management/authSlice';
import { useIsAdmin } from './useIsAdmin';
import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { useOrganisations } from './useOrganisations';
import { useMemo } from 'react';

type UseAddressesHookType = () => Address[] | undefined

export const useAddresses: UseAddressesHookType = () => {

  const isAdmin = useIsAdmin();
  const organisations = useOrganisations();
  const selectedOrganisationId = useSelector<RootState, number | undefined>(
    state => state.auth.selectedOrganisation
  )
  const addresses = useSelector<RootState, Address[] | undefined>(
    state => state.auth.user?.shippingaddresses
  )

  return useMemo(() => {
    if (!isAdmin || !selectedOrganisationId || !organisations)
      return addresses;

    const selectedOrganisation = organisations.find(({id}) => id === selectedOrganisationId)

    if (!selectedOrganisation)
      return addresses;

    if (!selectedOrganisation.addresses) {
      return []
    }

    return selectedOrganisation.addresses;
  }, [isAdmin, organisations, selectedOrganisationId])
}