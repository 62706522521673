import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadedFile } from '../hooks/useUniqueFileUploader';

export type UniqueProduct = {
  description: string
  files: UploadedFile[],
}

export type UniqueProductsState = {
  uniqueProducts: UniqueProduct[]
  description: string
  purchasingNumber: string
}

const initialState: UniqueProductsState = {
  uniqueProducts: [],
  description: '',
  purchasingNumber: ''
}

type UniqueProductsReducer<T = void> = CaseReducer<UniqueProductsState, PayloadAction<T>>

type Reducers = {
  modifyDescription: UniqueProductsReducer<string>,
  modifyPurchasingNumber: UniqueProductsReducer<string>,
  reset: UniqueProductsReducer,
  addProduct: UniqueProductsReducer,
  removeProduct: UniqueProductsReducer<number>,
  modifyProductDescription: UniqueProductsReducer<{
    index: number,
    description: string
  }>,
  addProductFiles: UniqueProductsReducer<{
    index: number,
    files: UploadedFile[]
  }>,
  removeProductFile: UniqueProductsReducer<{
    index: number
    fileIndex: number
  }>
}

export const uniqueProductSlice = createSlice<UniqueProductsState, Reducers>(
  {
    name: 'uniqueProducts',
    initialState,
    reducers: {
      reset: () => initialState,
      modifyDescription: (state, {payload}) => {
        state.description = payload
      },
      modifyPurchasingNumber: (state, {payload}) => {
        state.purchasingNumber = payload
      },
      addProduct: (state) => {
        state.uniqueProducts.push({
          description: '',
          files: []
        })
      },
      removeProduct: (state, {payload: index}) => {
        state.uniqueProducts.splice(index, 1)
      },
      modifyProductDescription: (state, {payload: {index, description}}) => {
        if (state.uniqueProducts[index])
          state.uniqueProducts[index].description = description
      },
      addProductFiles: (state, {payload: {index, files}}) => {
        if (state.uniqueProducts[index])
          state.uniqueProducts[index].files = state.uniqueProducts[index].files.concat(files)
      },
      removeProductFile: (state, {payload: {index, fileIndex}}) => {
        if (state.uniqueProducts[index]) {
          if (state.uniqueProducts[index].files[fileIndex]) {
            state.uniqueProducts[index].files.splice(fileIndex, 1);
          }
        }
      }
    }
  }
)

export const uniqueProductsActions = uniqueProductSlice.actions
export const uniqueProductsReducer = uniqueProductSlice.reducer
