import { useCallback } from 'react';
import axios from 'axios';

type UseNewsletterGroupDeleterHookType = (token: string) => (id: number) => Promise<void>

export const useNewsletterGroupDeleter: UseNewsletterGroupDeleterHookType = (token) => {

  return useCallback((id) => {

    return axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-group/${id.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }, [token])
}
