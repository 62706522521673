import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { Role } from './useAdminRoleList';

type UseAdminStatusChangeHookType = () => (id: number, status: number) => Promise<string>

export const useAdminStatusChange: UseAdminStatusChangeHookType = () => {

  const token = useToken()

  return useCallback(async (id, status) => {
    const {data} = await axios.post<string>(
      `${process.env.REACT_APP_SERVER_URL}/users/${id.toString()}/status`,
      {
        status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}