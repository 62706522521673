import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Role, useAdminRoleList } from '../hooks/useAdminRoleList';
import { useAdminUserRoles } from '../hooks/useAdminUserRoles';
import { useAdminUserChangeRoles } from '../hooks/useAdminUserChangeRoles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

type EditUserRoleModalProps = {
  open: boolean
  onClose: () => void
  onSave: (roles: Role[]) => void
  id?: number
  userName?: string
}

export const EditUserRoleModal: FC<EditUserRoleModalProps> = (
  {
    onClose,
    open,
    id,
    userName,
    onSave: onSaveProp,
  },
) => {
  const classes = useStyles();

  const [roles, setRoles] = useState<undefined | Role[]>(undefined);
  const [userRoles, setUserRoles] = useState<undefined | Role[]>(undefined);

  const adminRolesApi = useAdminRoleList();
  const adminUserRolesApi = useAdminUserRoles();
  const adminChangeRolesApi = useAdminUserChangeRoles();

  useEffect(() => {
    adminRolesApi().then((data) => {
      setRoles(data);
    });
  }, []);

  useEffect(() => {
    if (open && id && userRoles === undefined) {
      adminUserRolesApi(id).then((data) => {
        setUserRoles(data);
      });
    }
  }, [open, id, roles]);

  const onModalClose = () => {
    onClose();
    setUserRoles(undefined);
  };

  const onSave = async () => {
    if (!userRoles || !id)
      return;

    const newRoles = await adminChangeRolesApi(id, userRoles.map(({ id }) => id));

    setUserRoles(undefined);
    onSaveProp(newRoles);
  };

  const onRoleChange: (id: number) => SwitchBaseProps['onChange'] = (id) => (
    event,
    checked,
  ) => {
    if (checked) {
      const role = roles?.find(({ id: roleId }) => id === roleId);
      const roleIndex = userRoles?.findIndex(({ id: roleId }) => roleId === id);
      if (roleIndex !== undefined && roleIndex !== -1)
        return;

      if (role && userRoles)
        setUserRoles([
          ...userRoles,
          role,
        ]);
    } else {
      const roleIndex = userRoles?.findIndex(({ id: roleId }) => roleId === id);

      if (userRoles && roleIndex !== undefined && roleIndex >= 0) {
        userRoles.splice(roleIndex, 1);
        setUserRoles([...userRoles]);
      }
    }
  };

  return (
    <Dialog
      onClose={onModalClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Felhasználóhoz tartozó szerepkörök: {userName ?? ''}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List subheader={<ListSubheader>Szerepkörök</ListSubheader>} className={classes.list}>
          {
            roles?.map(({ id, name }, index) => (
              <ListItem key={index}>
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={onRoleChange(id)}
                    checked={!!userRoles?.find(({ id: roleId }) => roleId === id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSave}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Mentés
        </Button>
      </DialogActions>
    </Dialog>
  );
};