import React, { FC, useState } from 'react';
import { Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { useSaveOrderDeleter } from '../../hooks/useSavedOrderDeleter';
import { useToken } from '../../hooks/useToken';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
}))

type ConfirmLoadExistingOrderModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const ConfirmLoadExistingOrderModal: FC<ConfirmLoadExistingOrderModalProps> = React.memo((
  {
    open,
    onClose,
    onSuccess,
  },
) => {

  const classes = useStyles()

  const [error, setError] = useState<string | undefined>(undefined)

  const onModalClose = () => {
    onClose();
  }

  const onConfirm = () => {
    onSuccess();
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Meglévő rendelés betöltése
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          error ?
            <Alert severity={'error'}>
              {error}
            </Alert> : <></>
        }
        <Typography>
        Kedves Ügyfelünk! <br/><br/> A funkció használata esetén a rendelés visszakerül szerkesztési állapotba, így véglegesítéséhez még <strong>akkor is kattintson a rendelés gombra, ha nem végzett módosítást</strong>, ellenkező esetben a rendelés nem kerül feldolgozásra. 
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogButtons}>
        <Button
          autoFocus
          onClick={onModalClose}
          color="primary"
          variant={'contained'}
          size={'small'}
        >
          Nem törlöm a megrendelést
        </Button>
        <Button
          autoFocus
          onClick={onConfirm}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Betöltés módosításhoz
        </Button>
      </DialogActions>
    </Dialog>
  );
});
