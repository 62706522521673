import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DisplayRulesState = {
  [name: string]: []
}

const initialState: DisplayRulesState = {}

type DisplayRulesReducer<T = void> = CaseReducer<DisplayRulesState, PayloadAction<T>>

type Reducers = {
  clear: DisplayRulesReducer
  add: DisplayRulesReducer<{name: string, rules: []}>
}

export const DisplayRulesSlice = createSlice<DisplayRulesState, Reducers>(
  {
    name: 'DisplayRules',
    initialState,
    reducers: {
      clear: () => initialState,
      add: (state, {payload: {name, rules}}) => {
        state[name] = rules
      }
    }
  }
)

export const DisplayRulesActions = DisplayRulesSlice.actions
export const DisplayRulesReducer = DisplayRulesSlice.reducer
