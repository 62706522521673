import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close, Edit } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dropzone, { DropzoneOptions } from 'react-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFieldOptions } from '../../hooks/useFieldOptions';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { useProductSimpleImport } from '../../hooks/useProductSimpleImport';
import { Product, productActions } from '../../data-management/productsSlice';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { FieldOption } from '../../data-management/fieldOptionsSlice';
import { EdgeArray, ImportUniqueEdgesSelectorModal } from './ImportUniqueEdgesSelectorModal';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    marginBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dropzone: {
    flex: 1,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 2,
    backgroundColor: 'rgba(229, 229, 229, 0.5);',
    border: '2px dashed #221E1F',
    color: '##221e1f',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
    boxSizing: 'border-box',
    width: '100%',
  },
}));

type ImportProductXlsxProps = {
  fields: {
    code: string
    type: string
    label: string
  }[]
  categoryId: number | string
}

export const ImportProductXlsx: FC<ImportProductXlsxProps> = (
  {
    fields,
    categoryId,
  },
) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fieldOptions, setFieldOptions] = useState<{
    [name: string]: FieldOption[]
  }>({});
  const [selectedValues, setSelectedValues] = useState<{
    [name: string]: number
  }>({});
  const [file, setFile] = useState<undefined | File>(undefined);
  const fieldOptionGetter = useFieldOptions();
  const productSimpleImport = useProductSimpleImport();
  const dispatch = useDispatch();
  const [error, setError] = useState<undefined | string>(undefined);

  const [ralNumber, setRalNumber] = useState<undefined | string>(undefined);

  const [akril4DifferentEdgeModal, setAkril4DifferentEdgeModal] = useState(false);
  const [akril4Edges, setAkril4Edges] = useState<undefined | EdgeArray>(undefined);

  const shouldShowRal = selectedValues['SZIN'] !== undefined && fieldOptions['SZIN']?.find(
    ({ id }) => id === selectedValues['SZIN'])?.code === 'RAL_NCS';

  const shouldShowAkril4Edges = selectedValues['ELZ'] !== undefined && fieldOptions['ELZ']?.find(
    ({ id }) => id === selectedValues['ELZ'])?.code === 'ELZ';

  useEffect(() => {
    fieldOptionGetter(
      categoryId,
      'PROD',
    ).then((data) => {
      setFieldOptions(fieldOptions => ({
        ...fieldOptions,
        PROD: data,
      }));
    });

  }, [categoryId]);

  const reset = () => {
    setSelectedValues({});
    setFile(undefined);
  };

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onImport = async () => {
    if (!file)
      return;

    try {

      const { data } = await productSimpleImport(file);

      const akril4EdgesEnabled = selectedValues['ELZ'] !== undefined && fieldOptions['ELZ']?.find(({id}) => id === selectedValues['ELZ'])?.code === 'ELZ'

      const products = data.map<Product>(({ height, width, quantity }) => ({
        active: true,
        product_id: selectedValues['PROD'],
        quantity,
        minorder: 1,
        order_multiple: 1,
        note: '',
        features: [...Object.entries(selectedValues)
          .filter(([key]) => key !== 'PROD').map(([key, value]) => ({
              code: key,
              option_value: value,
              option_code: fieldOptions[key].find(({ id }) => id === value)?.code,
              ...(key === 'SZIN' && fieldOptions[key].find(({ id }) => id === value)?.code === 'RAL_NCS' ?
                { keyed_value: ralNumber ?? '' } : {}),
            }),
          ),
          ...(
            akril4EdgesEnabled ? ['ELZ1', 'ELZ2', 'ELZ3', 'ELZ4'].map((code, index) => ({
              code,
              option_code: akril4Edges?.[index],
              option_value: fieldOptions['ELZ']?.find(({code: edgeCode}) => akril4Edges?.[index] === edgeCode)?.id
            })) : []
          ),
          ...[
            {
              code: 'MAG',
              option_code: 'meret',
              keyed_value: height,
            },
            {
              code: 'SZEL',
              option_code: 'meret',
              keyed_value: width,
            },
          ],
        ],
      }));
      dispatch(productActions.addProducts(products));
      setError(undefined);
      reset();
      onClose();
    } catch (e) {
      setError('A feltöltött fájl hibás formátumú, kérjük próbálja újra a helyes formátummal.');
      setFile(undefined);
    }
  };

  const onUploadFile: DropzoneOptions['onDrop'] = async (files) => {
    setFile(files[0]);
  };

  const removeFile = () => {
    setFile(undefined);
  };

  const onChange: (code: string) => AutocompleteProps<{
    id: number
    description: string
    status: string
    code: string
  }, undefined, undefined, undefined>['onChange'] = (code) => (e, value, reason) => {

    const indexOfField = fields.findIndex(({ code: findCode }) => code === findCode);
    const removeFields = fields.slice(indexOfField);

    if (reason === 'clear') {
      setSelectedValues(selectedValues => {
        removeFields.forEach(({ code }) => {
          delete selectedValues[code];
        });

        delete selectedValues[code];

        return { ...selectedValues };
      });

      return;
    }

    if (!value)
      return;

    if (code === 'ELZ' && value?.code === 'ELZ')
      setAkril4DifferentEdgeModal(true)

    setSelectedValues(selectedValues => {
      removeFields.forEach(({ code }) => {
        delete selectedValues[code];
      });

      const nextField = fields[indexOfField + 1];

      if (nextField && nextField.type === 'select')
        fieldOptionGetter(
          categoryId,
          nextField.code,
          Object.entries({
            ...selectedValues,
            [code]: value.id,
          }).map(([key, value]) => ({
              code: key,
              value,
            }),
          ),
        ).then((data) => {
          setFieldOptions(fieldOptions => ({
            ...fieldOptions,
            [nextField.code]: data,
          }));
        });

      return {
        ...selectedValues,
        [code]: value.id,
      };
    });
  };

  const allowedInputs = useMemo<string[]>(() => {
    if (!fields)
      return ['PROD'];

    const filteredFields = fields.filter(({ type }) => type === 'select');
    let allowedFields = ['PROD'];

    filteredFields.some(({ code }) => {
      allowedFields.push(code);

      if (selectedValues[code] === undefined)
        return true;

      return false;
    });

    return allowedFields;
  }, [fields, selectedValues]);

  const openAkril4EdgesModal = () => {
    setAkril4DifferentEdgeModal(true);
  };

  const onSuccessAkril4EdgesModal = (edges: EdgeArray) => {
    setAkril4Edges(edges);
    setAkril4DifferentEdgeModal(false);
  };

  const onCloseAkril4EdgesModal = () => {
    setAkril4DifferentEdgeModal(false);
  };

  return (
    <>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={onClick}
        classes={{
          root: classes.buttonRoot,
        }}
      >
        Termékek importálása
      </Button>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth={'sm'}
        PaperProps={{
          square: true,
        }}
      >
        <DialogTitle disableTypography>
          <Typography variant={'h5'}>
            Termékek importálása excel fájlból
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              A rendszer az alábbi sablon alapján dolgozik:
            </Typography>
            <Link href={'/nettfront-import-sablon.xlsx'}>
              Sablon excel fájl
            </Link>
          </Box>
          <Box>
            {
              fields?.filter(({ type }) => type === 'select').map(({ code, label, type }, index) => (
                <Grid container key={index}>
                  <Grid item xs={12}><Box mt={2} /></Grid>
                  <Grid item xs={(code === 'ELZ' && shouldShowAkril4Edges)
                      ? 11 : 12}>
                    <FormControl fullWidth>
                      <Autocomplete
                        noOptionsText={
                          allowedInputs.find((field) => code === field) ?
                          "Nincs elérhető adat." : 'Korábbi mező még nincs kitöltve'
                        }
                        options={
                          allowedInputs.find((field) => code === field) ?
                            fieldOptions[code] ?? [] : []
                        }
                        getOptionLabel={(option) => option.description ?? ''}
                        getOptionDisabled={(option) => ((code === 'SZIN' || code === 'ELZ') && option.status === 'MEGSZUNT') || (code === 'PROD' && option.status === 'MEGSZUNT')}
                        fullWidth
                        autoHighlight
                        autoComplete
                        autoSelect
                        openOnFocus
                        value={fieldOptions[code]?.find(({ id }) => selectedValues[code] === id) ?? null}
                        onChange={onChange(code)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="none"
                            variant="standard"
                            label={label}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {
                    code === 'SZIN' && shouldShowRal ?
                      <Box width={1} mt={2}>
                        <TextField
                        fullWidth
                        inputMode="text"
                        inputProps={{
                          step: 1,
                          min: 0,
                        }}
                        value={ralNumber}
                        onChange={(event) => {
                          setRalNumber( event.target.value );
                        }}
                        label={'RAL szín'}
                      /></Box> : <></>
                  }
                  {
                    code === 'ELZ' && shouldShowAkril4Edges ?
                      <Grid item xs={code === 'ELZ' && shouldShowAkril4Edges ? 1 : 12}>
                        <IconButton
                          onClick={openAkril4EdgesModal}
                        >
                          <Edit />
                        </IconButton>
                      </Grid> : <></>
                  }
                  <Grid item xs={12}><Box mb={2} /></Grid>
                </Grid>
              ))
            }
          </Box>
          {
            error ?
              <Box mb={2}>
                <Alert
                  severity={'error'}
                  variant={'outlined'}
                >
                  {error}
                </Alert>
              </Box> : <></>
          }
          {
            file ? (
              <Box my={2}>
                <Grid container alignItems={'center'} justify={'space-between'}>
                  <Typography>
                    Jelenleg kiválasztott fájl: {file.name}
                  </Typography>
                  <IconButton onClick={removeFile}>
                    <Close />
                  </IconButton>
                </Grid>
              </Box>
            ) : <></>
          }

          <Box>
            <Dropzone
              maxFiles={1}
              multiple={false}
              onDrop={onUploadFile}
              accept={['.xlsx', '.xls']}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className={classes.dropzone} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Fájl kiválasztása
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onImport}
            color="secondary"
            variant={'contained'}
            size={'small'}
            disabled={!file}
          >
            Importálás
          </Button>
        </DialogActions>
      </Dialog>
      <ImportUniqueEdgesSelectorModal
        open={akril4DifferentEdgeModal}
        onClose={onCloseAkril4EdgesModal}
        onSuccess={onSuccessAkril4EdgesModal}
        initialValues={akril4Edges}
        edges={fieldOptions['ELZ']}
      />
    </>
  );
};
