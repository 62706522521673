import { useCallback } from 'react';
import axios from 'axios';
import { useToken } from './useToken';
import { format } from 'date-fns';
import fileDownload from 'js-file-download'

type UseDebugXMLHookType = () => (
  input: {
    note: string,
    ship_id: number,
    purchase_number: string,
    ship_breakdown: boolean,
    group_by_work_days: number | string,
    ship_mode: string | number,
    org_id: number
  }
) => Promise<void>

export const useDebugXml: UseDebugXMLHookType = () => {

  const token = useToken();

  return useCallback(async (input) => {
    const {data: xml} = await axios.post<string | ArrayBuffer | ArrayBufferView | Blob>(
      `${process.env.REACT_APP_SERVER_URL}/order/debug`,
      input,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const fileName = `rendeles_export_${format(new Date(), 'T')}.xml`

    fileDownload(
      xml,
      fileName,
      'text/xml',
    )
  }, [token])
}
