import { batch, useDispatch } from 'react-redux';
import { productActions } from '../data-management/productsSlice';
import { useToken } from './useToken';
import { useProduct } from './useProduct';
import { useCategoryId } from './useCategoryId';
import axios from 'axios';
import { useCallback } from 'react';

type UseProductSizeRulesGetterHookType = (id: number) => (
  width?: boolean
) => Promise<void>

type Response = [
  {
    max_h: number
    max_w: number
    min_h: number
    min_w: number
    required_extras: {
      [name: string]: string
    }[] | {
      [name: string]: string
    }
  }
]

export const useProductSizeRulesGetter: UseProductSizeRulesGetterHookType = (id) => {

  const token = useToken();
  const product = useProduct(id);
  const { catId, fields } = useCategoryId();
  const dispatch = useDispatch();
  const selectFields = fields.filter(({ type, code }) => type === 'select' || code === 'MAG' || code === 'SZEL')
    .map(({ code }) => code);

  return useCallback(async (width) => {

    const { data } = await axios
      .post<Response>(`${process.env.REACT_APP_SERVER_URL}/categories/fields/${catId}`,
        {
          REQ: width ? 'SZEL' : 'MAG',
          DATA:
            [
              {
                code: 'PROD',
                value: product.product_id,
              },
              ...(product.features.filter(
                ({ code }) => selectFields.includes(code),
              ).map(({ code, option_value, keyed_value }) => ({
                code,
                value: keyed_value ?? option_value,
              }))),
            ],
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    const sizeRules = data.map(({max_h, max_w, min_h, min_w}) => ({
      width: {
        min: min_w,
        max: max_w
      },
      height: {
        min: min_h,
        max: max_h
      }
    }))

    dispatch(productActions.modifyProductSizeRules({
      id,
      type: 'normal',
      sizeRules,
    }));

    const singleSize = sizeRules.reduce<[number, number] | false | undefined>((
      single, { height, width },
    ) => {
      if (single !== undefined)
        return false;

      const same = height.max === height.min && width.min === width.max;

      return same ? [height.max, width.max] : false;
    }, undefined);

    if (Array.isArray(singleSize)) {
      const features = product.features.map((feature) => ({...feature}))

      const heightFeature = features.find(({ code }) => code === 'MAG');
      const widthFeature = features.find(({ code }) => code === 'SZEL');

      batch(() => {
        if (!heightFeature) {
          // dispatch(productActions.modifyProductFeatureById(
          //   {
          //     id: product.key_id,
          //     feature: {
          //       code: 'MAG',
          //       option_code: 'meret',
          //       keyed_value: singleSize[0].toString()
          //     },
          //     fields,
          //   },
          // ));
        }
        if (!widthFeature) {
          // dispatch(productActions.modifyProductFeatureById(
          //   {
          //     id: product.key_id,
          //     feature: {
          //       code: 'SZEL',
          //       option_code: 'meret',
          //       keyed_value: singleSize[1].toString()
          //     },
          //     fields,
          //   },
          // ));
        }
      })
    }

  }, [id, catId, token, product, selectFields]);
};
