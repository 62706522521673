import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { orderActions, OrderItem } from '../data-management/orderSlice';
import axios from 'axios';
import { useToken } from './useToken';
import { Product, productActions } from '../data-management/productsSlice';
import { flashActions } from '../data-management/flashSlice';
import { useProducts } from './useProducts';

type UseEditOrderItemHookType = () => (id?: number) => Promise<Product[]>

export const useEditOrderItem: UseEditOrderItemHookType = () => {

  const orderItems = useSelector<RootState, OrderItem[]>(
    state => state.order.orders,
  );
  const token = useToken();
  const dispatch = useDispatch();
  const products = useProducts();
  
  return useCallback(async (id) => {
    
    const itemsToMove = orderItems.filter(({ id: currentId }) =>
      id === undefined ? true : id === currentId,
    );
    
    let tempProducts: Product[] = [...products]
    let axiosProducts: any = {};
    
    await Promise.all(itemsToMove.map(async ({ id, note, nettdata: {SzinErtek} }) => {
      const { data: res } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/order/item/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newProduct: Product = {
        product_id: res.product.id,
        quantity: res.quantity,
        active: true,
        note: note,
        features: [
          {
            code: 'MAG',
            option_code: 'meret',
            keyed_value: res.height,
          },
          {
            code: 'SZEL',
            option_code: 'meret',
            keyed_value: res.width,
          },
          ...res.extras.map(({ FeatureCode, OptionCode, OptionId }: {
            FeatureCode: string
            OptionCode: string
            OptionId: number
          }) => ({
            code: FeatureCode,
            option_code: OptionCode,
            option_value: OptionId,
          })),
        ],
      };

      if (SzinErtek !== undefined) {
        const szinFeature = newProduct.features.find(({code}) => code === 'SZIN')
        if (szinFeature) {
          szinFeature.keyed_value = SzinErtek
        }
      }
      axiosProducts[id] = newProduct;
    }));

    //Async axios szinkron sorrendbe kell API-ra küldeni
    itemsToMove.map(async ({ id, note, nettdata: {SzinErtek} }) => {
      let newProduct = axiosProducts[id];
      dispatch(productActions.addProduct(newProduct));
      dispatch(orderActions.deleteOrderItem(id));
      tempProducts.push(newProduct);
    });

    dispatch(flashActions.addFlashMessage('Termék sikeresen a szerkesztő felületre helyezve!'));
    dispatch(productActions.removeFirstEmpty())

    return tempProducts;
  }, [orderItems, token, products]);
};
