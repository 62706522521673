import { useProduct } from './useProduct';
import { productSizeRuleHelper } from './useProductSizeRuleValid';
import { useMemo } from 'react';
import { FeatureType, ProductSizeRulesHelper } from '../data-management/productsSlice';

type UseProductSizeHelperHookType = (productId: number) => {
  sizeHelperHeight: string
  sizeHelperWidth: string
  sizeHelperWidthArray: [number, number][]
  sizeHelperHeightArray: [number, number][]
}

const valueExtractor: (feature?: FeatureType) => number | undefined = (
  feature,
) => {
  if (!feature)
    return undefined;

  if (feature.keyed_value === undefined || feature.keyed_value === '')
    return undefined;

  return Number(feature.keyed_value);
};

const sizeRuleCombiner: (sizeRules: ProductSizeRulesHelper[]) => [number, number][] = (
  sizeRules,
) => sizeRules.reduce<[number, number][]>((array, { max, min }) => {

  if (array.some(([minHeight, maxHeight]) =>
    minHeight <= min && max <= maxHeight,
  ))
    return array;

  const extendableIndex = array.findIndex(([minHeight, maxHeight]) =>
    min <= minHeight && max >= maxHeight,
  );

  if (extendableIndex !== -1) {
    array[extendableIndex] = [min, max];
    return array;
  }

  const lowIncludeIndex = array.findIndex(([minHeight, maxHeight]) =>
    minHeight <= max + 1 && maxHeight > max +1 && minHeight >= min,
  );

  if (lowIncludeIndex !== -1) {
    array[lowIncludeIndex] = [min, array[lowIncludeIndex][1]];
    return array;
  }

  const highIncludeIndex = array.findIndex(([minHeight, maxHeight]) =>
    minHeight <= min && maxHeight >= min - 1 && maxHeight <= max,
  );

  if (highIncludeIndex !== -1) {
    array[highIncludeIndex] = [array[highIncludeIndex][0], max];
    return array;
  }

  array.push([min, max]);

  return array;
}, []);

type PivotType = 'MAG' | 'SZEL'

export const useProductSizeHelper: UseProductSizeHelperHookType = (
  productId,
) => {

  //const [pivot, setPivot] = useState<PivotType>('MAG');

  const product = useProduct(productId);

  const {
    currentSizeRules,
    sizeRules: allSizeRules
  } = useMemo(() => {
    const sizeRules = productSizeRuleHelper(
      product.validationState,
      product.sizeRules,
      product.sizeRulesFromValidation,
    ) ?? [];

    const heightFeature = product.features.find(({ code }) => code === 'MAG');
    const widthFeature = product.features.find(({ code }) => code === 'SZEL');

    const height = valueExtractor(heightFeature);
    const width = valueExtractor(widthFeature);
    const heightIsNumber = typeof height === 'number';
    const widthIsNumber = typeof width === 'number';

    const pivot: PivotType = widthIsNumber && !heightIsNumber ? 'SZEL' : 'MAG';

    const pivotValue: number | undefined = pivot === 'MAG' ? height : width;

    const currentSizeRules = pivotValue === undefined ? sizeRules :
      sizeRules.filter(({ width, height }) => {
        if (pivot === 'SZEL') {
          return width.min <= pivotValue && pivotValue <= width.max;
        } else {
          return height.min <= pivotValue && pivotValue <= height.max;
        }
      });

    return ({
      currentSizeRules,
      sizeRules
    });
  }, [product]);


  const {
    widthText,
    heightText,
    heightArray,
    widthArray
  } =
    useMemo(() => {
      const heightSizeRules = currentSizeRules.map(({ height }) => height);
      const widthSizeRules = currentSizeRules.map(({ width }) => width);

      const heightArray = sizeRuleCombiner(heightSizeRules);
      const widthArray = sizeRuleCombiner(widthSizeRules);

      const heightText = heightArray.length > 0 ?
        `Jelenleg érvényes méretszabály${heightArray.length > 1 ? 'ok' : ''}: ${
          heightArray.map(([min, max]) => min === max ? `${min}` : `${min} - ${max}`).join(' vagy ')
        }` : allSizeRules.length > 0 ? 'Hibás méret' :'Jelenleg nincs méretszabály';

      const widthText = widthArray.length > 0 ?
        `Jelenleg érvényes méretszabály${widthArray.length > 1 ? 'ok' : ''}: ${
          widthArray.map(([min, max]) => min === max ? `${min}` : `${min} - ${max}`).join(' vagy ')
        }` : allSizeRules.length > 0 ? 'Hibás méret' :'Jelenleg nincs méretszabály';

      return {
        heightArray,
        widthArray,
        heightText,
        widthText
      };
    }, [currentSizeRules, allSizeRules]);

  return ({
    sizeHelperHeight: heightText,
    sizeHelperWidth: widthText,
    sizeHelperWidthArray: widthArray,
    sizeHelperHeightArray: heightArray,
  });
};
