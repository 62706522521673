import React, { FC, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@material-ui/data-grid';
import { Box, makeStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Role, useAdminRoleList } from '../hooks/useAdminRoleList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 600,
    width: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
  },
}));

type RoleListPageProps = {}


export const RoleListPage: FC<RoleListPageProps> = () => {

  const adminRoleListApi = useAdminRoleList();
  const classes = useStyles();

  const [roles, setRoles] = useState<undefined | Role[]>(undefined);

  useEffect(() => {
    adminRoleListApi().then((data) => {
      setRoles(data);
    });
  }, []);

  const rows: GridRowsProp = useMemo(() => {
    if (!roles)
      return [];

    return roles.map((role) => ({
      ...role,
      created_at: format(parseISO(role.created_at), 'yyyy.MM.dd HH:mm'),
      updated_at: format(parseISO(role.updated_at), 'yyyy.MM.dd HH:mm'),
    }));
  }, [roles]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Név',
      flex: 1,
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Létrehozva',
      flex: 0,
      width: 180,
      editable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Frissítve',
      flex: 0,
      width: 180,
      editable: false,
    },
  ];

  return (
    <Box className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
      />
    </Box>
  );
};