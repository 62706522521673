import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAdminUserList, User } from '../hooks/useAdminUserList';
import { DataGrid, GridColDef, GridRowsProp } from '@material-ui/data-grid';
import { Box, Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import { Edit, Visibility, Person } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Role } from '../hooks/useAdminRoleList';
import { EditUserRoleModal } from './EditUserRoleModal';
import { UserSyncModal } from './UserSyncModal';
import { useIsAdmin } from '../hooks/useIsAdmin';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 600,
    width: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    '& .MuiDataGrid-row.Mui-even': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
    },
  },
}));

type UserListPageProps = {}


export const UserListPage: FC<UserListPageProps> = () => {

  const adminUserListApi = useAdminUserList();
  const classes = useStyles();
  const isAdmin = useIsAdmin();

  const [users, setUsers] = useState<undefined | User[]>(undefined);
  const [roleEditModal, setRoleEditModal] = useState<{
    open: boolean,
    id?: number
    userName?: string
  }>({
    open: false,
  });
  const [syncModal, setSyncModal] = useState(false)

  useEffect(() => {
    adminUserListApi().then((data) => {
      setUsers(data);
    });
  }, []);

  const openRoleEditModal = (id: number, userName: string) => {
    setRoleEditModal({
      open: true,
      id,
      userName,
    });
  };

  const onCloseRoleEditModal = () => {
    setRoleEditModal({
      open: false,
    });
  };

  const openSyncModal = () => {
    setSyncModal(true)
  }

  const closeSyncModal = () => {
    setSyncModal(false)
  }

  const onSaveRoleEditModal = (roles: Role[]) => {
    const user = users?.find(({ id }) => id === roleEditModal.id);
    if (user)
      user.roles = roles;
    if (users)
      setUsers([...users]);
    setRoleEditModal({
      open: false,
    });
  };

  const rows: GridRowsProp = useMemo(() => {
    if (!users)
      return [];

    return users.map((user) => ({
      ...user,
      roles: user.roles.map(({ name }) => name).join(', '),
      created_at: format(parseISO(user.created_at), 'yyyy.MM.dd HH:mm'),
      last_login: (typeof user.last_login === 'string') ? format(parseISO(user.last_login), 'yyyy.MM.dd HH:mm') : '-',
      updated_at: format(parseISO(user.updated_at), 'yyyy.MM.dd HH:mm'),
    }));
  }, [users]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Azonosító',
      flex: 0.5,
      editable: false,
    },
    {
      field: 'organization_name',
      headerName: 'Cégnév',
      flex: 0.75,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 0.75,
      editable: false,
    },
    {
      field: 'last_login',
      headerName: 'Legutolsó belépés dátums',
      flex: 0,
      width: 180,
      type: 'date',
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Létrehozás dátuma',
      flex: 0,
      width: 180,
      type: 'date',
      editable: false,
    },
    {
      field: 'roles',
      headerName: 'Szerepkörök',
      flex: 0.75,
      editable: false,
      renderCell: (params) => (
        isAdmin ? <>
          {params.value}
          <IconButton onClick={() => openRoleEditModal(
            Number(params.getValue(params.id, 'id')),
            params.getValue(params.id, 'name')?.toString() ?? ''
          )}>
            <Edit />
          </IconButton>
        </> : <>{params.value}</>
      ),
    },
    {
      field: 'active',
      headerName: 'Státusz',
      flex: 0.5,
      editable: false,
      renderCell: (params) => (
        <>
          {
            (params.value === 1) ?
            <>
              <Person style={{fill: "#B2A77D"}}></Person>
              <Typography>
                Aktív
              </Typography>
            </>
            :
            <>
            <Person style={{fill: "#B81E22"}}></Person>
            <Typography>
                Inaktív
              </Typography>
            </>
          }
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Műveletek',
      flex: 0,
      width: 180,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Link to={`user/${params.getValue(params.id, 'id')}`}>
          <IconButton>
            <Visibility />
          </IconButton>
        </Link>
      ),
    },
  ];

  return (
    <Box className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
      />
      <Box mt={4}/>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={3}>
          <Button
            variant={'contained'}
            onClick={openSyncModal}
            fullWidth
            color={'secondary'}
          >
            Felhasználók szinkronizálása
          </Button>
        </Grid>
      </Grid>
      <EditUserRoleModal
        open={roleEditModal.open}
        onClose={onCloseRoleEditModal}
        onSave={onSaveRoleEditModal}
        id={roleEditModal.id}
        userName={roleEditModal.userName}
      />
      <UserSyncModal open={syncModal} onClose={closeSyncModal} />
    </Box>
  );
};