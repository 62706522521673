import axios from 'axios';
import { useDispatch } from 'react-redux';
import { orderActions } from '../data-management/orderSlice';
import { useCallback } from 'react';

type UseOrderLoaderHookType = (token: string) => () => Promise<void>

export const useOrderLoader: UseOrderLoaderHookType = (token) => {

  const dispatch = useDispatch();

  return useCallback(() => {

    dispatch(orderActions.loadOrderItems())

    return axios.get(`${process.env.REACT_APP_SERVER_URL}/order/item`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({data}) => {
        dispatch(orderActions.successLoadOrder(data))
      })
      .catch((err) => {
        dispatch(orderActions.failedLoadOrder(err.message));
      });
  }, [token]);
};