import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { batch, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  ModalProps,
  Select,
  Typography,
} from '@material-ui/core';
import { productActions } from '../../data-management/productsSlice';
import { UniqueEdgeIcon } from '../UniqueEdgeIcon';
import { ExpandMore } from '@material-ui/icons';
import { useProduct } from '../../hooks/useProduct';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { Edge, useEdgeGetter } from '../../hooks/useEdgeGetter';
import { useProductValidator } from '../../hooks/useProductValidator';
import axios from 'axios';
import { ProductValidatorAlert } from './ProductValidatorAlert';

const useStyles = makeStyles((theme) => ({
  list: {
    position: 'relative',
    width: 400,
    padding: theme.spacing(3),
    height: '100%',
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  errorText: {
    color: '#B81E22',
  },
}));

const elmegmunks = [
  'ELMEGM1',
  'ELMEGM2',
  'ELMEGM4',
  'ELMEGM3',
];

const accordion = [
  'ELMEGMUNK',
  'BORITAS',
  'SZIN2',
  'FENY2'
];

// type Extra = {
//   FeatureCode: string
//   OptionCode: string
//   FeatureName?: string
// }

type ExtrasPanelProps = {
  open: boolean
  onClose: () => void
  productId: number
}

export const ExtrasPanel: FC<ExtrasPanelProps> = (
  {
    open,
    onClose,
    productId,
  },
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const product = useProduct(productId);
  const optionRaw = product.extrasOptions;
  const options = optionRaw ?? [];
  const error = product.error ?? {
    text: '',
    field: '',
  };
  const [edges, setEdges] = useState<Edge[]>([]);
  const [uniqueEdges, setUniqueEdges] = useState(false);
  const validateProduct = useProductValidator(productId);
  const edgeGetter = useEdgeGetter();

  const toggleDrawer: ModalProps['onClose'] = (event, reason) => {
    onClose();
  };

  const productEdges = useMemo(() => {

    const findFeature = (featureCode: string) => product?.features.find(({ code }) => code === featureCode);

    return elmegmunks.map((code) => findFeature(code));
  }, [product]);

  useEffect(() => {
    if (uniqueEdges && edges.length > 0) {

    } else {

      if (
        product?.features.find(({ code }) => code === 'ELMEGMUNK')?.option_code !== 'ELT' &&
        product?.features.some(({ code }) => elmegmunks.includes(code))
      ) {
        elmegmunks.forEach((code) => {
          dispatch(productActions.removeProductFeatureById({
            id: productId,
            featureCode: code,
          }));
        });
      }
    }
  }, [uniqueEdges, edges]);

  useEffect(() => {

    const elmegmunkFeature = product.features.find(({ code }) => code === 'ELMEGMUNK');

    if (elmegmunkFeature && elmegmunkFeature.option_code === 'ELT') {
      if (!uniqueEdges) {
        edgeGetter(Number(product.product_id))
          .then((res) => {
            const filteredEdges = res.filter((edge) => edge.code !== 'ELT');
            filteredEdges.unshift({
              code: '-',
              description: '-',
              isDefault: false
            });
            setEdges(filteredEdges);
            batch(() => {
              elmegmunks.forEach(name => {
                const currentFeature = product.features.find(({ code }) => code === name);

                if (!currentFeature)
                  dispatch(productActions.modifyProductFeatureById({
                    id: productId,
                    feature: {
                      code: name,
                      option_code: filteredEdges.find(({isDefault}) => isDefault)?.code ?? '-',
                    },
                  }));
              });
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
        setUniqueEdges(true);
      }
    } else {
      setUniqueEdges(false);
    }
  }, [product]);

  const handleExtrasChange: SelectInputProps['onChange'] = (e) => {
    dispatch(productActions.modifyProductFeatureById({
      id: productId,
      feature: {
        code: e.target.name ?? '',
        option_code: e.target.value as string,
      },
    }));
  };

  const handleEdgeChange: SelectInputProps['onChange'] = (e) => {
    dispatch(productActions.modifyProductFeatureById({
      id: productId,
      feature: {
        code: e.target.name ?? '',
        option_code: e.target.value as string,
      },
    }));
  };

  useEffect(() => {
    if (productId !== null && open && optionRaw === undefined) {
      validateProduct(axios.CancelToken.source());
    }
  }, [productId, optionRaw]);

  const saveExtras = () => {
    onClose();
  };

  const deleteExtras = () => {
    dispatch(productActions.removeExtrasFromProductById(productId));
  };

  const list = (
    <>
    <div className={clsx(classes.list)} role="presentation">
      <Typography variant="h5" style={{ marginBottom: '15px' }}>
        Termék extrák
      </Typography>
      <ProductValidatorAlert
        productId={productId}
      />
      <Box mb={4}/>
      {
        <>
          {
            options
              .filter(({ FeatureCode }) => !accordion.includes(FeatureCode) && !elmegmunks.includes(FeatureCode))
              .map((option, index) => (
                <FormControl key={index} fullWidth className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">{option.FeatureCode}</InputLabel>
                  <Select
                    error={error.field === option.FeatureCode}
                    fullWidth
                    labelId={`extras-${index}`}
                    id={`extas-${index}`}
                    name={option.FeatureCode}
                    value={
                      product?.features?.find(({ code }) => code === option.FeatureCode)?.option_code ??
                      option.OptionCode
                    }
                    onChange={handleExtrasChange}
                  >
                    {option.OtherOptions.map((item, index) => (
                      <MenuItem key={index} value={item.code}>{item.description}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className={classes.errorText}>
                    {error.field === option.FeatureCode && error.text}
                  </FormHelperText>
                </FormControl>
              ))
          }
          {
            (() => {
              const filteredOptions = options.filter(({ FeatureCode }) => accordion.includes(FeatureCode));

              if (filteredOptions)
                return (
                  <Box mb={2}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                      >
                        <Typography>Feláras extrák</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction={'column'}>
                          {
                            uniqueEdges ? (
                              <Box mb={3}>
                                <Grid container spacing={2}>
                                  <Grid item xs={10} container alignItems={'center'}>
                                    <Typography variant={'subtitle1'}>
                                      Élmarás
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <UniqueEdgeIcon
                                      style={{
                                        width: '65%',
                                        height: 'auto',
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={3} />
                                  <Grid item xs={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>Felső</InputLabel>
                                      <Select
                                        error={elmegmunks[0] === error.field}
                                        fullWidth
                                        name={elmegmunks[0]}
                                        value={productEdges?.[0]?.option_code ?? ''}
                                        onChange={handleEdgeChange}
                                      >
                                        {edges?.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.code}
                                          >
                                            {item.description}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText className={classes.errorText}>
                                        {error.field === elmegmunks[0] && error.text}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={3} />
                                  <Grid item xs={5}>
                                    <FormControl fullWidth>
                                      <InputLabel>Bal</InputLabel>
                                      <Select
                                        error={elmegmunks[1] === error.field}
                                        fullWidth
                                        name={elmegmunks[1]}
                                        value={productEdges?.[1]?.option_code ?? ''}
                                        onChange={handleEdgeChange}
                                      >
                                        {edges?.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.code}
                                          >
                                            {item.description}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText className={classes.errorText}>
                                        {error.field === elmegmunks[1] && error.text}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2} />
                                  <Grid item xs={5}>
                                    <FormControl fullWidth>
                                      <InputLabel>Jobb</InputLabel>
                                      <Select
                                        error={elmegmunks[2] === error.field}
                                        fullWidth
                                        name={elmegmunks[2]}
                                        value={productEdges?.[2]?.option_code ?? ''}
                                        onChange={handleEdgeChange}
                                      >
                                        {edges?.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.code}
                                          >
                                            {item.description}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText className={classes.errorText}>
                                        {error.field === elmegmunks[2] && error.text}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={3} />
                                  <Grid item xs={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>Alsó</InputLabel>
                                      <Select
                                        error={elmegmunks[3] === error.field}
                                        fullWidth
                                        name={elmegmunks[3]}
                                        value={productEdges?.[3]?.option_code ?? ''}
                                        onChange={handleExtrasChange}
                                      >
                                        {edges?.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.code}
                                          >
                                            {item.description}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText className={classes.errorText}>
                                        {error.field === elmegmunks[3] && error.text}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={3} />
                                </Grid>
                              </Box>
                            ) : <></>
                          }
                          {
                            filteredOptions
                              .sort((a, b) => accordion.indexOf(a.FeatureCode) - accordion.indexOf(b.FeatureCode))
                              .map((option, index) => (
                                <FormControl key={index} fullWidth className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-label">{option.FeatureName}</InputLabel>
                                  <Select
                                    error={error.field === option.FeatureCode}
                                    fullWidth
                                    labelId={`extras-${index}`}
                                    id={`extas-${index}`}
                                    name={option.FeatureCode}
                                    onChange={handleExtrasChange}
                                    value={
                                      product?.features?.find(({ code }) => code === option.FeatureCode)?.option_code ??
                                      option.OptionCode
                                    }
                                  >
                                    {option.OtherOptions.map((item, index) => (
                                      <MenuItem key={index} value={item.code}>{item.description}</MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText className={classes.errorText}>
                                    {error.field === option.FeatureCode && error.text}
                                  </FormHelperText>
                                </FormControl>
                              ))
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              else return <></>;
            })()
          }
        </>
      }
      <FormControl className={clsx(classes.formControl, classes.btnGroup)}>
        <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={saveExtras}>
          Ok
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ marginRight: '10px' }}
          onClick={deleteExtras}
        >
          Extrák törlése
        </Button>
      </FormControl>
    </div>
    </>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor="right" open={open} onClose={toggleDrawer}>
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

