import React, { FC, useState } from 'react';
import { Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { useSaveOrderDeleter } from '../../hooks/useSavedOrderDeleter';
import { useToken } from '../../hooks/useToken';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type ConfirmSavedOrderDeleteModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  id: number
  name: string
  createdAt: string
}

export const ConfirmSavedOrderDeleteModal: FC<ConfirmSavedOrderDeleteModalProps> = React.memo((
  {
    open,
    onClose,
    onSuccess,
    id,
    name,
    createdAt
  },
) => {

  const classes = useStyles()
  const token = useToken();
  const deleteSavedOrder = useSaveOrderDeleter(token)

  const [error, setError] = useState<string | undefined>(undefined)

  const onModalClose = () => {
    onClose();
  }

  const onConfirm = () => {
    deleteSavedOrder(id)
      .then(() => {
        onClose();
        onSuccess();
      })
      .catch(() => {
        setError('hiba')
      })
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Mentett rendelés törlése
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          error ?
            <Alert severity={'error'}>
              {error}
            </Alert> : <></>
        }
        <Typography>
          Biztosan törli az alábbi mentett rendelését?
        </Typography>
        <Typography>
          {name}, létrehozva: {createdAt}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onConfirm}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Törlés
        </Button>
      </DialogActions>
    </Dialog>
  );
});
