import React, { FC, useState } from 'react';
import { Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUniqueRequest } from '../../hooks/useUniqueRequest';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../data-management/store';
import { uniqueProductsActions, UniqueProductsState } from '../../data-management/uniqueProductsSlice';
import { useSelectedOrganisationId } from '../../hooks/useSelectedOrganisationId';
import { flashActions } from '../../data-management/flashSlice';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type UniqueProductConfirmModalProps = {
  open: boolean
  onClose: () => void
}

export const UniqueProductConfirmModal: FC<UniqueProductConfirmModalProps> = (
  {
    open,
    onClose
  }
) => {
  const classes = useStyles();
  const uniqueRequest = useUniqueRequest()
  const [progressing, setProgressing] = useState(false)
  const uniqueProduct = useSelector<RootState, UniqueProductsState>(state => state.uniqueProducts)
  const orgId = useSelectedOrganisationId()
  const dispatch = useDispatch()

  const onSubmit = () => {
    setProgressing(true)
    uniqueRequest(
      {
        note: uniqueProduct.description,
        org_id: orgId,
        purchase_number: uniqueProduct.purchasingNumber,
        items: uniqueProduct.uniqueProducts.map((
          {
            description,
            files,
          }
        ) => ({
          note: description,
          files: files.map(({id}) => id)
        }))
      }
    ).then(() => {
      setProgressing(false)
      dispatch(uniqueProductsActions.reset())
      dispatch(flashActions.addFlashMessage('Egyedi ajánlat kérés rögzítve!'))
      onClose()
    }).catch(() => {
      setProgressing(false)
    })
  }

  const onModalClose = () => {
    if (progressing)
      return;

    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
      onClose={progressing ? undefined : onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Egyedi ajánlat kérés megerősítése
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Biztosan véglegesíti az egyedi ajánlat kérését?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSubmit}
          color="secondary"
          variant={'contained'}
          size={'small'}
          disabled={progressing}
        >
          Egyedi ajánlat kérés
        </Button>
      </DialogActions>
    </Dialog>
  );
};
