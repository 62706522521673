import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

export type UploadedFile = {
  filename: string
  title: string
  extension: string
  size: number
  mime: string
  updated_at: string
  created_at: string
  id: number
}

type Response = {
  message: string
  files: UploadedFile[]
}

type UseUniqueFileUploaderHookType = () =>
  (files: File[]) => Promise<UploadedFile[]>


export const useUniqueFileUploader: UseUniqueFileUploaderHookType = () => {

  const token = useToken();

  return useCallback(async (files) => {
      const formData = new FormData();
      files.forEach((file) => formData.append('files[]', file));

      const {data} = await axios.post<Response>(
        `${process.env.REACT_APP_SERVER_URL}/order/custom/file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      return data.files
    },
    [token]);
};
