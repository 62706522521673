import React, { FormEventHandler, FC, useState, useEffect } from 'react';
import { Box, Dialog, DialogContent, makeStyles, TextField, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { useToken } from '../hooks/useToken';
import { flashActions } from '../data-management/flashSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type NewSendModalProps = {
  open: boolean
  onClose: () => void
}

export const NewSendModal: FC<NewSendModalProps> = React.memo((
  {
    open,
    onClose,
  },
) => {

  const classes = useStyles()
  const token = useToken();
  const dispatch = useDispatch();

  const [error, setError] = useState<string | undefined>(undefined)
  const [subject, setSubject] = useState<string>("")
  const [selectedPattern, setSelectedPattern] = useState<any>(undefined)
  const [selectedGroup, setSelectedGroup] = useState<any>(undefined)
  const [allPatterns, setAllPatterns] = useState<any>(undefined)
  const [allGroups, setAllGroups] = useState<any>(undefined)

  const onModalClose = () => {
    onClose();
  }

  useEffect(() => {
    axios.get(
        `${process.env.REACT_APP_SERVER_URL}/newsletter/get-sent-newsletter-fields`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    ).then((res) => {
        setAllGroups(res.data.groups);
        setAllPatterns(res.data.patterns);
    })
  }, []);

  const handleSubmit: FormEventHandler<Element> = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_SERVER_URL}/newsletter/send-newsletter-mail`;
    axios.post(
      url, { subject, newsletter_pattern_id: selectedPattern ? selectedPattern.id : null, newsletter_group_id : selectedGroup ? selectedGroup.id : null },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => {
      dispatch(flashActions.addFlashMessage('Küldés sikeres!'));
      onClose();
    })
    .catch((err) => {
      dispatch(flashActions.addFlashMessage('Küldés sikertelen!'));
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Hírlevél küldése
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Az alábbi mezők segítségével állítsa be a küldési folyamat paramétereit
        </Typography>
        <form onSubmit={handleSubmit}>
            <Box marginTop={4}>
                <Typography style={{marginBottom: "12px"}} variant="body1">
                Levél tárgya
                </Typography>
                <TextField
                fullWidth
                name="note"
                value={subject}
                onChange={ (e) => setSubject(e.target.value) }
                />
            </Box>
            <Box marginTop={3}>
                <Typography style={{marginBottom: "12px"}} variant="body1">
                Sablon választása
                </Typography>
                <Autocomplete
                    freeSolo
                    getOptionLabel={(option: any) => option.label}
                    limitTags={3}
                    fullWidth
                    onChange={(e, value: any) => setSelectedPattern(value)}
                    value={selectedPattern}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="newsletter_pattern_id"
                        variant="outlined"
                      />
                    )}
                    options={allPatterns}
                  />
            </Box>
            <Box marginTop={3}>
                <Typography style={{marginBottom: "12px"}} variant="body1">
                    Csoport választása
                </Typography>
                <Autocomplete
                    freeSolo
                    getOptionLabel={(option: any) => option.label}
                    limitTags={3}
                    fullWidth
                    onChange={(e, value: any) => setSelectedGroup(value)}
                    value={selectedGroup}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="newsletter_group_id"
                        variant="outlined"
                      />
                    )}
                    options={allGroups}
                  />
            </Box>
            <Box style={{marginTop: "10px", textAlign: "right"}}>
                <Button type="submit" variant="contained" color="secondary">
                Mentés
                </Button>
            </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
});
