import axios from 'axios';
import { useDispatch } from 'react-redux';
import { orderActions } from '../data-management/orderSlice';
import { useCallback } from 'react';

type UseOrderItemDeleterHookType = (
  token: string,
) => (
  itemId: number,
  itemIndex: number
) => Promise<void>

export const useOrderItemDeleter: UseOrderItemDeleterHookType = (
  token,
) => {

  const dispatch = useDispatch();

  return useCallback((
    itemId,
    itemIndex
  ) => {

    dispatch(orderActions.loadOrderItems())

    return axios.delete(`${process.env.REACT_APP_SERVER_URL}/order/item/${itemId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({data}) => {
        dispatch(orderActions.deleteOrderItemAtIndex(itemIndex))
      })
      .catch((err) => {
        dispatch(orderActions.failedLoadOrder(err.message));
      });
  }, [token]);
};