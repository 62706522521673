import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

export type ContactType = {
  message: string
  subject: string
}

type UseContactHookType = () => (input: ContactType) => Promise<void>

export const useContact: UseContactHookType = () => {

  const token = useToken()

  return useCallback(async (input) => {
    const {data} = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/contact`,
      input,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}
