import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fab, FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles, Switch,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Add, Close, Delete } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, parse } from 'date-fns';
import { SettingsType, SettingType } from '../hooks/useSettingsGet';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  list: {
    maxHeight: 400,
    height: 400,
    overflow: 'auto',
  },
  dialogContentRoot: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2),
    marginTop: theme.spacing(-2)
  },
  formControlLabel: {
    marginLeft: 0,
  },
  buttonNoMargin: {
    marginBottom: 0
  }
}));

type HolidayArrayPickerWrapperProps = {
  name: string
  setSetting: (inner: (settings: SettingsType) => SettingsType) => void
  setting: SettingType
}

export type HolidayType = {
  date: string
  recurring: boolean
  id: number
}

export const HolidayArrayPickerWrapper: FC<HolidayArrayPickerWrapperProps> = (
  {
    name,
    setSetting,
    setting
  },
) => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState<HolidayType[]>(setting.value as HolidayType[]);
  const [highestId, setHighestId] = useState(1);

  useEffect(() => {
    setData(setting.value as HolidayType[]);
    setHighestId(
      (setting.value as HolidayType[]).reduce<number>((acc, { id }) =>
          acc > id ? acc : id
        , 1),
    );
  }, [setting.value]);

  const open = () => {
    setOpenDialog(true);
  };

  const save = () => {
    setSetting(originalData => ({
      ...originalData,
      [name]: {
        ...setting,
        value: data
      },
    }));
    close();
  };

  const close = () => {
    setOpenDialog(false);
    setData(setting.value as HolidayType[]);
  };

  const add = () => {
    setData(data =>
      [...data, {
        date: format(new Date(), 'yyyy-MM-dd'),
        recurring: false,
        id: highestId + 1,
      }],
    );
    setHighestId(highestId + 1);
  };

  const remove = (index: number) => () => {
    setData(data => {
        const temp = [...data];
        temp.splice(index, 1);
        return temp;
      },
    );
  };


  const onChange: (index: number) => (date: MaterialUiPickersDate) => void = (index) => (date) => {
    setData(data => {
        const temp = [...data];
        temp[index].date = format(date ?? new Date(), 'yyyy-MM-dd')
        return temp;
      },
    );
  }

  const onRecurringChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(data => {
        const temp = [...data];
        temp[index].recurring = event.target.checked
        return temp;
      },
    );
  };

  return (
    <>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={open}
      >
        Munkaszüneti napok kezelése
      </Button>
      <Dialog
        open={openDialog}
        onClose={close}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          square: true,
        }}
      >
        <DialogTitle disableTypography>
          <Typography variant={'h5'}>
            Munkaszüneti napok kezelése
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContentRoot
          }}
        >
          <Box className={classes.list}>
            <Box m={2}>
              <Typography>A napok módosítása esetén kattintson a felugró ablakon kívüli mentés gombra!</Typography>
            </Box>
            <List>
              {
                data.map(({ recurring, date, id }, index) => (
                  <ListItem
                    key={id}
                  >
                    <ListItemText
                      primary={
                        <KeyboardDatePicker
                          clearable
                          value={parse(
                            date,
                            'yyyy-MM-dd',
                            0
                          )}
                          onChange={onChange(index)}
                          fullWidth
                          format="yyyy-MM-dd"
                        />
                      }
                      secondary={
                        <FormControlLabel
                          control={
                            <Switch
                              checked={recurring}
                              onChange={onRecurringChange(index)}
                              size={'small'}
                            />}
                          label="Évente ismétlődő"
                          labelPlacement={'start'}
                          className={classes.formControlLabel}
                          color={'primary'}
                        />
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={remove(index)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
            <Grid container justify={'center'}>
              <Fab
                variant="extended"
                color={'primary'}
                onClick={add}
              >
                <Add className={classes.extendedIcon} />
                Hozzáadás
              </Fab>
            </Grid>
            <Box mb={4}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={save}
            color="secondary"
            variant={'contained'}
            size={'small'}
            className={classes.buttonNoMargin}
          >
            Bezárás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
