import React, { useState } from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useToken } from '../hooks/useToken';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  textField: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gap: {
    marginRight: theme.spacing(2),
  },
}));

const SettingItem = () => {
  const [data, setData] = useState({
    value: '',
    key: '',
  });

  const [save, setSave] = useState(false);
  const token = useToken();
  const classes = useStyles();
  let match = useRouteMatch();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let sendData = [{ 'key': data.key, 'value': data.value }];

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/settings`, sendData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSave(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {save && <Redirect strict to="/settings" />}
      <Paper className={classes.root}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <TextField
                id="key"
                name="key"
                label="Kulcs"
                type="text"
                fullWidth
                onChange={(e) => handleChange(e)}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="value"
                name="value"
                label="Érték"
                type="text"
                fullWidth
                onChange={(e) => handleChange(e)}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={12}>
              <Button type="submit" variant="contained" color="primary">
                Mentés
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SettingItem;
