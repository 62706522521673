import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OrderItem = any

export type OrderState = {
  loading: boolean
  orders: OrderItem[];
  ship_id: number | undefined,
  ship_mode: string,
  note: string,
  purchase_number: string,
  from_saved_order: boolean,
  error: string
}

export type OrderMeta = {
  ship_id: number | undefined,
  ship_mode: string,
  note: string,
  purchase_number: string,
  from_saved_order: boolean,
}

const initialState: OrderState = {
  loading: false,
  orders: [],
  ship_id: undefined,
  ship_mode: '',
  note: '',
  purchase_number: '',
  from_saved_order: false,
  error: '',
};

type OrderReducer<T = void> = CaseReducer<OrderState, PayloadAction<T>>

type Reducers = {
  setOrderItems: OrderReducer<OrderItem[]>
  loadOrderItems: OrderReducer
  successLoadOrder: OrderReducer<OrderItem[]>
  failedLoadOrder: OrderReducer<string>
  deleteOrderItemAtIndex: OrderReducer<number>
  deleteOrderItem: OrderReducer<number>
  resetOrderItems: OrderReducer
  setOrderMeta: OrderReducer<OrderMeta>
}

export const orderSlice = createSlice<OrderState, Reducers>(
  {
    name: 'order',
    initialState,
    reducers: {
      setOrderItems: (state, { payload }) => {
        state.orders = payload;
      },
      loadOrderItems: (state) => {
        state.loading = true;
      },
      successLoadOrder: (state, { payload }) => {
        state.orders = payload;
        state.error = '';
        state.loading = false;
      },
      failedLoadOrder: (state, { payload: error }) => {
        state.loading = false;
        state.error = error;
      },
      deleteOrderItemAtIndex: (state, { payload: index }) => {
        state.orders.splice(index, 1);
        state.loading = false;
      },
      deleteOrderItem: (state, { payload: id }) => {
        const index = state.orders.findIndex(({ id: currentId }) => id === currentId);
        if (index !== -1)
          state.orders.splice(index, 1);
      },
      resetOrderItems: (state) => {
        state.orders = []
        state.ship_id = undefined;
        state.ship_mode = '';
        state.note = '';
        state.purchase_number = '';
        state.from_saved_order = false;
      },
      setOrderMeta: (state, {payload: meta}) => {
        state.ship_id = meta.ship_id;
        state.ship_mode = meta.ship_mode;
        state.note = meta.note;
        state.purchase_number = meta.purchase_number;
        state.from_saved_order = meta.from_saved_order;
      }
    },
  },
);

export const orderActions = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
