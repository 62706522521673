import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { useOrganisations } from './useOrganisations';

type UseIsListTypeOrganizationHookType = () => boolean;

export const useIsListTypeOrganization: UseIsListTypeOrganizationHookType = () => {

  const selectedOrganisationId = useSelector<RootState, number | undefined>(
    state => state.auth.selectedOrganisation
  );

  const organisations = useOrganisations();
  let selectedOrganisation = organisations?.find(({id}) => id === selectedOrganisationId) ?? null;
  if(typeof selectedOrganisationId == "undefined" && organisations.length){
    selectedOrganisation = organisations[0];
  }

  if(selectedOrganisation){
    return selectedOrganisation.arkat == "2024-I" || selectedOrganisation.arkat == "2024-0" || selectedOrganisation.arkat == "LISTA";
  }
  return false;
}
