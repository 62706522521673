
import React, { FC } from 'react';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { makeStyles, Typography, Box, Grid } from '@material-ui/core';
import TitleWrapper from './images/title-wrapper.png';
import AppBarImage from './images/app-bar-image.png';

const useStyles = makeStyles((theme) => ({
    box: {
        background: '#FFFFFF',
        height: '100vh'
    },
    middleBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '200px',
        marginBottom: 'auto',
        width: '375px'
    },
    text: {
        fontSize: '24px',
        color: '#535353',
        fontWeight: 300
    },
    iconGrid: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    icon: {
        width: 80,
        height: 80,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '12px',
        fill: '#535353'
    },
    titleWrapper: {
        position: 'absolute',
        top: '50%',
        left: 40,
        transform: 'translateY(-50%)',
        transition: 'left .3s',
        height: 70,
        width: 70,
      },
      title: {
        position: 'absolute',
        left: 65,
        top: 40,
        fontWeight: 300,
        fontSize: '36px',
        color: '#535353',
        transition: 'left .3s',
      },
      root: {
        position: 'relative',
        backgroundImage: `url(${AppBarImage})`,
        backgroundRepeat: 'no-repeate',
        backgroundSize: 'cover',
        paddingLeft: theme.spacing(5),
        height: '116px',
      },
  }));

const LowResolutionPage: FC = () => {

    const classes = useStyles();

  return (
    <Box
        className={classes.box}
    >
      <div className={classes.root}>
        <img
            src={TitleWrapper}
            className={classes.titleWrapper}
            alt="Nettfront"
        />
        <div className={classes.title}>Rendelési felület</div>
      </div>
        <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.middleBox}
        >
            <Grid
                item xs={12}
                className={classes.iconGrid}
            >
                <DesktopWindowsIcon
                    className={classes.icon}
                >
                </DesktopWindowsIcon>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    className={classes.text}
                    align="center"
                >
                    Rendelési felületünk csak asztali eszközről használható. Megértésüket köszönjük!
                </Typography>
            </Grid>

        </Grid>
    </Box>
  );
};

export default LowResolutionPage;
