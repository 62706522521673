import axios from 'axios';
import { useToken } from './useToken';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authActions, Organisation } from '../data-management/authSlice';

type UseOrganisationLoaderHookType = () => () => Promise<void>

export const useOrganisationLoader: UseOrganisationLoaderHookType = () => {

  const token = useToken();
  const dispatch = useDispatch();

  return useCallback(() => {

    return axios.get<Organisation[]>(
      `${process.env.REACT_APP_SERVER_URL}/order/orgs`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then(({data}) => {
      dispatch(authActions.loadOrganisations(data))
    });
  }, [token]);
};