import React, { FC, useState } from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { UserSyncModal } from './UserSyncModal';
import { ColorSyncModal } from './ColorSyncModal';
import { CatalogueSyncModal } from './CatalogueSyncModal';
import { FullSyncModal } from './FullSyncModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 600,
    width: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
  },
}));

export const SyncPage: FC = () => {

  const classes = useStyles()

  const [userSync, setUserSync] = useState(false)
  const [catalogueSync, setCatalogueSync] = useState(false)
  const [colorSync, setColorSync] = useState(false)
  const [fullSync, setFullSync] = useState(false)

  const openUserSync = () => {
    setUserSync(true)
  }

  const closeUserSync = () => {
    setUserSync(false)
  }

  const openCatalogueSync = () => {
    setCatalogueSync(true)
  }

  const closeCatalogueSync = () => {
    setCatalogueSync(false)
  }

  const openColorSync = () => {
    setColorSync(true)
  }

  const closeColorSync = () => {
    setColorSync(false)
  }

  const openFullSync = () => {
    setFullSync(true)
  }

  const closeFullSync = () => {
    setFullSync(false)
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={10} justify={'center'}>
        <Grid item xs={12} md={4}>
          <Button
            variant={'contained'}
            onClick={openUserSync}
            fullWidth
            color={'secondary'}
          >
            Felhasználók szinkronizálása
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant={'contained'}
            onClick={openCatalogueSync}
            fullWidth
            color={'secondary'}
          >
            Katalógus szinkronizálása
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant={'contained'}
            onClick={openColorSync}
            fullWidth
            color={'secondary'}
          >
            Színek szinkronizálása
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant={'contained'}
            onClick={openFullSync}
            fullWidth
            color={'secondary'}
          >
            Minden szinkronizálása
          </Button>
        </Grid>
      </Grid>
      <UserSyncModal open={userSync} onClose={closeUserSync} />
      <ColorSyncModal open={colorSync} onClose={closeColorSync} />
      <CatalogueSyncModal open={catalogueSync} onClose={closeCatalogueSync} />
      <FullSyncModal open={fullSync} onClose={closeFullSync} />
    </Box>
  )
}
