import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { AuthState } from '../data-management/authSlice';
import { useMemo } from 'react';

type UseIsAdminHookType = () => boolean;

export const useIsAdmin: UseIsAdminHookType = () => {

  const user = useSelector<RootState, AuthState['user'] | undefined>(state => state.auth.user)

  return useMemo<boolean>(() => {
    if (!user)
      return false;

    if (user.roles.some(({name}) => name === 'Admin'))
      return true;

    return false;
  }, [user])
}