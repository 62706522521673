import React, { FormEventHandler, useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Paper, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import { Redirect, useRouteMatch } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import { useToken } from '../hooks/useToken';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { SizeGroupElem } from './SizeGroupList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  textField: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gap: {
    marginRight: theme.spacing(2),
  },
}));

export type SizeGroupElemExtended = SizeGroupElem & {
  color_group_id: any[]
}

type Data = {
  name: string,
  size_group_id: string,
  height_min: number,
  height_max: number,
  width_min: number,
  width_max: number,
  glass_height_min: number | null,
  glass_height_max: number | null,
  glass_width_min: number | null,
  glass_width_max: number | null,
  flippable: number ,
  color_groups?: any[]
}

export type SizeGroupType = {
  code: string
  created_at: string
  description: string
  effective: string
  enabled: number
  feature_id: number
  id: number
  image: string | null
  inactive: string
  status: string | null
  type: string | null
  updated_at: string
}

type ColorGroupType = {
  code: string
  created_at: string
  description: string
  effective: string
  enabled: number
  feature_id: number
  id: number
  image: string | null
  inactive: string
  status: string | null
  type: string | null
  updated_at: string
}

export const SizeGroupSingle = () => {
  const [data, setData] = useState<Data>({
    name: '',
    size_group_id: '',
    height_min: 0,
    height_max: 0,
    width_min: 0,
    width_max: 0,
    glass_height_min: null,
    glass_height_max: null,
    glass_width_min: null,
    glass_width_max: null,
    flippable: 0,
  });
  const [colors, setColors] = useState<number[]>([]);
  const [sizeGroups, setSizeGroups] = useState<SizeGroupType[]>([]);
  const [colorGroups, setColorGroups] = useState<ColorGroupType[]>([]);

  const [save, setSave] = useState(false);
  const token = useToken();
  const classes = useStyles();
  const match = useRouteMatch<{
    id: string | undefined
  }>();

  const handleChange: StandardInputProps['onChange'] = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeSelect: SelectInputProps['onChange'] = (e) => {
    setData({ ...data, [e.target.name as string ?? 'size_group_id']: e.target.value });
  };

  const handleChangeColors: SelectInputProps['onChange'] = (e) => {
    setColors(e.target.value as number[]);
  };

  const handleChangeFlippable: SwitchBaseProps['onChange'] = (e) => {
    setData({ ...data, [e.target.name]: Number(e.target.checked) });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/sizerules/sizegroups`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSizeGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    async function getColorGroups() {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/sizerules/colorgroups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setColorGroups(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getColorGroups();

    if (match.params.id !== undefined) {
      axios
        .get<SizeGroupElemExtended>(`${process.env.REACT_APP_SERVER_URL}/sizerules/${match.params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({data: rule}) => {

          const ids = rule.color_groups.map((color) => color.color_group_id);
          setColors(ids);

          setData({
            name: rule.name,
            size_group_id: rule.size_group_id.toString(),
            color_groups: rule.color_group_id,
            height_min: rule.height_min,
            height_max: rule.height_max,
            width_min: rule.width_min,
            width_max: rule.width_max,
            glass_height_max: rule.glass_height_max ?? null,
            glass_height_min: rule.glass_height_min ?? null,
            glass_width_max: rule.glass_width_max ?? null,
            glass_width_min: rule.glass_width_min ?? null,
            flippable: rule.flippable,
          } as Data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    const sendData = {
      ...data,
      color_groups: colors
    };

    if (match.params.id !== undefined) {
      axios
        .put(`${process.env.REACT_APP_SERVER_URL}/sizerules/${match.params.id}`, sendData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSave(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/sizerules`, sendData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSave(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (colorGroups.length === 0 || sizeGroups.length === 0) {
    return <div className="loading-screen">Adatok betöltése...</div>;
  }

  return (
    <>
      {save && <Redirect strict to="/size-list" />}
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <TextField
                id="name"
                name="name"
                label="Név"
                required
                fullWidth
                value={data.name}
                type="text"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="height_min"
                name="height_min"
                label="Minimum magasság"
                required
                value={data.height_min}
                type="number"
                fullWidth
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="height_max"
                name="height_max"
                fullWidth
                label="Maximum magasság"
                required
                value={data.height_max}
                type="number"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="width_min"
                name="width_min"
                label="Minimum szélesség"
                required
                value={data.width_min}
                type="number"
                fullWidth
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="width_max"
                name="width_max"
                label="Maximum szélesség"
                required
                value={data.width_max}
                fullWidth
                type="number"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="glass_height_min"
                name="glass_height_min"
                label="Minimum magasság (Üveg)"
                value={data.glass_height_min ?? ''}
                type="number"
                fullWidth
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="glass_height_max"
                name="glass_height_max"
                fullWidth
                label="Maximum magasság (Üveg)"
                value={data.glass_height_max ?? ''}
                type="number"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="glass_width_min"
                name="glass_width_min"
                label="Minimum szélesség (Üveg)"
                value={data.glass_width_min ?? ''}
                type="number"
                fullWidth
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                id="glass_width_max"
                name="glass_width_max"
                label="Maximum szélesség (Üveg)"
                value={data.glass_width_max ?? ''}
                fullWidth
                type="number"
                onChange={handleChange}
                className={classes.textField}
              />
            </Grid>
            <Grid item lg={6}>
              <FormControl fullWidth>
                <InputLabel id="size-group-select">Méret csoport</InputLabel>
                <Select
                  labelId="size-group-select"
                  name="size_group_id"
                  id="size_group_id"
                  fullWidth
                  required
                  value={data.size_group_id}
                  onChange={handleChangeSelect}
                >
                  {sizeGroups.length > 0 &&
                  sizeGroups.map((group) => (
                    <MenuItem key={group.description} value={group.id}>
                      {group.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControl fullWidth>
                <InputLabel id="size-group-select">Szín csoport</InputLabel>
                <Select
                  labelId="color-group-select"
                  name="color_groups"
                  id="color_groups"
                  multiple
                  required
                  value={colors}
                  onChange={handleChangeColors}
                  input={<Input />}
                  fullWidth
                  renderValue={(selected) =>
                    (selected as number[]).map(
                      (value) => colorGroups.find((item) => item.id === value)?.description + ', ',
                    )
                  }
                >
                  {colorGroups.length > 0 &&
                  colorGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="flippable"
                    name="flippable"
                    checked={data.flippable === 1}
                    onChange={handleChangeFlippable}
                  />
                }
                label="Szélesség és magasság felcserélhető"
              />
            </Grid>
            <Grid item lg={12}>
              <Button type="submit" variant="contained" color="primary">
                Mentés
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

