import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { Role } from './useAdminRoleList';

type UseAdminUserChangeRolesHookType = () => (id: number, roles: number[]) => Promise<Role[]>

export const useAdminUserChangeRoles: UseAdminUserChangeRolesHookType = () => {

  const token = useToken()

  return useCallback(async (id, roles) => {
    const {data} = await axios.post<Role[]>(
      `${process.env.REACT_APP_SERVER_URL}/users/${id.toString()}/roles`,
      {
        roles
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}