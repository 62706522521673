import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#B2A77D',
    height: '100%',
    width: '248px',
    color: 'white',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 12px 6px rgb(83 83 83 / 16%)',
    },
  },
  disabled: {
    opacity: '0.5',
    backgroundColor: '#f0f0f0',
    color: '#000',
    cursor: 'default',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));

type TypeSwitchItemProps = {
  activeType: string | number | undefined
  id: string | number
  name: string
  onClick: () => void
}

export const TypeSwitchItem: FC<TypeSwitchItemProps> = (
  {
    activeType,
    id,
    name,
    onClick,
  },
) => {
  const classes = useStyles();

  const image = (() => {
    switch (id) {
      case 2:
        return '/images/festett.png';
      case 3:
        return '/images/akril.png';
      case 1:
        return '/images/folias.png';
      case 4:
        return '/images/alu.png';
      case 5:
        return '/images/front_panel.png';
      case 6:
        return '/images/raktari.png';
      case 'custom':
        return '/images/egyedi.png';
    }
  })();

  return (
    <div onClick={onClick} className={classes.root + ' ' + (activeType && id !== activeType ? classes.disabled : '')}>
      <Grid container alignItems={'center'} justify={'center'}>
        {(activeType ? null : (<img src={image} alt={name} />))}
        <h2>{name}</h2>
      </Grid>
    </div>
  );
};

