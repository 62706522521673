import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { useIsAdmin } from './useIsAdmin';
import { useOrganisations } from './useOrganisations';

type UseSelectedOrganisationIdHookType = () => number;

export const useSelectedOrganisationId: UseSelectedOrganisationIdHookType = () => {

  const selectedOrganisationId = useSelector<RootState, number | undefined>(
    state => state.auth.selectedOrganisation
  );
  const isAdmin = useIsAdmin()
  const organisations = useOrganisations();

  if (isAdmin && selectedOrganisationId !== undefined) {
    return selectedOrganisationId
  }

  if (!isAdmin) {
    return organisations[0]?.id ?? -1
  }

  return -1
}
