import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

type UseUniqueRequestHookType = () => (
  input: {
    note: string
    ship_id?: number
    org_id: number
    purchase_number: string
    items: {
      note: string
      files: number[]
    }[]
  }
) => Promise<void>

export const useUniqueRequest: UseUniqueRequestHookType = () => {
  const token = useToken()

  return useCallback(async (input) => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/order/custom`,
      input,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }, [token])
}
