import React, { FC, FormEventHandler, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Lock from '@material-ui/icons/Lock';
import Logo from '../../images/logo-dark.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { authActions, AuthState } from '../data-management/authSlice';
import axios from 'axios';
import { RootState } from '../data-management/store';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
	},
	card: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		padding: theme.spacing(3),
	},
	cardHeader: {
		display: "flex",
		alignItems: "center",
		marginBottom: theme.spacing(3),
	},
	title: {
		fontSize: 36,
		fontWeight: 300,
		marginRight: theme.spacing(10),
	},
	link: {
		color: "#b2a77d",
		textDecoration: "underline",
		marginTop: '5px',
	},
	loginBtn: {
		position: "relative",
		backgroundColor: "#b2a77d",
		color: "#fff",
		display: "block",
		padding: "12px",
		textAlign: "center",
		borderRadius: 0,
		width: "100%",
		textTransform: "unset",
		fontSize: "16px",
		border: 0,
		"&::after": {
			content: '""',
			display: "block",
			border: "1px solid #ccc",
			position: "absolute",
			top: "50%",
			left: "50%",
			width: "calc(100% - 15px)",
			height: "calc(100% - 15px)",
			transform: "translate(-50%, -50%)",
		},
		"&:hover": {
			backgroundColor: "#b2a77d",
			boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
			cursor: "pointer",
		},
	},
	loginBackground: {
		width:"100%",
		height:"100%",
		background: "url(/images/authbg.png) no-repeat center center/cover",
		position:"fixed",
		top:0,
		left:0,
		opacity:.5
	}
}));

export const Login: FC = () => {
	const classes = useStyles();

	const user = useSelector<RootState, AuthState>(state => state.auth)

	const [credentials, setCredentials] = useState({
		email: "",
		password: "",
		remember: true,
	});

	const dispatch = useDispatch();

	const handleChange: StandardInputProps['onChange'] = (event) => {
		setCredentials({ ...credentials, [event.target.name]: event.target.value });
	};

	const handleCheckbox: SwitchBaseProps['onChange'] = (e) => {
		setCredentials({ ...credentials, remember: e.target.checked });
	};

	const onSubmit: FormEventHandler = (e) => {
		e.preventDefault();

		dispatch(authActions.authLoginRequest());
		axios
			.post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, credentials)
			.then((res) => {
				const date = new Date();
				const user = res.data.user;
				user.accessToken = res.data.access_token;
				user.tokenExpire = date.getTime() + res.data.expires_in;
				date.setTime(date.getTime() + res.data.expires_in * 1000);

				dispatch(authActions.successfullyLogin(user));
			})
			.catch((error) => {
				let errorMsg;

				if (error.response.status === 401) {
					errorMsg = "Sajnáljuk, de ilyen e-mail cím jelszó párossal nincs felhasználó!";
				} else {
					errorMsg = error.response.data.error;
				}

				dispatch(authActions.failedLogin(errorMsg));
			});
	};

	return (
		<>
			{user.isLoggedIn && <Redirect to="/order" />}
			<div className={classes.root}>
				<div className={classes.loginBackground}></div>
				<Card className={classes.card}>
					<CardContent>
						<div className={classes.cardHeader}>
							<Typography className={classes.title}>Bejelentkezés</Typography>
							<img src={Logo} alt="Nettfront" />
						</div>
						<div style={{ color: "red", textAlign: "center", marginBottom: "20px" }}>{user.error}</div>
						<form onSubmit={onSubmit}>
							<Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: "20px" }}>
								<Grid item>
									<AccountCircle />
								</Grid>
								<Grid item style={{ flexGrow: 1 }}>
									<TextField
										error={user.error !== ''}
										id="input-with-icon-grid"
										name="email"
										onChange={handleChange}
										fullWidth
										label="E-mail cím"
									/>
								</Grid>
							</Grid>

							<Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: "20px" }}>
								<Grid item>
									<Lock />
								</Grid>
								<Grid item style={{ flexGrow: 1 }}>
									<TextField
										error={user.error !== ''}
										type="password"
										name="password"
										onChange={handleChange}
										id="input-with-icon-grid"
										fullWidth
										label="Jelszó"
									/>
								</Grid>
							</Grid>

							<FormControlLabel
								control={
									<Checkbox
										checked={credentials.remember}
										color="primary"
										onChange={handleCheckbox}
										name="remember"
									/>
								}
								style={{ marginBottom: "20px" }}
								label="Emlékezz rám"
							/>
							<button type="submit" className={classes.loginBtn} style={{ marginBottom: "15px" }}>
								Bejelentkezés
							</button>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<Link className={classes.link} to="/regisztracio">
									Regisztrációs igény elküldése
								</Link>
								<Link className={classes.link} to="/elfelejtett-jelszo">
									Elfelejtett jelszó
								</Link>
							</div>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<a className={classes.link} target="_blank" href="https://nettfront.hu/szinkartya">
									Kedvezményes színkártya regisztráció 
								</a>
							</div>
						</form>
					</CardContent>
				</Card>
			</div>
		</>
	);
};
