import React, { FC } from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { Block, CloudOff, Done, SettingsOverscan } from '@material-ui/icons';
import { ValidationState } from '../../data-management/productsSlice';
import { useProduct } from '../../hooks/useProduct';
import { ProductValidation } from './ProductValidation';

type ValidationStateMap = {
  tooltip: string
  icon: JSX.Element
}

const validationMap: {
  [name in (ValidationState | 'badSize')]: ValidationStateMap
} = {
  'waiting': {
    tooltip: 'A termék validálásra vár',
    icon: (
      <CircularProgress
        size={20}
        color={'secondary'}
      />
    ),
  },
  'badSize': {
    tooltip: 'Mérethibás',
    icon: (
      <SettingsOverscan
        htmlColor={'red'}
      />
    ),
  },
  'incomplete': {
    tooltip: 'Hiányos termék, a validálás nem indulhat el',
    icon: <CloudOff />,
  },
  'processing': {
    tooltip: 'A termék validálása folyamatban',
    icon: (
      <CircularProgress
        size={20}
        color={'primary'}
      />
    ),
  },
  'failed': {
    tooltip: 'Sikertelen validálás',
    icon: <Block
      htmlColor={'red'}
    />,
  },
  'correct': {
    tooltip: 'Termék helyes',
    icon: <Done
      htmlColor={'green'}
    />,
  },
};

type ProductValidatorProps = {
  productId: number
}

export const ProductValidator: FC<ProductValidatorProps> = React.memo((
  {
    productId,
  },
) => {

  const product = useProduct(productId);
  const sizeValidationState = product.sizeValidationState
  const errorText = product.error?.text.substring(product.error?.text.indexOf('//') + 2, product.error?.text.split('/', 3).join('/').length) ?? 'Nincs indok';
  const failed = product.validationState === 'failed'
  const badSize = sizeValidationState === 'bad'

  const validationState = validationMap[badSize ? 'badSize' : product.validationState];

  const tooltipText = failed ? `${validationState.tooltip}: \n${errorText}` : validationState.tooltip

  return (
    <>
      <Tooltip title={tooltipText}>
        {validationState.icon}
      </Tooltip>
      <ProductValidation product={product} />
    </>
  );
});