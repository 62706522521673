import React, { FC, useEffect } from 'react';
import axios from 'axios';
import { useToken } from '../hooks/useToken';

type CronLogProps = {}

export const CronLog: FC<CronLogProps> = () => {
  const token = useToken()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/log/statuscron`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

  }, [])

  return (<></>);
};