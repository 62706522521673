import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import File from "./File";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		maxWidth: "100%",
		marginTop: 50,
		"& .files > div:nth-of-type(odd)": {
			backgroundColor: "rgba(196, 196, 196, 0.1);",
		},
	},
}));

const Downloads = () => {
	const classes = useStyles();

	return (
		<Grid container spacing={3} className={classes.root}>
			<Grid item xs={12}>
				<div className="header" style={{ display: "flex", padding: "16px 50px", color: "rgba(83, 83, 83, .7)", borderBottom: "solid 1px #E5E5E5" }}>
					<div style={{ width: "50%" }}>Fájl</div>
					<div style={{ width: "20%" }}>Dátum</div>
					<div style={{ width: "10%" }}>Méret</div>
					<div style={{ width: "10%", textAlign: "center" }}>Letöltés</div>
				</div>
				<div className="files">
					<File />
					<File />
					<File />
					<File />
					<File />
					<File />
					<File />
					<File />
				</div>
			</Grid>
		</Grid>
	);
};

export default Downloads;
