import React, { FC, useState } from 'react';
import { CircularProgress, Dialog, DialogContent, Grid, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close, Done, ErrorOutline } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useAdminCatalogueSync } from '../hooks/useAdminCatalogueSync';
import { useCategoryId } from '../hooks/useCategoryId';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: 100,
  }
}));

type CatalogueSyncModalProps = {
  open: boolean
  onClose: () => void
}

export const CatalogueSyncModal: FC<CatalogueSyncModalProps> = (
  {
    open,
    onClose,
  },
) => {
  const classes = useStyles();

  const [progressing, setProgressing] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const catalogueSync = useAdminCatalogueSync();
  const { catId, setCatId } = useCategoryId();

  const onModalClose = () => {
    if (!progressing)
      onClose();
  };

  const onSubmit = () => {
    setProgressing(true);

    catalogueSync()
      .then(() => {
        setProgressing(false)
        setSuccess(true)
        setCatId(undefined)
      }).catch(() => {
      setProgressing(false)
      setSuccess(false)
    })
  };

  return (
    <Dialog
      onClose={onModalClose}
      open={open}
      fullWidth
      maxWidth={'xs'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Katalógus szinkronizálása
        </Typography>
        {
          !progressing ?
            <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
              <Close />
            </IconButton> : <></>
        }
      </DialogTitle>
      <DialogContent>
        {
          progressing ?
            <Grid container direction={'column'} alignItems={'center'}>
              <CircularProgress />
              <Typography>
                A szinkronizálás folyamatban
              </Typography>
            </Grid> :
            <Typography>
              A katalógus szinkronizálása akár egy percig is eltarthat. Biztosan megkezdi a szinkronizálást?
            </Typography>
        }
        {
          success === true && !progressing ?
            <Grid container direction={'column'} alignItems={'center'}>
              <Done className={classes.icon}/>
              <Typography>
                A szinkronizálás sikeres
              </Typography>
            </Grid> : <></>
        }
        {
          success === false && !progressing ?
            <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
              <ErrorOutline className={classes.icon} />
              <Typography>
                A szinkronizálás sikertelen
              </Typography>
            </Grid> : <></>
        }
      </DialogContent>
      {
        !progressing ?
          <DialogActions>
            <Button
              autoFocus
              onClick={onSubmit}
              color="secondary"
              variant={'contained'}
              size={'small'}
            >
              Szinkronizálás
            </Button>
          </DialogActions> : <></>
      }
    </Dialog>
  );
};
