import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Role, useAdminRoleList } from '../hooks/useAdminRoleList';
import { useAdminUserRoles } from '../hooks/useAdminUserRoles';
import { useAdminUserChangeRoles } from '../hooks/useAdminUserChangeRoles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { useAdminStatusChange } from '../hooks/useAdminStatusChange';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

type EditStatusModalProps = {
  open: boolean
  onClose: () => void
  onSave: (active: number) => void
  id?: number
  userName?: string,
  statusProp: number,
}

export const EditStatusModal: FC<EditStatusModalProps> = (
  {
    onClose,
    open,
    id,
    userName,
    statusProp,
    onSave: onSaveProp,
  },
) => {
  const classes = useStyles();

  const [status, setStatus] = useState<number>(statusProp);

  const adminStatusChangeApi = useAdminStatusChange();

  const onModalClose = () => {
    onClose();
  };

  const onSave = async () => {
    if (!id)
      return;

    await adminStatusChangeApi(id, status);

    onSaveProp(status);
  };

  const onStatusChange: () => SwitchBaseProps['onChange'] = () => (
    _event,
    checked,
  ) => {
    if (checked) {
      setStatus(1);
    } else {
      setStatus(0);
    }
  };

  return (
    <Dialog
      onClose={onModalClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Felhasználó: {userName ?? ''}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <FormControlLabel
        control={
          <Switch
            checked={!!status}
            onChange={onStatusChange()}
            size={'small'}
          />}
        label="A felhasználó belépésének engedélyezése"
        labelPlacement={'start'}
        color={'primary'}
      />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSave}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Mentés
        </Button>
      </DialogActions>
    </Dialog>
  );
};