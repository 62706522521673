import { useProduct } from './useProduct';
import { useProductSizeRules, useProductSizeRulesFromValidation } from './useProductSizeRules';
import { ProductSizeRules, ValidationState } from '../data-management/productsSlice';

type UseProductSizeRuleValidHookType = (productId: number) => [boolean | undefined, boolean | undefined]

const correctCheck: (
  rules: ProductSizeRules | undefined,
  width: string | number | undefined,
  height: string | number | undefined,
) => [boolean | undefined, boolean | undefined] = (
  rules,
  width,
  height,
) => {
  if (rules === undefined || rules.length === 0)
    return [true, true];

  if (width === undefined || height === undefined)
    return [undefined, undefined]

  const widthValue = Number(width );
  const heightValue = Number(height);

  const singleValue = rules.reduce<[number, number] | false | undefined>((
    single, {height, width}
  ) => {
    if (single !== undefined)
      return false

    const same = (height.max === height.min) && (width.min === width.max)

    return same ? [height.max, width.max] : false
  }, undefined)

  if (Array.isArray(singleValue))
    return [heightValue === singleValue[0], widthValue === singleValue[1]]

  return rules.some((
    {
      height: {
        max: heightMax,
        min: heightMin,
      },
      width: {
        max: widthMax,
        min: widthMin,
      },
    },
    ) => heightMin <= heightValue && heightValue <= heightMax &&
    widthMin <= widthValue && widthValue <= widthMax,
  ) ? [true, true] : [false, false];
};

export const productSizeRuleHelper: (
  validationState: ValidationState,
  rules?: ProductSizeRules,
  rulesFromValidation?: ProductSizeRules,
) => ProductSizeRules | undefined =
  (validationState, rules, rulesFromValidation) => {
    if (rules === undefined && rulesFromValidation === undefined)
      return undefined;

    if (rules === undefined && rulesFromValidation !== undefined)
      return rulesFromValidation;

    if (rules !== undefined && rulesFromValidation === undefined)
      return rules;

    if (validationState === 'incomplete')
      return rules;

    return rulesFromValidation;
  };


export const useProductSizeRuleValid: UseProductSizeRuleValidHookType = (productId) => {

  const product = useProduct(productId);
  const sizeRulesNormal = useProductSizeRules(productId);
  const sizeRulesFromValidation = useProductSizeRulesFromValidation(productId);
  const sizeRules = productSizeRuleHelper(product.validationState, sizeRulesNormal, sizeRulesFromValidation);

  const height = product.features.find(({ code }) => code === 'MAG')?.keyed_value;
  const width = product.features.find(({ code }) => code === 'SZEL')?.keyed_value;

  return correctCheck(sizeRules, width, height);
};

export const productSizeRuleCorrectCheck = correctCheck;
