import { useCallback } from 'react';
import axios from 'axios';

type UseSavedOrderDeleterHookType = (token: string) => (id: number) => Promise<void>

export const useSaveOrderDeleter: UseSavedOrderDeleterHookType = (token) => {

  return useCallback((id) => {

    return axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/order/saved/${id.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }, [token])
}
