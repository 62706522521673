import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { useMemo } from 'react';
import { FieldType, productActions } from '../data-management/productsSlice';

type UseCategoryIdHookType = () => {
  catId?: string | number
  setCatId: (catId?: string | number, fields?: FieldType[]) => void
  fields: FieldType[]
}

export const useCategoryId: UseCategoryIdHookType = () => {
  const categoryId = useSelector<RootState, string | number | undefined>(state => state.products.categoryId)
  const fields = useSelector<RootState, FieldType[] | undefined>(state => state.products.fields)

  const dispatch = useDispatch()

  return useMemo(() => ({
    catId: categoryId,
    setCatId: (catId, fields) => {
      dispatch(productActions.resetProducts());
      dispatch(productActions.setCategoryId({
        catId,
        fields
      }))
    },
    fields: fields ?? []
  }), [categoryId, fields])
}
