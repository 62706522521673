import TextField from '@material-ui/core/TextField';
import { Autocomplete, UseAutocompleteProps } from '@material-ui/lab';
import React, { FC } from 'react';
import { SettingsType, SettingType } from '../hooks/useSettingsGet';

type StringArraySettingProps = {
  name: string
  setSetting: (inner: (settings: SettingsType) => SettingsType) => void
  setting: SettingType
}

export const StringArraySetting: FC<StringArraySettingProps> = (
  {
    setting,
    setSetting,
    name
  }
) => {

  const autocompleteHandleChange: UseAutocompleteProps<string, true, false, true>['onChange'] =
      (
        event,
        value,
        reason
      ) => {
        setSetting(data => ({
          ...data,
          [name]: {
            ...setting,
            value
          },
        }));
      }

  return (
    <Autocomplete
      multiple
      freeSolo
      limitTags={3}
      fullWidth
      onChange={autocompleteHandleChange}
      value={(setting.value as string[]).flat()}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant="standard"
          helperText={'Több értéket elfogadó mező, minden érték után üssön Entert.'}
        />
      )}
      options={[]}
    />
  )
}
