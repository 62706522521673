import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { Role } from './useAdminRoleList';

export type User = {
  created_at: string
  email: string
  email_verified_at?: string
  id: number
  name: string
  price_extra?: number
  price_type: number
  resetpw_code?: string
  roles: Role[]
  updated_at: string
  last_login: string
}

type UseAdminUserListHookType = () => () => Promise<User[]>

export const useAdminUserList: UseAdminUserListHookType = () => {

  const token = useToken()

  return useCallback(async () => {
    const {data} = await axios.get<User[]>(
      `${process.env.REACT_APP_SERVER_URL}/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}