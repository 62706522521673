import { authActions, Organisation } from '../data-management/authSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { useOrganisationLoader } from './useOrganisationLoader';
import { useMemo } from 'react';

type UseOrganisationsHookType = () => Organisation[]

export const useOrganisations: UseOrganisationsHookType = () => {

  const organisations = useSelector<RootState, Organisation[] | undefined>(state => state.auth.user?.organisations)

  return useMemo(() => {
    return organisations ?? [];
  }, [organisations])
}
