import { useCallback } from 'react';
import axios from 'axios';
import { useToken } from './useToken';

export type Edge = {
  description: string
  code: string
  isDefault: boolean
}

type UseEdgeGetterHookType = () => (id: number) => Promise<Edge[]>

export const useEdgeGetter: UseEdgeGetterHookType = () => {

  const token = useToken()

  return useCallback(async (id: number) => (await axios.get<Edge[]>(
    `${process.env.REACT_APP_SERVER_URL}/products/${id}/edges`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )).data, [token])
}
