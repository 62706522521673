import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type ProductListRemoveModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const ProductListRemoveModal: FC<ProductListRemoveModalProps> = (
  {
    open,
    onClose,
    onSuccess
  }
) => {

  return (
    <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>Biztosan törölni szeretné a kijelölt terméket?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Törlés után a művelet nem visszavonható!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Mégse
      </Button>
      <Button onClick={onSuccess} color="secondary" autoFocus>
        Igen törlöm
      </Button>
    </DialogActions>
  </Dialog>
  );
};