import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Box, CircularProgress, Button } from '@material-ui/core';
import { DataGrid, GridColumns, GridSortModel } from '@material-ui/data-grid';
import { parseISO } from 'date-fns';
import { SavedOrder } from '../hooks/useSaveProducts';
import axios from 'axios';
import { useToken } from '../hooks/useToken';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { useHistory } from 'react-router';
import { NewSendModal } from './NewSendModal';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    minHeight: '90vh',
    '& .MuiDataGrid-row.Mui-even': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
    },
  },
  paper: {
    padding: '40px 50px 52px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  filterItem: {
    flexGrow: 1,
    marginRight: theme.spacing(20),
  },
  itemFlexGrow: {
    flexGrow: 1,
    minHeight: 400,
    padding: '50px',
  },
  statusRow: {
    backgroundColor: '#DEFFBD',
    paddingLeft: '6px',
    paddingRight: '6px'
  }
}));

export const NewsletterSendPage = () => {
  const classes = useStyle();
  const token = useToken();
  const orgId = useSelectedOrganisationId();
  const browserHistory = useHistory();

  const [items, setItems] = useState<SavedOrder[] | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const [newSendModal, setNewSendModal] = useState(false);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const refreshTable = function(){
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/newsletter/get-sent-newsletters`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setItems(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    refreshTable();
  }, [orgId]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Azonosító',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      flex: 0.40
    },
    {
      field: 'pattern_name',
      headerName: 'Sablon neve',
      flex: 0.60,
    },
    {
      field: 'group_name',
      headerName: 'Csoportnak kiküldve',
      flex: 0.75,
    },
    {
      field: 'subject',
      headerName: 'Levél tárgya',
      flex: 0.75,
    },
    {
      field: 'created_at',
      headerName: 'Küldés dátuma',
      flex: 0.75,
      valueGetter: (params) => {
        return typeof params.value === 'string' ?
          parseISO(params.value) : new Date();
      },
      type: 'dateTime',
    },
    {
      field: 'created_by_name',
      headerName: 'Küldő felhasználó',
      flex: 0.75,
    },
    {
      field: 'sent_count',
      headerName: 'Tervezett kiküldött darabszám',
      flex: 0.75,
    }
  ];

  const rows = items ?? [];

  return (
    <>
    <Grid container direction={'column'} className={classes.root}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.filterItem} variant="h4">
              Küldések
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item className={classes.itemFlexGrow}>
        <Box style={{textAlign: "right"}}>
          <Button variant={'contained'} color={'primary'} onClick={() => setNewSendModal(true) } >
            Új küldés
          </Button>
        </Box>
        <Paper>
        {loading ? <Grid container justify={'center'}><Box p={2}><CircularProgress/></Box></Grid> :
          <div style={{ minHeight: 300, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
              sortModel={sortModel}
              onSortModelChange={({ sortModel }) => setSortModel(sortModel)}
            />
          </div> }
        </Paper>
      </Grid>
    </Grid>
    <NewSendModal
      open={!!newSendModal}
      onClose={() => {
        setNewSendModal(false);
        refreshTable();
      }}
    />
    </>
  );
};