import React, { FC, useEffect } from 'react';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useOrganisations } from '../../hooks/useOrganisations';
import { useDispatch, useSelector } from 'react-redux';
import { useOrganisationAddressLoader } from '../../hooks/useOrganisationAddressLoader';
import { useOrganisationLoader } from '../../hooks/useOrganisationLoader';
import { RootState } from '../../data-management/store';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { authActions, Organisation } from '../../data-management/authSlice';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  companySelectWrapper: {
    position: 'absolute',
    left: 400,
    top: 16,
    padding: theme.spacing(1),
    transition: 'left .3s',
  },
  companySelectWrapperLeft: {
    left: 700,
  },
  autocomplete: {
    width: 320
  }
}));

type AdminOrgPickerProps = {
  open: boolean
}

export const AdminOrgPicker: FC<AdminOrgPickerProps> = (
  {
    open,
  },
) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const organisations = useOrganisations();
  const selectedOrganisationId = useSelector<RootState, number | undefined>(
    state => state.auth.selectedOrganisation,
  );
  const organisationAddressLoader = useOrganisationAddressLoader();
  const orgLoader = useOrganisationLoader();
  const selectedOrganisation = organisations?.find(({id}) => id === selectedOrganisationId) ?? null;

  useEffect(() => {
    orgLoader();
  }, []);
  useEffect(() => {
    if (!organisations)
      return;

    const selectedOrganisation = organisations.find(({ id }) => id === selectedOrganisationId);

    if (!selectedOrganisation)
      return;

    if (!selectedOrganisation.addresses) {
      organisationAddressLoader(selectedOrganisation.id);
    }
  }, [selectedOrganisationId]);

  type CustomAutocompleteType = AutocompleteProps<Organisation, false, false, false>

  const renderInput: CustomAutocompleteType['renderInput'] = (params) => (
    <TextField
      variant={'outlined'}
      {...params}
      label={'Cég'}
      helperText={'Válassza ki, hogy melyik cég nevében cselekszik'}
    />
  );

  const getOptionLabel: CustomAutocompleteType['getOptionLabel'] = (organisation) =>
    organisation.name + ' (' + organisation.code + ')';

  const getOptionSelected: CustomAutocompleteType['getOptionSelected'] = (option, value) =>
    option.id === value.id

  const onChange: CustomAutocompleteType['onChange'] = (
    event,
    value,
    reason
  ) => {
    if (!isAdmin)
      return;

    if (['select-option', 'blur'].includes(reason)) {
      dispatch(authActions.selectOrganisation(value?.id ?? undefined));
    }
    if (['clear'].includes(reason)) {
      dispatch(authActions.selectOrganisation(undefined));
    }
  };

  return isAdmin ?
    <Paper className={clsx(classes.companySelectWrapper, open ? classes.companySelectWrapperLeft : '')}>
      <Autocomplete
        className={classes.autocomplete}
        options={organisations}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        autoComplete
        autoSelect
        openOnFocus
        autoHighlight
        value={selectedOrganisation}
        clearText={'Törlés'}
        closeText={'Bezárás'}
        openText={'Megnyitás'}
        getOptionSelected={getOptionSelected}
        noOptionsText={'Nincs betöltött cég'}
        loading={organisations.length === 0}
        onChange={onChange}
      />
    </Paper> : <></>
    ;
};