import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, makeStyles, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { productActions } from '../../data-management/productsSlice';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { useProduct } from '../../hooks/useProduct';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type RalNumberInputModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  productId: number
}

export const RalNumberInputModal: FC<RalNumberInputModalProps> = (
  {
    open,
    onClose,
    onSuccess,
    productId,
  },
) => {
  const classes = useStyles();

  const [ralNumber, setRalNumber] = useState('');
  const readyForSubmit = ralNumber !== '';
  const product = useProduct(productId)
  const currentSzinFeature = product.features.find(({ code }) => code === 'SZIN')

  const dispatch = useDispatch();

  const onModalClose = () => {
    onClose();
  };

  useEffect(() => {
    setRalNumber(currentSzinFeature?.keyed_value?.toString() ?? '')
  }, [currentSzinFeature])

  const onSubmit = () => {
    if (!currentSzinFeature)
      onClose();

    dispatch(productActions.modifyProductFeatureById({
      id: productId,
      feature: {
        ...currentSzinFeature,
        code: currentSzinFeature?.code ?? '',
        keyed_value: `${ralNumber}`
      },
    }));
    onSuccess();
  };

  const onChange: StandardInputProps['onChange'] = (e) => {
    setRalNumber(e.target.value);
  };

  return (
    <Dialog
      onClose={onModalClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          RAL szín
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container justify={'center'}>
          <Grid item xs={12} sm={10} md={6}>
            <TextField
              fullWidth
              inputMode="text"
              inputProps={{
                step: 1,
                min: 0,
              }}
              value={ralNumber}
              onChange={onChange}
              label={'RAL szín'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSubmit}
          color="secondary"
          variant={'contained'}
          size={'small'}
          disabled={!readyForSubmit}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
