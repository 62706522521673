import { useSelector } from 'react-redux';
import { RootState } from '../data-management/store';
import { ProductSizeRules } from '../data-management/productsSlice';

type UseProductSizeRulesHookType = (id: number) => ProductSizeRules | undefined

export const useProductSizeRules: UseProductSizeRulesHookType = (id) =>
  useSelector<RootState, ProductSizeRules | undefined>(
    state => state.products.products.find(({ key_id }) => id === key_id)?.sizeRules,
  );

export const useProductSizeRulesFromValidation: UseProductSizeRulesHookType = (id) =>
  useSelector<RootState, ProductSizeRules | undefined>(
    state => state.products.products.find(({ key_id }) => id === key_id)?.sizeRulesFromValidation,
  );

