import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { useToken } from '../hooks/useToken';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { SizeGroupType } from './SizeGroupSingle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  newBtn: {
    float: 'right',
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'unset !important',
    color: '#fff',
  },
}));

export type SizeGroupElem = {
  color_groups: {
    code: string
    color_group_id: number
    description: string
  }[]
  created_at: string
  flippable: number
  glass_height_max?: number
  glass_height_min?: number
  glass_width_max?: number
  glass_width_min?: number
  height_max: number
  height_min: number
  width_max: number
  width_min: number
  id: number
  name: string
  size_group_id: number
  updated_at: string
}

export type SizeGroupColorGroup = SizeGroupElem['color_groups'][number]

export const SizeGroupList = () => {
  const [data, setData] = useState<SizeGroupElem[]>([]);
  const classes = useStyles();
  const token = useToken();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const [sizeGroups, setSizeGroups] = useState<SizeGroupType[]>([]);

  const openDialog = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const onDeleteItem = () => {
    if (deleteId !== undefined)
      deleteSizeRule(deleteId);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get<SizeGroupElem[]>(`${process.env.REACT_APP_SERVER_URL}/sizerules`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/sizerules/sizegroups`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSizeGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteSizeRule = (id: number) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/sizerules/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(data =>
          data.filter((item) => item.id !== id),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rows = data ?? [];

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Név',
      flex: 2,
    },
    {
      field: 'sizeGroup',
      headerName: 'Méretcsoport',
      valueGetter: (params) => {
        const sizeGroupId = params.getValue(params.id, 'size_group_id') as number;

        return sizeGroups.find(({ id }) => id === sizeGroupId)?.description ?? '';
      },
      flex: 1,
    },
    {
      field: 'height',
      headerName: 'Magasság',
      valueGetter: (params) => {
        const min = params.getValue(params.id, 'height_min');
        const max = params.getValue(params.id, 'height_max');
        return `${min} - ${max} mm`;
      },
      flex: 1,
    },
    {
      field: 'width',
      headerName: 'Szélesség',
      valueGetter: (params) => {
        const min = params.getValue(params.id, 'width_min');
        const max = params.getValue(params.id, 'width_max');
        return `${min} - ${max} mm`;
      },
      flex: 1,
    },
    {
      field: 'glassHeight',
      headerName: 'Üveges magasság',
      valueGetter: (params) => {
        const min = params.getValue(params.id, 'glass_height_min');
        const max = params.getValue(params.id, 'glass_height_max');
        if (!min || !max)
          return '';

        return `${min} - ${max} mm`;
      },
      flex: 1,
    },
    {
      field: 'glassWidth',
      headerName: 'Üveges szélesség',
      valueGetter: (params) => {
        const min = params.getValue(params.id, 'glass_width_min');
        const max = params.getValue(params.id, 'glass_width_max');
        if (!min || !max)
          return '';

        return `${min} - ${max} mm`;
      },
      flex: 1,
    },
    {
      field: 'flippable',
      headerName: 'Felcserélhetőség',
      valueGetter: (params) => {
        return params.value;
      },
      width: 200,
      type: 'boolean',
    },
    {
      field: 'colorGroups',
      headerName: 'Színcsoportok',
      flex: 1,
      valueGetter: (params) => {
        const colorGroups = params.getValue(params.id, 'color_groups') as SizeGroupColorGroup[];

        return `${colorGroups.length} színcsoport`;
      },
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const colorGroups = params.getValue(params.id, 'color_groups') as SizeGroupColorGroup[];

        const tooltipTitle = (
          <List
            dense
            disablePadding
          >
            {colorGroups.map(({ description, color_group_id }) => (
              <ListItem key={color_group_id} dense>
                <ListItemText
                  primary={description}
                />
              </ListItem>
            ))}
          </List>
        );

        return (
          <Tooltip
            title={tooltipTitle}
          >
            <Chip label={params.value} />
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Műveletek',
      valueGetter: (params) => {
        return params.getValue(params.id, 'id');
      },
      renderCell: ({ value: id }) => (
        <>
          <Tooltip title="Szerkesztés" placement="top">
            <IconButton
              href={`/size-rule/${id}/edit`}
            >
              <Edit color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Törlés" placement="top">
            <IconButton
              onClick={() => openDialog(id as number)}
            >
              <Delete color="primary" />
            </IconButton>
          </Tooltip>
        </>
      ),
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container direction={'column'}>
        <Grid xs={12} container item justify={'flex-end'}>
          <Button
            color="primary"
            className={classes.newBtn}
            variant="contained"
            href={'/size-list/create'}
          >
            Új szabály létrehozása
          </Button>
        </Grid>
        <Paper>
          <div style={{ minHeight: 300, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={50}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
            />
          </div>
          <Box mb={4}/>
        </Paper>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Biztosan törölni szeretné a terméket?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Törlés után a művelet nem visszavonható!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button onClick={() => onDeleteItem()} color="secondary" autoFocus>
            Igen törlöm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
