import React, { FC } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

type ProductImageModalProps = {
  open: boolean
  onClose: () => void
  image?: string
}

export const ProductImageModal: FC<ProductImageModalProps> = (
  {
    open,
    onClose,
    image
  }
) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <img
          src={image}
          style={{ width: '100%', height: 'auto', maxHeight: '50vh', objectFit: 'contain' }}
          alt="image"
        />
      </DialogContent>
    </Dialog>

  );
};