import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { Role } from './useAdminRoleList';
import { Address } from '../data-management/authSlice';

export type User = {
  created_at: string
  email: string
  email_verified_at?: string
  id: number
  name: string
  price_extra?: number
  price_type: number
  resetpw_code?: string
  roles: Role[]
  updated_at: string
  active: number
  shippingaddresses: Address[]
}

type UseAdminUserShowHookType = () => (id: number) => Promise<User>

export const useAdminUserShow: UseAdminUserShowHookType = () => {

  const token = useToken()

  return useCallback(async (id) => {
    const {data} = await axios.get<User>(
      `${process.env.REACT_APP_SERVER_URL}/users/${id.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}