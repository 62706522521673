import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { useDispatch } from 'react-redux';
import { productActions } from '../../data-management/productsSlice';
import { useProduct } from '../../hooks/useProduct';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type UniqueEdgesSelectorModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  edges?: {
    code: string
    description: string
    id: number
  }[]
  productId: number
}

const elzArray = ['ELZ1', 'ELZ2', 'ELZ4', 'ELZ3']

export const UniqueEdgesSelectorModal: FC<UniqueEdgesSelectorModalProps> = (
  {
    open,
    onClose,
    onSuccess,
    edges: fieldEdgesRaw,
    productId
  },
) => {
  const classes = useStyles();

  const [edges, setEdges] = useState(['', '', '', '']);
  const readyForSubmit = edges.every((edge) => edge !== '')
  const dispatch = useDispatch();
  const fieldEdges = fieldEdgesRaw?.filter(({code}) => code !== 'ELZ')
  const product = useProduct(productId)

  const currentFeatures = elzArray.map(
    (elz) => product.features.find(({ code }) => code === elz)
  )

  useEffect(() => {
    setEdges(
      currentFeatures.map((feature) => feature?.option_code ?? '')
    )
  }, currentFeatures)

  const onModalClose = () => {
    onClose();
  };

  const onSubmit = () => {
    elzArray.forEach((code, index) =>
      dispatch(productActions.modifyProductFeatureById({
      id: productId,
      feature: {
        code,
        option_code: edges[index],
        option_value: fieldEdges?.find(({code: edgeCode}) => edges[index] === edgeCode)?.id
      }
    })))
    onSuccess();
  };

  const handleEdgeChange: (index: number) => SelectInputProps['onChange'] =
    (index) => (e) => {
      const tempEdges = [...edges];
      tempEdges[index] = (e.target.value ?? '') as string;
      setEdges(tempEdges);
    };

  return (
    <Dialog
      onClose={onModalClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          4 élen eltérő élzáró
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Felső élzáró</InputLabel>
              <Select
                fullWidth
                name={elzArray[0]}
                value={edges[0]}
                onChange={handleEdgeChange(0)}
              >
                {fieldEdges?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.code}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Bal élzáró</InputLabel>
              <Select
                fullWidth
                name={elzArray[1]}
                value={edges[1]}
                onChange={handleEdgeChange(1)}
              >
                {fieldEdges?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.code}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Jobb élzáró</InputLabel>
              <Select
                fullWidth
                name={elzArray[2]}
                value={edges[2]}
                onChange={handleEdgeChange(2)}
              >
                {fieldEdges?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.code}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Alsó élzáró</InputLabel>
              <Select
                fullWidth
                name={elzArray[3]}
                value={edges[3]}
                onChange={handleEdgeChange(3)}
              >
                {fieldEdges?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.code}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSubmit}
          color="secondary"
          variant={'contained'}
          size={'small'}
          disabled={!readyForSubmit}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
