import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { FC } from 'react';
import { Close } from '@material-ui/icons';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type ConfirmBackModalProps = {
  open: boolean
  onBack: () => void
  onConfirm: () => void
}

export const ConfirmBackModal: FC<ConfirmBackModalProps> = (
  {
    open,
    onBack,
    onConfirm,
  },
) => {

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onBack}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Visszalépés a kategóriához
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onBack}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Visszalépésnél a rendelési lista kiürül. Biztos vagy benne?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onBack}
          color="primary"
          variant={'contained'}
          size={'small'}
        >
          Mégse
        </Button>
        <Button
          autoFocus
          onClick={onConfirm}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          Kategóriaválasztás
        </Button>
      </DialogActions>
    </Dialog>
  );
};
