import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	oneLine: {
		display: "flex", 
		alignItems: "center", 
		padding: "16px 50px", 
		color: "#535353", 
		borderBottom: "solid 1px #E5E5E5" 		
	}, 
	download: {
		width: "24px",
		height: "24px",
		background: "url(/images/download_icon.svg) no-repeat center center/cover",
		display: "table",
		margin: "0 auto",
		cursor: "pointer",
		"&:hover": {
			opacity: .8
		}
	}
}));

const File = () => {
	const classes = useStyles();

	return (
		<div className={classes.oneLine}>
			<div style={{ width: "50%" }}>Alukeretes listaár 2020. április 16-tól <span style={{display:"inline-block", color:"#B2A77D", fontWeight:"600", "marginLeft": "40px"}}>Friss!</span></div>
			<div style={{ width: "20%" }}>2020. április 07. kedd</div>
			<div style={{ width: "10%" }}>883.33 Kb</div>
			<div style={{ width: "10%" }}>
				<div className={classes.download}></div>
			</div>
		</div>
	);
};

export default File;
