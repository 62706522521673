import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

type UniqueEdgeIconProps = {
  focus?: number
} & SvgIconProps

export const UniqueEdgeIcon: FC<UniqueEdgeIconProps> = (
    {
        focus,
        ...props
    }
) => {

    return <SvgIcon
        width={35}
        height={32}
        viewBox="0 0 35 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            x="6.5"
            y="5.5"
            width="23"
            height="23"
            fill="#FFFFFF"
            stroke="#535353"
            strokeLinejoin="round"
        />
        {
            focus !== undefined ? (
                <rect
                    x="6.5"
                    y="5.5"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    stroke="#535353"
                    strokeWidth="2px"
                    strokeLinejoin="round"
                    strokeDasharray="70%, 230%"
                    strokeDashoffset={`${Number(focus) * 70 + 20}%`}
                />
            ) : <></>
        }
        <path fillRule="evenodd" clipRule="evenodd" d="M17.664 11.6302L5.88551 0L0 3.74339L12.8256 16.4077L0.8257 28.2566L6.71121 32L17.664 21.1851L28.2867 31.6741L34.1722 27.9307L22.5023 16.4077L34.9979 4.06928L29.1124 0.325878L17.664 11.6302Z" fill="#FFFFFF"/>
    </SvgIcon>

}
