import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldOption, fieldOptionsActions, FieldOptionsState } from '../data-management/fieldOptionsSlice';
import axios from 'axios';
import { useCategoryId } from './useCategoryId';
import { useProducts } from './useProducts';
import { useToken } from './useToken';
import { RootState } from '../data-management/store';

type UseFieldOptionsCacheHookType = () => {
  getFieldOptions: (productId: number, fieldCode: string) => Promise<FieldOption[] | null>
}

export const useFieldOptionsCache: UseFieldOptionsCacheHookType = () => {

  const dispatch = useDispatch()
  const {fields, catId} = useCategoryId()
  const products = useProducts()
  const token = useToken()
  const fieldOptionsCache = useSelector<RootState, FieldOptionsState>(state => state.fieldOptions)

  const getFieldOptions = useCallback(async (
    productId,
    fieldCode,
  ) => {
    const fieldIndex = fields.findIndex(field => field.code === fieldCode);

    if (fieldIndex === -1) {
      return null;
    }

    if (fields[fieldIndex].type === 'number') {
      return null;
    }

    const requiredFields = fields.slice(0, fieldIndex).map(field => field.code);
    const product = products.find(({key_id}) => key_id === productId)

    if (!product) {
      return null;
    }

    const requiredFeatures = requiredFields.map(field =>
      product.features.find(feature => feature.code === field),
    ).filter(feature => !!feature);
    if (product.product_id && fieldCode !== 'PROD') {
      requiredFeatures.push({
        code: 'PROD',
        option_value: Number(product.product_id),
      });
    }

    if (requiredFields.length !== requiredFeatures.length) {
      return null;
    }

    const codes = requiredFields.map(
      field => (
        {
          code: field,
          value: (() => {
            const actualField = requiredFeatures
              .find(({ code } = { code: '' }) => code === field);
            return actualField?.keyed_value ? actualField.keyed_value : actualField?.option_value;
          })(),
        }
      ),
    );
    const cachedName = `${catId};` + codes.map(({ code, value }) => `${code}:${value}`).join(';') + `;${fieldCode}`;

    // Disabling RAKTARI kategória miatt - visszakapcsolva
    if (fieldOptionsCache[cachedName]) {
      return fieldOptionsCache[cachedName];
    }

    try {
      const { data: fieldOptions } = await axios
        .post<FieldOption[]>(`${process.env.REACT_APP_SERVER_URL}/categories/fields/${catId}`,
          {
            REQ: fieldCode,
            DATA: codes.length > 0 ? codes : undefined,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

      dispatch(fieldOptionsActions.add({
        name: cachedName,
        options: fieldOptions
      }))

      return fieldOptions;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, [fields, catId, fieldOptionsCache, products]);

  return ({
    getFieldOptions
  })
}