import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { authActions } from '../data-management/authSlice';
import { useToken } from '../hooks/useToken';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  middleItem: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  extraPriceBox: {
    display: 'inline-flex',
    alignItems: 'baseline',
  },
  percentField: {
    minWidth: 30,
    maxWidth: 50,
    width: 'auto',
  },
  percentFieldInput: {
    textAlign: 'right',
  },
}));

export const PricingEdit = () => {

  const classes = useStyles();

  const [value, setValue] = useState('own');
  const [extra, setExtra] = useState(10);
  const [error, setError] = useState(undefined);
  const [info, setInfo] = useState(undefined);
  const token = useToken();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (user?.price_type) {
      setValue(user.price_type === 0 ? 'own' : 'ownextra');
      if (user?.price_extra) {
        setExtra(Number(user.price_extra));
      }
    }
  }, [user]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onSave = () => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/auth/priceinfo`,
      {
        price_type: value === 'own' ? 0 : 1,
        price_extra: value === 'own' ? null : extra,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then(({ data }) => {
      setInfo(data);
      dispatch(authActions.modifyPriceInfo(
        {
          price_type: value === 'own' ? 0 : 1,
          price_extra: value === 'own' ? null : extra,
        },
      ));
    }).catch(e => {
      setError('Hiba a beállítás során');
    });
  };

  return (
    <Grid
      container
      justify={'center'}
      className={classes.root}
    >
      <Grid item xs={12} sm={10} md={8}>
        <Typography variant={'h5'}>
          Adja meg az árkijelzés módját
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={8} className={classes.middleItem}>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel value="own" control={<Radio />} label="Saját áramon" />
          <FormControlLabel value="ownextra" control={<Radio />} label={
            <Box className={classes.extraPriceBox}>
              Saját ár + árrés
              <TextField
                type={'number'}
                value={extra}
                margin={'none'}
                size={'small'}
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  classes: {
                    input: classes.percentFieldInput,
                  },
                }}
                className={classes.percentField}
                onChange={(e) => setExtra(Number(e.target.value))}
              />
              %
            </Box>
          } />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} sm={10} md={8}>
        {
          error ? (
            <Alert severity={'error'} variant={'outlined'}>
              {error}
            </Alert>
          ) : <></>
        }
        {
          info ? (
            <Alert severity={'success'} variant={'outlined'}>
              {info}
            </Alert>
          ) : <></>
        }
        <Button
          variant={'contained'}
          fullWidth
          onClick={onSave}
          color={'secondary'}
        >
          Mentés
        </Button>
      </Grid>
    </Grid>
  );
};