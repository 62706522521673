import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

type UseAdminUserSyncHookType = () => () => Promise<void>

export const useAdminUserSync: UseAdminUserSyncHookType = () => {

  const token = useToken()

  return useCallback(async () => {
    const {data} = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/sync/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}
