import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Organisation = {
  id: number
  name: string
  code: string
  arkat: string
  addresses?: Address[]
}

export type Address = {
  id: number
  organization_id: number
  type: string
  location_code: string
  address: string
  city: string
  zip: string
  country: string
  isdefault: number
  effective: string
  inactive: string
  language: string
  extras: {
    ShipmentModeCode: string
    ShippingZoneCode: string
  }
}

export type AuthState = {
  loading: boolean
  isLoggedIn: boolean
  user?: {
    name: string
    email: string
    id: number
    shipping_id: number
    organization_id: string
    organization: any
    updated_at: string
    accessToken: string
    tokenExpire: string
    order_processed_noti: boolean
    order_status_change_noti: boolean
    newsletter_noti: boolean
    order_confirm: boolean
    price_type: number
    price_extra: null | number
    shippingaddresses: Address[]
    roles: {
      id: number
      name: string
    }[]
    organisations?: Organisation[],
    terms_agreed: boolean,
  }
  selectedOrganisation?: number
  error: string
}

const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  error: '',
};

type AuthReducer<T = void> = CaseReducer<AuthState, PayloadAction<T>>

type Reducers = {
  authLoginRequest: AuthReducer
  successfullyLogin: AuthReducer<Required<AuthState['user']>>
  failedLogin: AuthReducer<string>
  refreshToken: AuthReducer<{ accessToken: string; expire: string }>
  me: AuthReducer<Required<AuthState['user']>>
  signOut: AuthReducer
  modifyPriceInfo: AuthReducer<{ price_type: number; price_extra: number | null }>
  loadOrganisations: AuthReducer<Organisation[]>
  loadOrganisationAddress: AuthReducer<{ id: number; addresses: Address[] }>
  selectOrganisation: AuthReducer<number | undefined>
  termsAgree: AuthReducer<boolean>
  setNotification: AuthReducer<{ order_processed_noti: boolean; order_status_change_noti: boolean; newsletter_noti: boolean; order_confirm: boolean }>
  resetShippingAddresses: AuthReducer<{ shippingaddresses: Address[]}>
}

export const authSlice = createSlice<AuthState, Reducers>(
  {
    name: 'auth',
    initialState,
    reducers: {
      authLoginRequest: state => {
        state.loading = true;
      },
      successfullyLogin: (state, { payload: user }) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = user;
        state.error = '';
      },
      failedLogin: (state, { payload: error }) => {
        state.loading = false;
        state.user = undefined;
        state.isLoggedIn = false;
        state.error = error;
      },
      refreshToken: (state, { payload: { accessToken, expire } }) => {
        if (state.user) {
          state.user.accessToken = accessToken;
          state.user.tokenExpire = expire;
        }
        state.error = ''
      },
      me: (state, { payload: user }) => {
        state.user = user;
        state.isLoggedIn = true;
        state.error = ''
      },
      signOut: () => initialState,
      modifyPriceInfo: (state, { payload: { price_type, price_extra } }) => {
        if (state.user) {
          state.user.price_type = price_type;
          state.user.price_extra = price_extra;
        }
      },
      loadOrganisations: (state, { payload: organisations }) => {
        if (state.user) {
          state.user.organisations = organisations;
        }
      },
      loadOrganisationAddress: (state, { payload: { id, addresses } }) => {
        if (state.user?.organisations) {
          const index = state.user.organisations.findIndex(({ id: currentId }) => currentId === id);

          if (index !== -1)
            state.user.organisations[index].addresses = addresses;
        }
      },
      selectOrganisation: (state, {payload: id}) => {
        state.selectedOrganisation = id
      },
      termsAgree: (state, {payload: terms_agreed}) => {
        if(state.user){
          state.user.terms_agreed = terms_agreed;
        }
      },
      setNotification: (state, {payload: { order_processed_noti, order_status_change_noti, newsletter_noti, order_confirm } }) => {
        if(state.user){
          state.user.order_processed_noti = order_processed_noti;
          state.user.order_status_change_noti = order_status_change_noti;
          state.user.newsletter_noti = newsletter_noti;
          state.user.order_confirm = order_confirm;
        }
      },
      resetShippingAddresses: (state, {payload: { shippingaddresses } }) => {
        if(state.user){
          state.user.shippingaddresses = shippingaddresses;
        }
      }
    },
  },
);

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
