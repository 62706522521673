import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FlashState = {
  messages: string[],
  errorMessages: string[]
}

const initialState: FlashState = {
  messages: [],
  errorMessages: []
}

type FlashReducer<T = void> = CaseReducer<FlashState, PayloadAction<T>>

type Reducers = {
  addFlashMessage: FlashReducer<FlashState['messages'][number]>
  addErrorMessage: FlashReducer<FlashState['errorMessages'][number]>
  resetFlashMessages: FlashReducer
  deleteFlashMessage: FlashReducer<number>
  deleteErrorMessage: FlashReducer<number>
}

export const flashSlice = createSlice<FlashState, Reducers>(
  {
    name: 'flash',
    initialState,
    reducers: {
      addFlashMessage: (state, {payload: message}) => {
        state.messages.push(message)
      },
      addErrorMessage: (state, {payload: message}) => {
        state.errorMessages.push(message)
      },
      resetFlashMessages: state => {
        state.messages = []
      },
      deleteFlashMessage: (state, { payload: index }) => {
        state.messages.splice(index, 1)
      },
      deleteErrorMessage: (state, { payload: index }) => {
        state.errorMessages.splice(index, 1)
      }
    },
  },
);

export const flashActions = flashSlice.actions;
export const flashReducer = flashSlice.reducer;
