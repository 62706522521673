import { useCallback } from 'react';
import axios from 'axios';

type UseNewsletterPatternDeleterHookType = (token: string) => (id: number) => Promise<void>

export const useNewsletterPatternDeleter: UseNewsletterPatternDeleterHookType = (token) => {

  return useCallback((id) => {

    return axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/newsletter/newsletter-pattern/${id.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }, [token])
}
