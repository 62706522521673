import React, { FC, KeyboardEventHandler, useLayoutEffect, useMemo, useRef, useEffect, useState } from 'react';
import { useCategoryId } from '../../hooks/useCategoryId';
import { useProduct } from '../../hooks/useProduct';
import { TableCell, TextField, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { useDispatch } from 'react-redux';
import { productActions } from '../../data-management/productsSlice';
import { useAddProduct } from '../../hooks/useAddProduct';
import { useProductSizeRules, useProductSizeRulesFromValidation } from '../../hooks/useProductSizeRules';
import { productSizeRuleHelper, useProductSizeRuleValid } from '../../hooks/useProductSizeRuleValid';
import { useProductSizeHelper } from '../../hooks/useProductSizeHelper';

type ProductSizeFieldsProps = {
  productId: number
}

const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      border: 'none',
      maxWidth: '13%',
      width: '13%',
      verticalAlign: 'baseline'
    },
  }),
)(TableCell);

export const ProductSizeFields: FC<ProductSizeFieldsProps> = React.memo((
  {
    productId,
  },
) => {

  const { fields } = useCategoryId();
  const product = useProduct(productId);

  const [magValue, setMagValue] = useState<any>(undefined);
  const [szelValue, setSzelValue] = useState<any>(undefined);

  const heightRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch()
  const onAddProduct = useAddProduct();
  const sizeRulesNormal = useProductSizeRules(productId)
  const sizeRulesFromValidation = useProductSizeRulesFromValidation(productId)

  const [heightValid, widthValid] = useProductSizeRuleValid(productId)

  const sizeRules = productSizeRuleHelper(product.validationState, sizeRulesNormal, sizeRulesFromValidation) ?? []

  const singleValue = useMemo<[number, number] | false | undefined>(() => {
    return sizeRules.reduce<[number, number] | false | undefined>((
      single, {height, width}
    ) => {
      if (single !== undefined)
        return false

      const same = height.max === height.min && width.min === width.max

      return same ? [height.max, width.max] : false
    }, undefined)
  }, [sizeRules])

  const {
    sizeHelperHeight,
    sizeHelperWidth
  } = useProductSizeHelper(productId)

  useEffect(() => {
    setMagValue(fields.find(({ code }) => code === 'MAG') ?
      product.features.find(({ code }) => code === 'MAG')?.keyed_value ?? null :
      undefined)
    setSzelValue(fields.find(({ code }) => code === 'SZEL') ?
      product.features.find(({ code }) => code === 'SZEL')?.keyed_value ?? null :
      undefined)
  }, [product.product_id])

  useEffect(() => {
    if(Array.isArray(singleValue)){
      if(magValue != singleValue[0] && szelValue != singleValue[1]){
        setMagValue(singleValue[0]);
        setSzelValue(singleValue[1]);
        dispatch(productActions.modifyProductFeatureById(
          {
            id: productId,
            feature: {
              code: 'MAG',
              option_code: 'meret',
              keyed_value: singleValue[0]
            },
            fields,
          },
        ));
        dispatch(productActions.modifyProductFeatureById(
          {
            id: productId,
            feature: {
              code: 'SZEL',
              option_code: 'meret',
              keyed_value: singleValue[1]
            },
            fields,
          },
        ));
      }
    }
  }, [singleValue])

  useLayoutEffect(() => {
    heightRef.current?.focus();
  }, []);

  const handleSzelChange = (event: any) => {
    const value = event.target.value;
    setSzelValue(value);
  };

  const handleMagChange = (event: any) => {
    const value = event.target.value;
    setMagValue(value);
  };

  const handleKeypress: KeyboardEventHandler =
    (e) => {
      if (e.key === 'Enter') {
        onAddProduct(productId);
      }
      if (e.key > '9' || e.key < '0') {
        e.preventDefault();
      }
    };

  const onBlur: (field: 'MAG' | 'SZEL') => StandardInputProps['onChange'] =
    (field) =>
      (event) => {
        if((field == 'MAG' && magValue !== undefined) || field == 'SZEL' && szelValue !== undefined){
          dispatch(productActions.modifyProductFeatureById(
            {
              id: productId,
              feature: {
                code: field,
                option_code: 'meret',
                keyed_value: event.target.value
              },
              fields,
            },
          ));
        }
      };

  return (
    <>
      <StyledTableCell align={'left'}>
        <TextField
          id={'MAG'}
          name={'MAG'}
          fullWidth
          inputMode="numeric"
          value={Array.isArray(singleValue) ? singleValue[0] : magValue ?? ''}
          inputProps={{
            step: 1,
            min: 0,
          }}
          type="text"
          onChange={handleMagChange}
          onBlur={onBlur('MAG')}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={heightRef}
          variant="standard"
          onKeyPress={handleKeypress}
          helperText={sizeHelperHeight}
          error={Array.isArray(singleValue) ? false : heightValid === false}
          disabled={Array.isArray(singleValue)}
        />
      </StyledTableCell>
      <StyledTableCell align={'left'}>
        <TextField
          id={'SZEL'}
          name={'SZEL'}
          fullWidth
          inputMode="numeric"
          value={Array.isArray(singleValue) ? singleValue[1] : szelValue ?? ''}
          inputProps={{
            step: 1,
            min: 0,
          }}
          type="text"
          onChange={handleSzelChange}
          onBlur={onBlur('SZEL')}
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          onKeyPress={handleKeypress}
          helperText={sizeHelperWidth}
          error={Array.isArray(singleValue) ? false : widthValid === false}
          disabled={Array.isArray(singleValue)}
        />
      </StyledTableCell>
    </>
  );
});
