import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { SettingsType } from './useSettingsGet';

type UseSettingsSetHookType = () => (settings: SettingsType) => Promise<void>

export const useSettingsSet: UseSettingsSetHookType = () => {

  const token = useToken();

  return useCallback((settings) =>
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/settings`,
          Object.keys(settings).map(key => ({key, value: settings[key].value})),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
    , [token]);
};
