import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { SettingsType, SettingType } from '../hooks/useSettingsGet';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

type StringSettingProps = {
  name: string
  setSetting: (inner: (settings: SettingsType) => SettingsType) => void
  setting: SettingType
}

export const StringSetting: FC<StringSettingProps> = (
  {
    setSetting,
    name,
    setting
  }
) => {

  const textFieldHandleChange: StandardInputProps['onChange'] = (e) => {
    setSetting(data => ({
      ...data,
      [name]: {
        ...setting,
        value: e.target.value
      },
    }));
  };

  return (
    <TextField
      name={setting.label}
      value={setting.value}
      type="text"
      fullWidth
      onChange={textFieldHandleChange}
    />
  )
}
