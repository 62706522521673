import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

export type Role = {
  created_at: string
  guard_name: string
  id: number
  name: string
  updated_at: string
  pivot: {
    model_id: number
    role_id: number
    model_type: string
  }
}

type UseAdminRoleListHookType = () => () => Promise<Role[]>

export const useAdminRoleList: UseAdminRoleListHookType = () => {

  const token = useToken()

  return useCallback(async () => {
    const {data} = await axios.get<Role[]>(
      `${process.env.REACT_APP_SERVER_URL}/roles`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}