import React, { FC } from 'react';
import { Route, Redirect } from "react-router-dom";
import { useToken } from '../../hooks/useToken';
import { OmitNative, RouteProps } from 'react-router';

type PrivateRouteProps<T extends {} = {}, Path extends string = string> =
	RouteProps<Path> & OmitNative<T, keyof RouteProps>

export const PrivateRoute: FC<PrivateRouteProps> = (
	{
		children,
		...rest
	}
	) => {

	const token = useToken()

	return (
		<Route
			{...rest}
			render={({ location }) =>
				token ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

