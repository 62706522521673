import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';

type UseAdminAllSyncHookType = () => () => Promise<void>

export const useAdminAllSync: UseAdminAllSyncHookType = () => {

  const token = useToken()

  return useCallback(async () => {
    const {data} = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/sync/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return data
  }, [token])
}
