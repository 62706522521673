import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem, ListItemAvatar, ListItemText, ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close, DescriptionOutlined, Done, ErrorOutline } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { UploadedFile, useUniqueFileUploader } from '../../hooks/useUniqueFileUploader';
import { useDispatch } from 'react-redux';
import { uniqueProductsActions } from '../../data-management/uniqueProductsSlice';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: 100,
  }
}))

type UniqueProductFileUploadModalProps = {
  open: boolean
  onClose: () => void
  files: File[]
  productIndex?: number
}

export const UniqueProductFileUploadModal: FC<UniqueProductFileUploadModalProps> = (
  {
    open,
    onClose,
    files,
    productIndex
  }
) => {
  const classes = useStyles()
  const [uploaded, setUploaded] = useState(false)
  const [success, setSuccess] = useState<undefined | boolean>(undefined)
  const uniqueFileUploader = useUniqueFileUploader()
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    setUploaded(false)
    setSuccess(undefined)
  }, [files, productIndex])

  useEffect(() => {
    if (!uploaded && productIndex !== undefined) {
      uniqueFileUploader(files).then(
        (files) => {
          setUploadedFiles(files)
          setUploaded(true)
          setSuccess(true)
          dispatch(uniqueProductsActions.addProductFiles({
            index: productIndex,
            files
          }))
        }
      ).catch(() => {
        setSuccess(false)
        setUploaded(true)
      })
    }
  }, [uploaded, files, productIndex])

  const onModalClose = () => {
    if (!uploaded)
      return;

    onClose();
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={uploaded ? onModalClose : undefined}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          Egyedi termék fájlfeltöltés
        </Typography>
        {
          uploaded ?
            <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
              <Close />
            </IconButton> : <></>
        }
      </DialogTitle>
      <DialogContent>
        {
          !uploaded ?
            <Grid container direction={'column'} alignItems={'center'}>
              <CircularProgress />
              <Typography>
                A feltöltés folyamatban
              </Typography>
            </Grid> :
            <></>
        }
        {
          success === true && uploaded ? (
            <>
              <Grid container direction={'column'} alignItems={'center'}>
                <Done className={classes.icon}/>
                <Typography>
                  A feltöltés sikeres
                </Typography>
              </Grid>
              <List
                subheader={<ListSubheader>A feltöltött fájlok:</ListSubheader>}
              >
                {
                  uploadedFiles.map((
                    {
                      title,
                      mime
                    }, index
                  ) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <DescriptionOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={title}
                        secondary={mime}
                      />
                    </ListItem>
                  ))
                }
              </List>
            </>
          ) : <></>
        }
        {
          success === false && uploaded ? (
            <>
              <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                <ErrorOutline className={classes.icon} />
                <Typography>
                  A szinkronizálás sikertelen
                </Typography>
              </Grid>
            </>
          ) : <></>
        }
      </DialogContent>
      <DialogActions>
        {
          uploaded ?
            <Button
              autoFocus
              onClick={onModalClose}
              color="secondary"
              variant={'contained'}
              size={'small'}
            >
              Bezár
            </Button> : <></>
        }
      </DialogActions>
    </Dialog>
  )
}
