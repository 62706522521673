import { useToken } from './useToken';
import { useCallback } from 'react';
import axios from 'axios';

type UseProductSimpleImportHookType = () => (
  file: File
) => Promise<{
  data: {
    height: number
    width: number
    quantity: number
  }[]
}>

export const useProductSimpleImport: UseProductSimpleImportHookType = () => {
  const token = useToken()

  return useCallback(async (file) => {

    const formData = new FormData();
    formData.append('import', file)

    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}/products/import/simple`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

  }, [token])
}