import { useCallback } from 'react';
import { useToken } from './useToken';
import axios from 'axios';
import { FieldOption } from '../data-management/fieldOptionsSlice';

type UseFieldOptionsHookType = () => (
  categoryId: number | string,
  fieldCode: string,
  codes?: {code: string, value: number}[]
) => Promise<FieldOption[]>

export const useFieldOptions: UseFieldOptionsHookType = () => {

  const token = useToken();

  return useCallback(async (categoryId, fieldCode, codes = []) => {

    const {data} = await axios
      .post<FieldOption[]>(`${process.env.REACT_APP_SERVER_URL}/categories/fields/${categoryId}`,
        {
          REQ: fieldCode,
          DATA: codes.length > 0 ? codes : undefined,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    return data
  }, [token])
}