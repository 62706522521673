import { getGridLocalization, GridLocaleText, Localization } from '@material-ui/data-grid';
import { huHU as coreHu } from '@material-ui/core/locale';

const huHUGrid: Partial<GridLocaleText> = {
  // Root
  noRowsLabel: 'Nincsenek sorok',
  noResultsOverlayLabel: 'Nem található eredmény.',
  errorOverlayDefaultLabel: 'Hiba történt.',

  // Density selector toolbar button text
  toolbarDensity: 'Tömörség',
  toolbarDensityLabel: 'Tömörség',
  toolbarDensityCompact: 'Kompakt',
  toolbarDensityStandard: 'Normál',
  toolbarDensityComfortable: 'Kényelmes',

  // Columns selector toolbar button text
  toolbarColumns: 'Oszlopok',
  toolbarColumnsLabel: 'Oszlopok kiválasztása',

  // Filters toolbar button text
  toolbarFilters: 'Szűrők',
  toolbarFiltersLabel: 'Szűrők mutatása',
  toolbarFiltersTooltipHide: 'Szűrők elrejtése',
  toolbarFiltersTooltipShow: 'Szűrők megjelenítése',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`,

  // Export selector toolbar button text
  toolbarExport: 'Export',
  toolbarExportLabel: 'Export',
  toolbarExportCSV: 'Letöltés CSV-ként',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Oszlop keresése',
  columnsPanelTextFieldPlaceholder: 'Oszlop név',
  columnsPanelDragIconLabel: 'Oszlopok újrarendezése',
  columnsPanelShowAllButton: 'Mindegyik mutatása',
  columnsPanelHideAllButton: 'Mindegyik elrejtése',

  // Filter panel text
  filterPanelAddFilter: 'Szűrő hozzáadása',
  filterPanelDeleteIconLabel: 'Törlés',
  filterPanelOperators: 'Műveletek',
  filterPanelOperatorAnd: 'És',
  filterPanelOperatorOr: 'Vagy',
  filterPanelColumns: 'Oszlopok',
  filterPanelInputLabel: 'Érték',
  filterPanelInputPlaceholder: 'Szűrő érték',

  // Filter operators text
  filterOperatorContains: 'tartalmaz',
  filterOperatorEquals: 'egyenlő',
  filterOperatorStartsWith: 'kezdődik',
  filterOperatorEndsWith: 'véget ér',
  filterOperatorIs: 'ami',
  filterOperatorNot: 'ami nem',
  filterOperatorAfter: 'ez után',
  filterOperatorOnOrAfter: 'ez és ez után',
  filterOperatorBefore: 'ez előtt',
  filterOperatorOnOrBefore: 'ez és ez előtt',

  // Filter values text
  filterValueAny: 'akármi',
  filterValueTrue: 'igen',
  filterValueFalse: 'nem',

  // Column menu text
  columnMenuLabel: 'Menü',
  columnMenuShowColumns: 'Oszlopok mutatása',
  columnMenuFilter: 'Szűrés',
  columnMenuHideColumn: 'Elrejtés',
  columnMenuUnsort: 'Rendezés törlése',
  columnMenuSortAsc: 'Növekvő sorrend',
  columnMenuSortDesc: 'Csökkenő sorrend',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`,
  columnHeaderFiltersLabel: 'Szűrők mutatása',
  columnHeaderSortIconLabel: 'Rendezés',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} sor kiválasztva`
      : `${count.toLocaleString()} sor kiválasztva`,

  // Total rows footer text
  footerTotalRows: 'Sorok száma:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()}, összesen: ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Checkbox selection',

  // Boolean cell text
  booleanCellTrueLabel: 'igen',
  booleanCellFalseLabel: 'nem',

};

export const huHU: Localization = getGridLocalization(huHUGrid, coreHu);