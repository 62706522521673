import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { useAdminUserShow, User } from '../hooks/useAdminUserShow';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem, ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { EditUserRoleModal } from './EditUserRoleModal';
import { Role } from '../hooks/useAdminRoleList';
import { Cancel, Done, LocalShipping, Receipt } from '@material-ui/icons';
import { useAdminUserWelcome } from '../hooks/useAdminUserWelcome';
import { EditStatusModal } from './EditStatusModal';
import { useIsAdmin } from '../hooks/useIsAdmin';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  removeUnderline: {
    textDecoration: 'none'
  }
}));

type UserShowPageProps = {}

export const UserShowPage: FC<UserShowPageProps> = () => {

  const classes = useStyles();
  const { userId } = useParams<{ userId: string }>();
  const adminUserShowApi = useAdminUserShow();
  const [user, setUser] = useState<undefined | User>(undefined);
  const [roleEditModal, setRoleEditModal] = useState(false);
  const [statusEditModal, setStatusEditModal] = useState(false);
  const userWelcome = useAdminUserWelcome()
  const [sendWelcome, setSendWelcome] = useState<boolean>(false)
  const [sendWelcomeSuccess, setSendWelcomeSuccess] = useState<boolean | undefined>(undefined)
  const isAdmin = useIsAdmin();

  //const email_verified_at = user?.email_verified_at ? format(parseISO(user.updated_at), 'yyyy.MM.dd HH:mm') : 'Nincs megerősítve';

  const onCloseRoleEditModal = () => {
    setRoleEditModal(false);
  };

  const openRoleEditModal = () => {
    setRoleEditModal(true);
  };

  const openStatusEditModal = () => {
    setStatusEditModal(true);
  };

  const onCloseStatusEditModal = () => {
    setStatusEditModal(false);
  };

  const onSaveRoleEditModal = (roles: Role[]) => {
    if (!user) return;

    setUser({
      ...user,
      roles,
    });
    setRoleEditModal(false);
  };

  const onSaveStatusEditModal = (active: number) => {
    if (!user) return;

    setUser({
      ...user,
      active: active,
    });

    setStatusEditModal(false);
  };

  const resendEmail = () => {
    setSendWelcome(true)
    setSendWelcomeSuccess(undefined)
    userWelcome(Number(userId))
      .then(() => {
        setSendWelcome(false)
        setSendWelcomeSuccess(true)
      }).catch(() => {
      setSendWelcome(false)
      setSendWelcomeSuccess(false)
    })
  }

  useEffect(() => {
    if (!userId)
      return;

    adminUserShowApi(Number(userId)).then((data) => {
      setUser(data);
    });
  }, [userId]);

  if (!userId)
    return (
      <Redirect to={'/users'} />
    );

  return (
    <Container maxWidth={'lg'}>
      <Box my={2}>
        <Link to={'/users'} className={classes.removeUnderline}>
          <Button
            color={'secondary'}
            variant={'contained'}
          >
            Vissza a listához
          </Button>
        </Link>
      </Box>
      <Box mt={4}>
        {
          !user ? <CircularProgress /> :
            <>
              <Typography variant={'h4'}>
                Felhasználó: {user.name}
              </Typography>
              <Divider />
              <Box mt={4} />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Paper square variant={'outlined'} className={classes.paper}>
                    <Typography variant={'h5'}>
                      Email: {user.email}
                    </Typography>
                    <Typography variant={'h6'}>
                      Állapot: {user.active ? "Aktív" : "Hibernált"}
                    </Typography>
                    <Box mt={2}/>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={resendEmail}
                      disabled={sendWelcome}
                      endIcon={
                        sendWelcomeSuccess ?
                          <Done /> : sendWelcomeSuccess === false ?
                            <Cancel /> :
                          sendWelcome ?
                            <CircularProgress />
                            : undefined
                      }
                    >
                      {sendWelcomeSuccess ? 'Email újraküldve' : 'Email újraküldése'}
                    </Button>
                    <Button
                      style={{margin: "0px 10px 15px"}}
                      variant={'contained'}
                      color={'primary'}
                      onClick={openStatusEditModal}
                    >
                      Állapot beállítása
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper square variant={'outlined'} className={classes.paper}>
                    <Typography variant={'h5'}>
                      Létrehozva: {format(parseISO(user.created_at), 'yyyy-MM-dd HH:mm')}
                    </Typography>
                    <Typography variant={'h5'}>
                      Utolsó módosítás: {format(parseISO(user.updated_at), 'yyyy-MM-dd HH:mm')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper square variant={'outlined'} className={classes.paper}>
                    <Typography variant={'h5'}>
                      Szerepkörök:
                    </Typography>
                    <Box my={2}>
                      <Grid container>
                        {user.roles.map(({ name }, index) => (
                          <Box key={index} mr={1}>
                            <Chip
                              label={name}
                              color={'primary'}
                            />
                          </Box>
                        ))}
                      </Grid>
                    </Box>
                    {isAdmin ?
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={openRoleEditModal}
                    >
                      Szerepkörök szerkesztése
                    </Button>
                    : <></> }
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper square variant={'outlined'} className={classes.paper}>
                    <Typography variant={'h5'}>
                      Árazás
                    </Typography>
                    <Box mt={1} />
                    <Chip
                      label={user.price_type === 1 ? `Saját ár + ${user.price_extra ?? 0}%` : `Saját áron`}
                      color={'primary'}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper square variant={'outlined'} className={classes.paper}>
                    <Typography variant={'h5'}>
                      Címek
                    </Typography>
                    <Box mt={1} />
                    <List>
                      {
                        user.shippingaddresses.map((
                          {
                            type,
                            location_code,
                            address,
                            city,
                            zip,
                            country,
                          }
                          , index) => (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar>
                                {
                                  type === 'SHIP' ?
                                    <LocalShipping /> :
                                    <Receipt />
                                }
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${country}, ${zip} ${city}, ${address}`}
                              secondary={`${location_code} - ${type === 'SHIP' ? 'Szállítási cím' : 'Számlázási cím'}`}
                            />
                          </ListItem>
                        ))
                      }
                    </List>
                  </Paper>
                </Grid>
              </Grid>
              <EditUserRoleModal
                open={roleEditModal}
                onClose={onCloseRoleEditModal}
                onSave={onSaveRoleEditModal}
                id={user.id}
                userName={user.name}
              />
              <EditStatusModal
                open={statusEditModal}
                onClose={onCloseStatusEditModal}
                onSave={onSaveStatusEditModal}
                id={user.id}
                userName={user.name}
                statusProp={user.active}
              />
            </>
        }
      </Box>
    </Container>
  );
};