import React, { FC, FormEventHandler, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Logo from '../../images/logo-dark.png';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { RootState } from '../data-management/store';
import { AuthState } from '../data-management/authSlice';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  card: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 36,
    fontWeight: 300,
    marginRight: theme.spacing(10),
  },
  link: {
    color: '#b2a77d',
    textDecoration: 'underline',
  },
  loginBtn: {
    position: 'relative',
    backgroundColor: '#b2a77d',
    color: '#fff',
    display: 'block',
    padding: '12px',
    textAlign: 'center',
    borderRadius: 0,
    width: '100%',
    textTransform: 'unset',
    fontSize: '16px',
    border: 0,
    '&::after': {
      content: '""',
      display: 'block',
      border: '1px solid #ccc',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 'calc(100% - 15px)',
      height: 'calc(100% - 15px)',
      transform: 'translate(-50%, -50%)',
    },
    '&:hover': {
      backgroundColor: '#b2a77d',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    },
  },
  loginBackground: {
    width: '100%',
    height: '100%',
    background: 'url(/images/authbg.png) no-repeat center center/cover',
    position: 'fixed',
    top: 0,
    left: 0,
    opacity: .5,
  },
}));

export const ResetPassword: FC = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState<undefined | boolean>(undefined);
  const [error, setError] = useState('');
  const { token } = useParams<{
    token: string
  }>();

  const user = useSelector<RootState, AuthState>(state => state.auth);

  const [credentials, setCredentials] = useState({
    new_password: '',
    new_password_confirm: '',
  });

  const handleChange: StandardInputProps['onChange'] = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/auth/resetpw`,
      {
        code: token,
        ...credentials,
      },
    ).then((res) => {
      setSuccess(true);
    }).catch((e) => {
      setSuccess(false);
      if (typeof e?.response?.data === 'string')
        setError(e.response.data);
    });
  };

  return (
    <>
      {user.isLoggedIn && <Redirect to="/order" />}
      <div className={classes.root}>
        <div className={classes.loginBackground}></div>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardHeader}>
              <Typography className={classes.title}>
                Új jelszó beállítása
              </Typography>
              <img src={Logo} alt="Nettfront" />
            </div>
            {
              success !== true ? (
                <form onSubmit={onSubmit}>
                  <Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: '20px' }}>
                    {
                      success === false ? (
                        <Grid item xs={12}>
                          <Alert variant={'outlined'} severity={'warning'}>
                            Hiba: {error}
                          </Alert>
                        </Grid>
                      ) : <></>
                    }
                    <Grid item>
                      <Lock />
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                      <TextField
                        name="new_password"
                        type="password"
                        onChange={handleChange}
                        fullWidth
                        label="Jelszó"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: '20px' }}>
                    <Grid item>
                      <Lock />
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                      <TextField
                        name="new_password_confirm"
                        type="password"
                        onChange={handleChange}
                        fullWidth
                        label="Jelszó megerősítés"
                      />
                    </Grid>
                  </Grid>
                  <button type="submit" className={classes.loginBtn} style={{ marginBottom: '20px' }}>
                    Új jelszó kérelem küldése
                  </button>
                </form>
              ) : (
                <>
                  <Typography variant={'h5'} align={'center'}>
                    Jelszó sikeresen visszaállítva, most már bejelentkezhet az új jelszavával!
                  </Typography>
                  <Box mt={3} width={'100%'} />
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    href={'/'}
                  >
                    Bejelentkezés
                  </Button>
                </>
              )
            }
          </CardContent>
        </Card>
      </div>
    </>
  );
};

