import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Paper, Typography, Button, Box, CircularProgress } from '@material-ui/core';
import { DataGrid, GridColumns, GridSortModel } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { parseISO } from 'date-fns';
import { SavedOrder } from '../hooks/useSaveProducts';
import axios from 'axios';
import { useToken } from '../hooks/useToken';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { DeleteModal } from '../Components/Newsletter/DeleteModal';
import { useNewsletterPatternDeleter } from '../hooks/useNewsletterPatternDeleter';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    minHeight: '90vh',
    '& .MuiDataGrid-row.Mui-even': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
    },
  },
  paper: {
    padding: '40px 50px 52px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  filterItem: {
    flexGrow: 1,
    marginRight: theme.spacing(20),
  },
  itemFlexGrow: {
    flexGrow: 1,
    minHeight: 400,
    padding: '50px',
  },
  statusRow: {
    backgroundColor: '#DEFFBD',
    paddingLeft: '6px',
    paddingRight: '6px'
  }
}));

export const NewsletterPatternPage = () => {
  const classes = useStyle();
  const token = useToken();
  const orgId = useSelectedOrganisationId();

  const deletePattern = useNewsletterPatternDeleter(token)

  const [items, setItems] = useState<SavedOrder[] | undefined>(undefined);
  const [deleteModal, setDeleteModal] = useState<any | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const refreshTable = function(){
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/newsletter/get-newsletter-patterns`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setItems(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    refreshTable();
  }, [orgId]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Azonosító',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      flex: 0.40
    },
    {
      field: 'title',
      headerName: 'Megnevezés',
      flex: 0.60,
    },
    {
      field: 'updated_at',
      headerName: 'Utolsó szerkesztés dátuma',
      flex: 0.75,
      valueGetter: (params) => {
        return typeof params.value === 'string' ?
          parseISO(params.value) : new Date();
      },
      type: 'dateTime',
    },
    {
      field: 'updated_by_name',
      headerName: 'Utolsó szerkesztő',
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.getValue(params.id, 'updated_by');
        return (value && typeof value === 'object') ? (value as any).name : "";
      },
    },
    {
      field: 'show',
      headerName: '',
      sortable: false,
      resizable: false,
      editable: false,
      hideSortIcons: true,
      filterable: false,
      flex: 0.75,
      disableColumnMenu: true,
      renderHeader: () => (
        <div />
      ),
      renderCell: (params) => (
        <div className={classes.wrapper}>
          <IconButton href={`/newsletter-patterns/edit/${params.id}`}>
            <EditIcon style={{ fill: '#535353' }}></EditIcon>
          </IconButton>
          <IconButton onClick={() => setDeleteModal(params.row.id) }>
            <DeleteIcon style={{ fill: '#B81E22' }}></DeleteIcon>
          </IconButton>
        </div>
      ),
      align: 'left',
    },
  ];

  const rows = items ?? [];

  return (
    <>
    <Grid container direction={'column'} className={classes.root}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.filterItem} variant="h4">
              Hírlevél sablonok
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item className={classes.itemFlexGrow}>
        <Box style={{textAlign: "right"}}>
        <Button variant={'contained'} color={'primary'} href={'/newsletter-patterns/create'} >
          Új sablon
        </Button>
        </Box>
        <Paper>
        {loading ? <Grid container justify={'center'}><Box p={2}><CircularProgress/></Box></Grid> :
          <div style={{ minHeight: 300, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
              sortModel={sortModel}
              onSortModelChange={({ sortModel }) => setSortModel(sortModel)}
            />
          </div> }
        </Paper>
      </Grid>
    </Grid>
    <DeleteModal
      open={!!deleteModal}
      onClose={() => {
        setDeleteModal(undefined);
      }}
      onConfirm={() => {
        deletePattern(deleteModal)
        .then(() => {
          refreshTable();
          setDeleteModal(undefined);
        });
      }}
    />
    </>
  );
};